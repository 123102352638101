import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Fragment } from 'react';
import { Group, Stack, Text, Title } from '@mantine/core';
import ActionButtons from '../../components/ActionButtons/ActionButtons';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import FixtureTypeForm from '../../components/fixtureTypes/FixtureTypeForm';
import GenericDeleteConfirmation from '../../components/ObjectAction/GenericDeleteConfirmation';

const fixtureTypesModel = {
  title: 'Fixture Types',
  readFrom: 'swr',
  service: 'fixture-types',
  injectGetQuery: ['orgId'],
  defaultSort: 'name',
  key: 'id',
  colDefs: [
    {
      key: 'name',
      label: 'Name',
      getter: (data) => data.name,
      width: '35%',
      flex: '2 1',
      hideBreak: 'sm',
    },
    {
      key: 'shortname',
      label: 'Short',
      getter: (data) => data.shortname,
      width: '15%',
      flex: '2 1',
      hideBreak: 'sm',
    },
    {
      key: 'manufacturer',
      label: 'Manufacturer',
      getter: (data) => data.manufacturer,
      width: '15%',
      flex: '2 1',
      hideBreak: 'sm',
    },

    {
      key: 'description',
      label: 'Description',
      getter: (data) => data.description,
      width: '35%',
      hideBreak: 'sm',
    },
    {
      key: 'update',
      label: 'Actions',
      getter: (data) => data,
      width: '56px',
      minWidth: '56px',
      hideBreak: 'sm',
      cellRenderer: (props) => <ActionButtons {...props} />,
    },
    {
      key: 'mobile',
      label: 'Fixture Type',
      cellRenderer: (props) => (
        <Stack style={{ width: '100%' }} spacing={0}>
          <Group position='apart' style={{ width: '100%' }}>
            <Text>
              {props.data.name}
              {props.data.shortname ? ` (${props.data.shortname})` : ''}
            </Text>
            <Text align='right'>{props.data.manufacturer}</Text>
          </Group>

          <Group position='apart'>
            <Text size='sm' color='dimmed'>
              {props.data.description}
            </Text>
            <ActionButtons {...props} />
          </Group>
        </Stack>
      ),
      width: '100%',
      minWidth: '100%',
      showBreak: 'sm',
    },
  ],
  toolbar: ['create'],
  actionButtons: ['patch', 'remove'],
  actions: {
    create: {
      type: 'modal',
      icon: <FontAwesomeIcon icon={faPlus} />,
      label: 'New Fixture Type',
      title: 'New Fixture Type',
      content: <FixtureTypeForm />,
    },
    patch: {
      type: 'modal',
      icon: <FontAwesomeIcon icon={faEdit} />,
      label: 'Edit Fixture Type',
      title: 'Edit Fixture Type',
      content: <FixtureTypeForm />,
    },
    remove: {
      type: 'modal',
      icon: <FontAwesomeIcon icon={faTrash} />,
      label: 'Delete Fixture Type',
      title: 'Delete Fixture Type',
      content: <GenericDeleteConfirmation />,
    },
  },
  sidebar: (
    <Fragment>
      <Title order={5}>Your Fixture Types</Title>
      <Text size='sm' color={'dimmed'}>
        Fixture types belong to an organization.
      </Text>
    </Fragment>
  ),
};

export default fixtureTypesModel;
