import React from 'react';
import ServiceActionProvider from '../ServiceAction/ServiceActionProvider';
import CommentForm from './CommentForm';

function CommentCreator({ targetId, ...rest }) {
  return (
    <ServiceActionProvider service='comments' method='create'>
      <CommentForm targetId={targetId} {...rest} />
    </ServiceActionProvider>
  );
}

export default CommentCreator;
