import { Stack, Text } from '@mantine/core';
import React from 'react';
import VenueLink from './VenueLink';

function VenueMobileCellRenderer({ data }) {
  return (
    <Stack spacing={0}>
      <VenueLink data={data} cellData={data.name} />
      <Text size='sm' color='dimmed' m={0} lineClamp={1}>
        {data.shortname}
        {data.venueGroup && ` (${data.venueGroup})`}
      </Text>
    </Stack>
  );
}

export default VenueMobileCellRenderer;
