import React from 'react';
import {
  Text,
  TextInput,
  Space,
  Group,
  Button,
  Box,
  LoadingOverlay,
  Textarea,
  NumberInput,
  Accordion,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import DataAutocomplete from '../DataAutocomplete/DataAutocomplete';
import venuesModel from '../../features/venues/venuesModel';
import fixtureTypesModel from '../../features/fixtureTypes/fixtureTypesModel';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher } from '../../app/feathersClient';
import { useEffect } from 'react';

function PositionsForm({
  target,
  targetData,
  onClose,
  onSubmit,
  success,
  action,
  pending = false,
  error = '',
}) {
  const { venueId, orgId } = useParams();

  const { data: position } = useSWR(
    target
      ? {
          service: 'positions',
          query: {
            venueId,
            orgId,
            parentId: target,
            $limit: 0,
          },
        }
      : null,
    fetcher
  );
  const defaultVenue = venueId || null;

  const form = useForm({
    initialValues: {
      venueId: target ? targetData.venueId : defaultVenue || '',
      name: target ? targetData.name : '',
      description: target ? targetData.description : '',
      shortname: target ? targetData.shortname : '',
      limitTypeId: target ? targetData.limitTypeId : '',
      limitQty: target ? targetData.limitQty : '',
      parentId: target ? targetData.parentId : '',
    },
    validationRules: {
      venueId: (value) => value !== '',
      name: (value) => value.trim().length >= 1 && value.trim().length <= 64,
      description: (value) => value.trim().length <= 128,
      colour: (value) => value.trim().length === 0 || value.trim().length === 7,
    },
    errorMessages: {
      venueId: 'Venue must be selected',
      name: 'Venue name should be between 1 and 64 characters',
      description: 'Description should be less than 128 characters',
      colour: 'Colour should be a Hex value',
    },
  });

  useEffect(() => {
    if (success) onClose();
    // eslint-disable-next-line
  }, [success]);

  return (
    <Box>
      <form
        onSubmit={form.onSubmit(() => {
          onSubmit({
            ...form.values,
            id: target ? target : undefined,
          });
        })}
      >
        <LoadingOverlay visible={pending} />

        <Accordion defaultValue={'required'} multiple>
          <Accordion.Item value='required'>
            <Accordion.Control>Required</Accordion.Control>
            <Accordion.Panel>
              {!venueId && (
                <DataAutocomplete
                  model={venuesModel}
                  disabled={action !== 'create'}
                  label='Venue'
                  description='The venue this position belongs to'
                  matchField='name'
                  required
                  {...form.getInputProps('venueId')}
                />
              )}

              <TextInput
                placeholder='Position Name'
                label='Position Name'
                required
                data-autofocus
                mt='md'
                {...form.getInputProps('name')}
              />
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item value='optional'>
            <Accordion.Control>Optional</Accordion.Control>
            <Accordion.Panel>
              <TextInput
                placeholder='Short Name'
                label='Short Name'
                mt='md'
                {...form.getInputProps('shortname')}
              />
              <DataAutocomplete
                model={{
                  service: 'positions',
                  injectGetQuery: ['orgId', 'venueId'],
                }}
                additionalQuery={{
                  parentId: 'null',
                  id: target
                    ? {
                        $ne: target,
                      }
                    : undefined,
                }}
                label='Parent Position'
                mt='md'
                description='Optional type to require for fixtures to be assigned this position'
                matchField='name'
                disabled={position ? position.total !== 0 : false}
                {...form.getInputProps('parentId')}
              />
              <Textarea
                placeholder='Description'
                label='Description'
                mt='md'
                {...form.getInputProps('description')}
              />

              <DataAutocomplete
                model={fixtureTypesModel}
                label='Position Type'
                description='Optional type to require for fixtures to be assigned this position'
                matchField='name'
                {...form.getInputProps('limitTypeId')}
              />
              <NumberInput
                description='Optional maximum number of fixtures that can be assigned. (Or 0 for unlimited)'
                defaultValue={0}
                label='Limit Quantity'
                mt='md'
                min={0}
                max={999}
                {...form.getInputProps('limitQty')}
              />
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        {error && (
          <Text color='red' size='sm' mt='sm'>
            {error}
          </Text>
        )}
        <Space h='xl' />
        <Group position='right'>
          <Button type='submit'>{action === 'create' ? 'Create' : 'Update'}</Button>
          <Button variant='outline' type='button' onClick={onClose}>
            Cancel
          </Button>
        </Group>
      </form>
    </Box>
  );
}

export default PositionsForm;
