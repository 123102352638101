import { createStyles, useMantineTheme, Text, Tooltip, Loader } from '@mantine/core';
import React from 'react';
import useSWR from 'swr';
import { getter } from '../../app/feathersClient';

const colours = {
  dark: {
    font: '#ededed',
    border: '#4f5169',
    dark: '#23242f',
    medium: '#353646',
    light: '#4f5169',
  },
  light: {
    font: '2c2c2c',
    border: '#c3c5d1',
    dark: '#b7b8cb',
    medium: '#d8d9e9',
    light: '#ebebf5',
  },
};

const useStyles = createStyles((theme) => ({
  wrapper: {
    overflow: 'hidden',
    height: '28px',
    lineHeight: '28px',
    fontSize: '14px',
    fontWeight: 400,
    display: 'inline-flex',
    width: 'auto',
    letterSpacing: '0.25',
    justifyContent: 'center',
    webkitBoxAlign: 'center',
    fontFamily:
      '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji',
    flexWrap: 'nowrap',
    cursor: 'help',
    maxWidth: '150px',
  },
  tag: {
    borderRadius: '4px',
    justifyContent: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    border: `1px solid ${colours[theme.colorScheme].border}`,
    alignItems: 'center',
    display: 'inline-flex',

    whitespace: 'nowrap',
    maxWidth: '80px',
    paddingBottom: '1px',
  },
}));

function PositionBadgeRenderer({
  positionId,
  showVenue = true,
  position = null,
  venue = null,
  compact = false,
}) {
  const { classes } = useStyles();
  const theme = useMantineTheme();

  const positionKey = positionId ? `positions/${positionId}` : false;
  let { data: positionData } = useSWR(positionId ? positionKey : false, getter);
  if (!positionKey) positionData = position;

  const validPos = positionId || position?.id ? true : false;

  const parentKey =
    positionData && positionData.parentId ? `positions/${positionData.parentId}` : false;
  const { data: parentData } = useSWR(validPos ? parentKey : false, getter);

  const venueId = venue ? false : positionData?.venueId || positionData?.venue?.id;
  let { data: venueData } = useSWR(venueId ? `venues/${venueId}` : false, getter);
  if (venue) venueData = venue;

  const venueName = venueData?.shortname?.toUpperCase() || '';
  const parentName = parentData?.shortname || parentData?.name || '';
  const positionName = positionData?.shortname || positionData?.name || '';

  const fullName = `${parentName ? `${parentName} / ` : ''}${positionName}`;

  const showParent = positionData?.parentId;

  if (!validPos) return null;

  if (!positionData && positionId) {
    return <Loader size={'xs'} />;
  }

  if (!positionData) {
    return (
      <span>
        <div className={classes.wrapper}>
          <Text size='md' color={'dimmed'} weight={300} style={{ fontStyle: 'italic' }}>
            -unassigned-
          </Text>
        </div>
      </span>
    );
  }

  const padding = compact ? '4px' : '8px';

  return (
    <span>
      <Tooltip label={fullName}>
        <div className={classes.wrapper}>
          {showVenue && (
            <span
              className={classes.tag}
              style={{
                backgroundColor: colours[theme.colorScheme].dark,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                color: 'white',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                paddingLeft: padding,
                paddingRight: padding,
              }}
            >
              {venueName}
            </span>
          )}
          {showParent && (
            <span
              className={classes.tag}
              style={{
                backgroundColor: colours[theme.colorScheme].medium,
                borderTopLeftRadius: showVenue ? 0 : 4,
                borderBottomLeftRadius: showVenue ? 0 : 4,
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
                color: 'white',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                paddingLeft: padding,
                paddingRight: padding,
              }}
            >
              {parentName}
            </span>
          )}
          <span
            className={classes.tag}
            style={{
              backgroundColor: colours[theme.colorScheme].light,
              borderTopLeftRadius: showVenue || showParent ? 0 : 4,
              borderBottomLeftRadius: showVenue || showParent ? 0 : 4,
              color: 'white',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              paddingLeft: padding,
              paddingRight: padding,
            }}
          >
            {positionName}
          </span>
        </div>
      </Tooltip>
    </span>
  );
}

export default PositionBadgeRenderer;
