import { Button, Group, Text } from '@mantine/core';
import React, { Fragment } from 'react';

function GenericDeleteConfirmation({ onClose, targetData, onSubmit, error, warning = '' }) {
  return (
    <Fragment>
      <Text>
        Are you sure you want to permanently delete "{targetData?.name || targetData?.identifier}" ?
      </Text>
      {warning && (
        <Text mt='md' color='red'>
          {warning}
        </Text>
      )}
      {error && (
        <Text mt='md' mb='md' size={'sm'} color='red'>
          {error}
        </Text>
      )}
      <Group position='right' mt='xl'>
        <Button type='submit' onClick={() => onSubmit({})}>
          Delete
        </Button>
        <Button variant='outline' type='button' onClick={onClose}>
          Cancel
        </Button>
      </Group>
    </Fragment>
  );
}

export default GenericDeleteConfirmation;
