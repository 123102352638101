import { Container, Divider, Loader, ScrollArea } from '@mantine/core';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { getter } from '../../app/feathersClient';
import logsModel from '../../features/logs/logsModel';
import FixtureLogList from '../Logs/FixtureLogList';
import Toolbar from '../Toolbar/Toolbar';
import FixtureIssuesBlock from './FixtureIssuesBlock';
import FixtureLabelBlock from './FixtureLabelBlock';

function FixtureInfo() {
  const { fixtureId } = useParams();
  const { data: fixture } = useSWR(`fixtures/${fixtureId}`, getter);
  const [filters, setFilters] = useState({});
  let query = {};

  Object.keys(filters).forEach((key) => {
    query = { ...query, ...filters[key] };
  });

  // A function that can be passed to the FixtureIssuesBlock to set an issueId filter from there
  const setIssueIdFilter = (issueId) => {
    setFilters({ ...filters, issueId: { issueId } });
  };

  if (!fixture || !fixture.id) return <Loader />;
  return (
    <Container>
      <Divider mt='md' mb='md' />
      <FixtureLabelBlock fixtureId={fixture.id} />
      <FixtureIssuesBlock fixtureId={fixture.id} setIssueId={setIssueIdFilter} />
      <Divider mt='md' mb='md' />
      <Toolbar
        model={logsModel}
        secondaryTarget={fixture}
        filters={filters}
        setFilters={setFilters}
      />
      <ScrollArea
        offsetScrollbars
        sx={{
          root: { height: '100%', flex: '1 1' },
        }}
      >
        <FixtureLogList fixtureId={fixture.id} query={query} />
      </ScrollArea>
    </Container>
  );
}

export default FixtureInfo;
