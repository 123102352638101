import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, UnstyledButton } from '@mantine/core';
import React from 'react';
import { flexCenterCenter } from '../../common/commonStyles';
import { openContextModal } from '@mantine/modals';
import { useMatch } from 'react-router-dom';

function QuickSearchButton() {
  const match = useMatch(
    { path: `/orgs/:orgId/venues/:venueId/*`, exact: false, strict: false },
    window.location.pathname
  );

  const orgId = match?.params?.orgId || null;
  const venueId = match?.params?.venueId || null;

  return (
    <Box>
      <UnstyledButton
        disabled={!venueId}
        onClick={() =>
          openContextModal({
            modal: 'quickSearch',
            title: 'Quick Search',
            size: 'xl',
          })
        }
        sx={(theme) => ({
          display: 'flex',
          minWidth: '56px',
          width: '100%',
          height: '100%',
          minHeight: '56px',
          ...flexCenterCenter,
          ':hover': {
            backgroundColor:
              theme.colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.gray[3],
          },
          ':active': {
            backgroundColor:
              theme.colorScheme === 'dark' ? theme.colors.gray[9] : theme.colors.gray[2],
          },
          ':disabled': {
            ':hover': {
              backgroundColor: 'unset',
            },
          },
        })}
      >
        <FontAwesomeIcon icon={faMagnifyingGlass} color={orgId ? 'gray' : '#323232'} size='lg' />
      </UnstyledButton>
    </Box>
  );
}

export default QuickSearchButton;
