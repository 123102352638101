import { Box, Center, Pagination, Table } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import app, { fetcher } from '../../app/feathersClient';
import { getPaginationValues } from '../../common/commonResolvers';
import VenueRoleCreator from './VenueRoleCreator';

function VenueAddUserForm({ venue, pageSize = 10 }) {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);

  const pg = getPaginationValues(total, pageSize, page);

  const { data } = useSWR(
    {
      service: 'venue-access',
      query: {
        orgId: venue.orgId,
        venueId: venue.id,
        $limit: pageSize,
        $skip: pg.firstIndex,
        invert: true,
        // Sort filter is ignored in the venue access class for now
        $sort: {
          email: 1,
        },
      },
    },
    fetcher
  );

  const rows =
    data &&
    data.data.map((user) => (
      <tr key={user.userId}>
        <td>{user.username}</td>
        <td>{user.email}</td>
        <td>
          <VenueRoleCreator user={user} venue={venue} />
        </td>
      </tr>
    ));

  return (
    <Box>
      <Table>
        <thead>
          <tr>
            <th>User</th>
            <th>Email</th>
            <th>Permission</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Center>
        <Pagination mt='md' initialPage={1} page={page} total={pg.totalPages} onChange={setPage} />
      </Center>
    </Box>
  );
}

export default VenueAddUserForm;
