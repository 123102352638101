import { Image, Modal } from '@mantine/core';
import React from 'react';
import { useState } from 'react';

function ImageThumbnail({ image }) {
  const [opened, setOpened] = useState(false);

  const src = `${process.env.REACT_APP_IMAGE_ASSET_URL}/${image}`;
  const [id, ext] = image.split('.');
  const thumb = `${process.env.REACT_APP_IMAGE_ASSET_URL}/${id}-thumb.${ext}`;

  return (
    <>
      <Image
        m='sm'
        sx={(theme) => ({
          border: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[9]
          }`,
          cursor: 'pointer',
          borderRadius: 8,
        })}
        width='200px'
        withPlaceholder
        src={thumb}
        onClick={() => setOpened(true)}
      />
      <Modal title='Image' opened={opened} onClose={() => setOpened(false)} size='xl'>
        <Image width='100%' withPlaceholder src={src} />
      </Modal>
    </>
  );
}

export default ImageThumbnail;
