import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { store } from './app/store';
import { Provider, useSelector } from 'react-redux';
import { MantineProvider } from '@mantine/core';
import { BrowserRouter } from 'react-router-dom';
import { selectColourScheme } from './features/ui/uiSlice';
import { NotificationsProvider } from '@mantine/notifications';
import NotificationListener from './components/NotificationListener/NotificationListener';
import { SWRConfig } from 'swr';
import { ModalsProvider } from '@mantine/modals';
import QuickSearch from './components/QuickSearch/QuickSearch';

function ThemedMantineProvider(props) {
  const colorScheme = useSelector(selectColourScheme);
  return (
    <MantineProvider
      theme={{
        colorScheme,
        fontFamily: '"Inter", sans-serif',
        components: {
          Anchor: {
            styles: (theme) => ({
              root: {
                padding: '4px 12px',
                ':hover': {
                  textDecoration: 'none',
                  backgroundColor:
                    theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[2],
                  padding: '4px 12px',
                  borderRadius: 12,
                },
              },
            }),
          },
        },
      }}
      withGlobalStyles
      withNormalizeCSS
    >
      <ModalsProvider modals={{ quickSearch: QuickSearch }}>
        <NotificationsProvider>
          <NotificationListener />
          <SWRConfig value={{ provider: () => new Map() }}>
            {/* <Global
              styles={(theme) => ({
                Anchor: (theme, params) => ({
                  root: {
                    color: theme.colorScheme === 'dark' ? theme.colors.gray[4] : theme.colors.dark[8],
                  },
                }),
              })}
            /> */}
            <App />
          </SWRConfig>
        </NotificationsProvider>
      </ModalsProvider>
    </MantineProvider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ThemedMantineProvider />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
