import { getVenueRoleText } from '../../common/commonResolvers';
import VenueLink from '../../components/venues/VenueLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Fragment } from 'react';
import { Stack, Text, Title } from '@mantine/core';
import NewVenueForm from '../../components/venues/NewVenueForm';
import VenueMobileCellRenderer from '../../components/venues/VenueMobileCellRenderer';
import VenueSettingsButton from '../../components/venues/VenueSettingsButton';

const venuesModel = {
  title: 'Venues',
  readFrom: 'swr',
  service: 'venues',
  defaultSort: 'name',
  key: 'id',
  injectGetQuery: ['orgId'],
  colDefs: [
    {
      key: 'name',
      label: 'Name',
      getter: (data) => data.name,
      width: '100%',
      flex: '2 1',
      hideBreak: 'sm',
      cellRenderer: ({ data, cellData }) => <VenueLink data={data} cellData={cellData} />,
    },
    {
      key: 'shortname',
      label: 'Short',
      getter: (data) => data.shortname,
      width: '15%',
      flex: '2 1',
      hideBreak: 'sm',
    },
    {
      key: 'group',
      label: 'Group',
      getter: (data) => data.venueGroup,
      width: '15%',
      flex: '2 1',
      hideBreak: 'sm',
    },
    {
      key: 'mobileLeft',
      label: 'Venue',
      width: '80%',
      flex: '1',
      showBreak: 'sm',
      cellRenderer: VenueMobileCellRenderer,
    },
    {
      key: 'role',
      label: 'Role',
      getter: (data) => getVenueRoleText(data),
      width: '80px',
      hideBreak: 'sm',
    },
    {
      key: 'settings',
      label: 'Settings',
      cellRenderer: ({ data }) => <VenueSettingsButton venue={data} />,
      width: '56px',
      align: 'center',
      hideBreak: 'sm',
    },
    {
      key: 'settingsMobile',
      label: '',
      cellRenderer: ({ data }) => (
        <Stack spacing={0}>
          <VenueSettingsButton venue={data} />
          <Text size='xs' color='dimmed'>
            {getVenueRoleText(data)}
          </Text>
        </Stack>
      ),
      width: '70px',
      align: 'right',
      showBreak: 'sm',
    },
  ],
  toolbar: ['create'],
  actions: {
    create: {
      type: 'modal',
      icon: <FontAwesomeIcon icon={faPlus} />,
      label: 'New Venue',
      title: 'New Venue',
      content: <NewVenueForm />,
    },
  },
  sidebar: (
    <Fragment>
      <Title order={5}>Your Venues</Title>
      <Text size='sm' color={'dimmed'}>
        These are the venues belonging to the current organization.
      </Text>
      <Text size='sm' weight={'bold'} mt='sm'>
        Roles
      </Text>
      <Text size='sm' color={'dimmed'} mt='xs'>
        <strong>Admin:</strong> Admins can manage venues, fixtures and venue users.
      </Text>
      <Text size='sm' color={'dimmed'} mt='xs'>
        <strong>Users:</strong> Can view venues read only, or collaborate (add and modify) on
        fixtures.
      </Text>
    </Fragment>
  ),
};

export default venuesModel;
