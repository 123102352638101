import React from 'react';

import { Box } from '@mantine/core';

import GroupRow from '../RepeatingGroup/GroupRow';
import HeaderRow from '../RepeatingGroup/HeaderRow';
import { themeRepeatingGroup } from '../../common/commonStyles';

function RepeatingGroup({ colDefs, model = null, colKey, data = [] }) {
  return (
    <Box>
      <Box
        sx={(theme) => ({
          ...themeRepeatingGroup(theme),
        })}
      >
        <HeaderRow model={model || { colDefs }} />
        {data?.map((row) => {
          return <GroupRow model={model || { colDefs }} row={row} key={row[colKey]} />;
        })}
      </Box>
    </Box>
  );
}

export default RepeatingGroup;
