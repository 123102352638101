import React from 'react';
import { Group, Menu } from '@mantine/core';
import ThemeToggle from './ThemeToggle';
import { useDispatch } from 'react-redux';
import { logout } from '../../features/auth/authSlice';
import OrgSelector from '../orgs/OrgSelector';

function TopMenu({ menuControl, ...rest }) {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout);
  };

  return (
    <Group style={{ width: '100%' }} {...rest}>
      <Menu position='top' withArrow closeOnItemClick={false}>
        <Menu.Target>{menuControl}</Menu.Target>
        <Menu.Dropdown>
          <Menu.Label>Theme</Menu.Label>
          <Menu.Item>
            <ThemeToggle />
          </Menu.Item>

          <Menu.Label>Organization</Menu.Label>
          <Menu.Item>
            <OrgSelector />
          </Menu.Item>

          <Menu.Label>Account</Menu.Label>
          <Menu.Item>Settings</Menu.Item>
          <Menu.Item onClick={handleLogout}>Log Out</Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
}

export default TopMenu;
