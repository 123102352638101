import { Group, Text } from '@mantine/core';
import React from 'react';
import { themeHeaderRow, themeHeaderTextColor } from '../../common/commonStyles';

function HeaderCell({ col }) {
  return (
    <Group
      sx={(theme) => ({
        width: col.width,
        minWidth: col.minWidth || 20,
        maxWidth: col.width?.endsWith('%') ? undefined : col.width,
        flex: col.flex,
        [`@media (max-width: ${theme.breakpoints[col.hideBreak ?? 'xs']}px)`]: col.hideBreak
          ? { display: 'none' }
          : undefined,
        [`@media (min-width: ${theme.breakpoints[col.showBreak ?? 'xs']}px)`]: col.showBreak
          ? { display: 'none' }
          : undefined,
        justifyContent: col.align,
      })}
    >
      <Text weight='700' size='sm' sx={(theme) => ({ ...themeHeaderTextColor(theme) })}>
        {col.label}
      </Text>
    </Group>
  );
}

function HeaderRow({ model }) {
  const { colDefs } = model;

  return (
    <Group
      sx={(theme) => ({
        ...themeHeaderRow(theme),
        flexWrap: 'nowrap',
      })}
    >
      {model.nestedRows && <Group style={{ width: 28 }}></Group>}
      {colDefs?.map((col) => (
        <HeaderCell key={col.key} col={col} />
      ))}
    </Group>
  );
}

export default HeaderRow;
