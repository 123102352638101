import {
  faCity,
  faMapMarkerAlt,
  faRobot,
  faBuilding,
  faIdBadge,
  faDashboard,
  faBook,
  faSearch,
  faTicket,
  faWrench,
  faTags,
  faHistory,
} from '@fortawesome/free-solid-svg-icons';

import { Box, Divider, MediaQuery, Navbar, ScrollArea, useMantineTheme } from '@mantine/core';
import React, { Fragment } from 'react';
import { useMatch } from 'react-router-dom';
import useSWR from 'swr';
import { getter } from '../../app/feathersClient';
import { flexCenterCenter } from '../../common/commonStyles';
import HeaderLogo from '../HeaderContent/HeaderLogo';
import TopMenu from '../HeaderContent/TopMenu';
import QuickSearchButton from '../QuickSearch/QuickSearchButton';

import UserButton from '../UserButton/UserButton';
import NavButton from './NavButton';

function NavbarContent({ menuClose }) {
  const matchOrgPath = useMatch({ path: '/orgs/:orgId/*', exact: false });
  const matchVenuePath = useMatch({ path: '/orgs/:orgId/venues/:venueId/*', exact: false });
  const orgId = matchOrgPath?.params?.orgId;
  const venueId = matchVenuePath?.params?.venueId;
  const { data: org } = useSWR(orgId ? `orgs/${orgId}` : null, getter);
  const { data: venue } = useSWR(venueId ? `venues/${venueId}` : null, getter);
  const theme = useMantineTheme();

  return (
    <Fragment>
      <MediaQuery smallerThan={'xs'} styles={{ display: 'none' }}>
        <Navbar.Section
          sx={(theme) => ({
            // minHeight: 60,
            width: '100%',
            ...flexCenterCenter,
            flexWrap: 'wrap',
            borderBottom: `1px solid ${
              theme.colorScheme === 'dark' ? theme.colors.gray[9] : theme.colors.gray[2]
            }`,
          })}
        >
          <MediaQuery
            smallerThan='lg'
            styles={{
              minHeight: '39px',
            }}
          >
            <Box
              sx={(theme) => ({
                ...flexCenterCenter,
                width: '100%',
                minWidth: '64px',
                flex: '1',
              })}
            >
              <HeaderLogo />
            </Box>
          </MediaQuery>
          <MediaQuery
            smallerThan='lg'
            styles={{
              width: '100%',
              height: '76px',
              minHeight: '76px',
              borderLeft: 'none',
              borderTop: `1px solid ${
                theme.colorScheme === 'dark' ? theme.colors.gray[9] : theme.colors.gray[2]
              }`,
            }}
          >
            <Box
              sx={(theme) => ({
                height: '100%',
                maxHeight: '60px',

                padding: '0 0',
                borderLeft: `1px solid ${
                  theme.colorScheme === 'dark' ? theme.colors.gray[9] : theme.colors.gray[2]
                }`,

                ...flexCenterCenter,
              })}
            >
              <QuickSearchButton />
            </Box>
          </MediaQuery>
        </Navbar.Section>
      </MediaQuery>

      <Navbar.Section grow component={ScrollArea}>
        <NavButton icon={faCity} title='Organizations' path='orgs' menuClose={menuClose} />
        {orgId && (
          <>
            <MediaQuery smallerThan={'lg'} styles={{ display: 'none' }}>
              <Divider label={org?.name} m='sm' />
            </MediaQuery>
            <MediaQuery largerThan={'lg'} styles={{ display: 'none' }}>
              <Divider label={org?.id.toUpperCase()} labelPosition='center' m='sm' />
            </MediaQuery>
            <NavButton
              icon={faDashboard}
              title='Dashboard'
              path={`orgs/${orgId}`}
              menuClose={menuClose}
            />
            <NavButton
              icon={faIdBadge}
              title='Fixture Types'
              path={`orgs/${orgId}/types`}
              menuClose={menuClose}
            />
            <NavButton
              icon={faBuilding}
              title='Venues'
              path={`orgs/${orgId}/venues`}
              menuClose={menuClose}
            />
            <NavButton
              icon={faTags}
              title='Service Tags'
              path={`orgs/${orgId}/service-tags`}
              menuClose={menuClose}
            />
          </>
        )}
        {venueId && (
          <>
            <MediaQuery smallerThan={'lg'} styles={{ display: 'none' }}>
              <Divider label={venue?.name} m='sm' />
            </MediaQuery>
            <MediaQuery largerThan={'lg'} styles={{ display: 'none' }}>
              <Divider label={venue?.shortname.toUpperCase()} labelPosition='center' m='sm' />
            </MediaQuery>
            <NavButton
              icon={faRobot}
              title='Fixtures'
              path={`orgs/${orgId}/venues/${venueId}/fixtures`}
              menuClose={menuClose}
            />
            <NavButton
              icon={faMapMarkerAlt}
              title='Positions'
              path={`orgs/${orgId}/venues/${venueId}/positions`}
              menuClose={menuClose}
            />
            <NavButton
              icon={faBook}
              title='Logs'
              path={`orgs/${orgId}/venues/${venueId}/logs`}
              menuClose={menuClose}
            />
            <NavButton
              icon={faTicket}
              title='Issue Tickets'
              path={`orgs/${orgId}/venues/${venueId}/tickets`}
              menuClose={menuClose}
            />
            <NavButton
              icon={faWrench}
              title='Issues'
              path={`orgs/${orgId}/venues/${venueId}/issues`}
              menuClose={menuClose}
            />
            <NavButton
              icon={faSearch}
              title='Views'
              path={`orgs/${orgId}/venues/${venueId}/views`}
              menuClose={menuClose}
            />
            <NavButton
              icon={faHistory}
              title='Service History'
              path={`orgs/${orgId}/venues/${venueId}/service-history`}
              menuClose={menuClose}
            />
          </>
        )}
      </Navbar.Section>
      <Navbar.Section>
        <Box
          sx={(theme) => ({
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',

            borderTop: `1px solid ${
              theme.colorScheme === 'dark' ? theme.colors.gray[9] : theme.colors.gray[3]
            }`,
          })}
        >
          <MediaQuery
            query={`(max-width: ${theme.breakpoints.xs}px) or (min-width: ${theme.breakpoints.lg}px)`}
            styles={{ display: 'none' }}
          >
            <TopMenu menuControl={<UserButton collapse />} />
          </MediaQuery>
          <MediaQuery
            query={`(max-width: ${theme.breakpoints.lg}px) and (min-width: ${theme.breakpoints.xs}px)`}
            styles={{ display: 'none' }}
          >
            <TopMenu menuControl={<UserButton />} />
          </MediaQuery>
        </Box>
      </Navbar.Section>
    </Fragment>
  );
}

export default NavbarContent;
