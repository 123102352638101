import {
  faArrows,
  faBoltLightning,
  faCircleInfo,
  faCog,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';

const logTypesMap = [
  {
    id: 0,
    name: 'Unknown',
    color: 'gray',
    icon: faQuestionCircle,
  },
  {
    id: 1,
    name: 'Info',
    color: 'blue',
    icon: faCircleInfo,
  },
  {
    id: 2,
    name: 'Service',
    color: 'teal',
    icon: faCog,
  },
  {
    id: 3,
    name: 'Problem',
    color: 'orange',
    icon: faBoltLightning,
  },
  {
    id: 4,
    name: 'Move',
    color: 'violet',
    icon: faArrows,
  },
];

export default logTypesMap;
