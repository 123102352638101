import React, { useEffect, useState } from 'react';
import {
  TextInput,
  PasswordInput,
  Button,
  Group,
  Anchor,
  Text,
  Container,
  Paper,
  Checkbox,
  LoadingOverlay,
} from '@mantine/core';
import { useForm } from '@mantine/form';

import { RX_VALID_EMAIL } from '../../common/commonRegex';

import { useDispatch, useSelector } from 'react-redux';
import {
  authenticate,
  selectIsAuthenticating,
  createUser,
  selectUserIsCreating,
} from '../../features/auth/authSlice';
import { Link, useSearchParams } from 'react-router-dom';

function LoginForm({ mode }) {
  const [searchParams, setSearchParams] = useSearchParams();
  // console.log(searchParams.get('fshortId'));
  const authenticationPending = useSelector(selectIsAuthenticating);
  const registrationPending = useSelector(selectUserIsCreating);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const form = useForm({
    initialValues: {
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
    validateInputOnBlur: true,
    validate: {
      username: (value) => {
        if (mode === 'login') {
          return null;
        }
        return value.trim().length >= 1 ? null : 'Please enter a username';
      },
      email: (value) => {
        return RX_VALID_EMAIL.test(value) ? null : 'Must be a valid email address';
      },
      password: (value) =>
        value.length >= 8 && value.length <= 64
          ? null
          : 'Password must be between 8 and 64 characters long',
      confirmPassword: (value, values) =>
        mode === 'login' ? null : value === values.password ? null : 'Passwords must match',
    },
  });
  useEffect(() => {
    setError(null);
    //eslint-disable-next-line
  }, [mode]);

  const handleSubmit = async () => {
    if (mode === 'login') {
      try {
        const result = await dispatch(
          authenticate({ email: form.values.email.toLowerCase(), password: form.values.password })
        );
        // Redirect if shortId is in search params
        if (searchParams.get('fshortId')) {
          dispatch({
            type: 'ui/initiateRedirect',
            payload: `/f/${searchParams.get('fshortId')}`,
          });
        }
        if (result.type !== 'auth/create/fulfilled') {
          setError(result.error.message);
        }
      } catch (error) {
        console.log(error);
      }
      return;
    }
    try {
      const result = await dispatch(
        createUser({
          username: form.values.username,
          email: form.values.email.toLowerCase(),
          password: form.values.password,
        })
      );
      if (result.type === 'users/create/fulfilled') {
        await dispatch(authenticate({ email: form.values.email, password: form.values.password }));
      } else {
        setError(result.error.message);
        throw result.error;
      }
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };
  const switchPromptText = mode === 'login' ? 'No account?' : 'Have an account?';
  const buttonActionText = mode === 'login' ? 'Login' : 'Register';
  const switchPromptPath = mode === 'login' ? 'Register' : 'Login';

  return (
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Container size={420} my={40} style={{ width: 320 }}>
        <LoadingOverlay visible={registrationPending || authenticationPending} />
        <Paper shadow='md' p={30} mt={30} radius='md' withBorder>
          <TextInput
            label='Email'
            placeholder='you@fixtr.io'
            data-autofocus
            required
            {...form.getInputProps('email')}
          />

          {mode === 'register' && (
            <TextInput
              mt='md'
              label='Username'
              placeholder='Username'
              required
              {...form.getInputProps('username')}
            />
          )}
          <PasswordInput
            label='Password'
            placeholder='Your password'
            required
            mt='md'
            {...form.getInputProps('password')}
          />
          {mode === 'register' && (
            <PasswordInput
              label='Confirm Password'
              required
              {...form.getInputProps('confirmPassword')}
              mt='md'
            />
          )}

          {error && (
            <Text color='red' size='sm' mt='sm'>
              {error}
            </Text>
          )}

          {/* <Group position='apart' mt='md'>
            <Checkbox label='Remember me' />
            <Anchor onClick={(event) => event.preventDefault()} href='#' size='sm'>
              Forgot password?
            </Anchor>
          </Group> */}
          <Button fullWidth mt='xl' type='submit'>
            {buttonActionText}
          </Button>
        </Paper>

        <Text color='dimmed' size='sm' align='center' mt={'xl'}>
          {switchPromptText}{' '}
          <Anchor component={Link} to={`/${switchPromptPath.toLowerCase()}`} size='sm'>
            {switchPromptPath}
          </Anchor>
        </Text>
      </Container>
    </form>
  );
  // return (
  //   <form onSubmit={form.onSubmit(handleSubmit)}>
  //     <LoadingOverlay visible={registrationPending || authenticationPending} />
  //     {mode === 'register' && (
  //       <TextInput
  //         icon={<FontAwesomeIcon icon={faUser} />}
  //         label='User Name'
  //         data-autofocus
  //         onBlur={() => form.validateField('username')}
  //         required
  //         {...form.getInputProps('username')}
  //       />
  //     )}
  //     <TextInput
  //       icon={<FontAwesomeIcon icon={faEnvelope} />}
  //       label='Email'
  //       data-autofocus
  //       required
  //       onBlur={() => form.validateField('email')}
  //       {...form.getInputProps('email')}
  //       mt={mode === 'login' ? 0 : 'md'}
  //     />
  //     <PasswordInput
  //       icon={<FontAwesomeIcon icon={faLock} />}
  //       label='Password'
  //       required
  //       onBlur={() => form.validateField('password')}
  //       {...form.getInputProps('password')}
  //       mt='md'
  //     />
  //     {mode === 'register' && (
  //       <PasswordInput
  //         icon={<FontAwesomeIcon icon={faLock} />}
  //         label='Confirm Password'
  //         required
  //         onBlur={() => form.validateField('confirmPassword')}
  //         {...form.getInputProps('confirmPassword')}
  //         mt='md'
  //       />
  //     )}
  //     {error && (
  //       <Text color='red' size='sm' mt='sm'>
  //         {error}
  //       </Text>
  //     )}
  //     <Group position='apart' mt='xl'>
  //       <Anchor component={Link} to={`/${switchPromptPath}`} type='button' color='gray' size='xs'>
  //         {switchPromptText}
  //       </Anchor>
  //       <Button color='blue' type='submit'>
  //         {buttonActionText}
  //       </Button>
  //     </Group>
  //   </form>
  // );
}

export default LoginForm;
