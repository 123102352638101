import {
  FileInput,
  Paper,
  Select,
  Text,
  Title,
  Button,
  SegmentedControl,
  Stack,
  LoadingOverlay,
} from '@mantine/core';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { read, utils } from 'xlsx';
import { restClientPath } from '../../app/feathersClient';
import { selectAuth } from '../../features/auth/authSlice';

function DataImportForm({ venueId, orgId }) {
  const [file, setFile] = useState(null);
  const [sheetnames, setSheetnames] = useState([]);
  const [sheet, setSheet] = useState('');
  const [type, setType] = useState('fixtures');
  const [processing, setProcessing] = useState(false);
  const [result, setResult] = useState('');
  const [error, setError] = useState('');
  const [rows, setRows] = useState();
  const ref = useRef();
  const auth = useSelector(selectAuth);

  useEffect(() => {
    let cancel = false;
    if (processing) {
      setResult('');
      setError('');

      const wk = read(file);
      const data = utils.sheet_to_json(wk.Sheets[sheet]);

      async function submitData() {
        const request = new Request(`${restClientPath}/bulkdata/`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${auth.accessToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            orgId,
            venueId,
            type,
            data,
          }),
        });

        const res = await fetch(request);
        const json = await res.json();

        if (res.ok) {
          setResult(json);
        }
        if (!res.ok) {
          console.log(res);
          setError(json.message || res.statusText);
        }
      }
      submitData();

      setProcessing(false);
    }
    return () => {
      cancel = true;
    };
    //eslint-disable-next-line
  }, [processing]);

  const handleFile = async (inputFile) => {
    const data = await inputFile.arrayBuffer();
    const wk = read(data);
    setSheetnames(wk.SheetNames);
    setFile(data);
  };

  return (
    <Paper withBorder p='md' mt='lg'>
      <LoadingOverlay visible={processing} />
      <Title order={4}>Data Import</Title>
      <Text>Upload Positions or Fixtures via Excel file</Text>

      <FileInput label={'Upload XLSX File'} mt='md' onChange={handleFile} ref={ref} />

      <Select
        required
        label={'Select Sheet to Import'}
        data={sheetnames}
        mt='md'
        disabled={!file}
        value={sheet}
        onChange={setSheet}
      />
      <Stack spacing={0}>
        <Text mt='md' size='sm' weight={500}>
          Data to Import
        </Text>
        <SegmentedControl
          label='Data to Import'
          disabled={!file}
          data={[
            { label: 'Fixtures', value: 'fixtures' },
            { label: 'Positions', value: 'positions' },
          ]}
          value={type}
          onChange={setType}
        />
      </Stack>

      <Button mt='lg' fullWidth disabled={!file && !sheet} onClick={() => setProcessing(true)}>
        Process
      </Button>
      {result && <Text mt='md'>{result}</Text>}
      {error && (
        <Text mt='md' color={'red'}>
          {error}
        </Text>
      )}
    </Paper>
  );
}

export default DataImportForm;

// useEffect(() => {
//   let cancel = false;
//   if (processing) {
//     setResult('');
//     setError('');

//     const wk = read(file);
//     const data = utils.sheet_to_json(wk.Sheets[sheet]);

//     async function submitData() {
//       const request = new Request(`${restClientPath}/bulkdata/`, {
//         method: 'POST',
//         headers: {
//           Authorization: `Bearer ${auth.accessToken}`,
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({
//           orgId,
//           venueId,
//           type,
//           data,
//         }),
//       });

//       const res = await fetch(request);
//       const json = await res.json();

//       if (res.ok) {
//         setResult(json);
//       }
//       if (!res.ok) {
//         setError(res.statusText);
//       }
//     }
//     submitData();

//     setProcessing(false);
//   }
//   return () => {
//     cancel = true;
//   };
//   //eslint-disable-next-line
// }, [processing]);
