// Get plain text org role for a given set of orgs_users permissions
export const ORG_OWNER = 100;
export const ORG_ADMIN = 80;
export const ORG_VENUE_ADMIN = 60;
export const ORG_USER = 1;
export const VENUE_ADMIN = 40;
export const VENUE_COLLAB = 20;
export const VENUE_KIOSK = 20;
export const VENUE_VIEWER = 5;
export const NO_ACCESS = 0;

export function assessOrgAccessLevel(orgAccessRecord) {
  if (!orgAccessRecord) return NO_ACCESS;
  if (orgAccessRecord.isOrgOwner) return ORG_OWNER;
  if (orgAccessRecord.isOrgAdmin) return ORG_ADMIN;
  if (orgAccessRecord.isVenueAdmin) return ORG_VENUE_ADMIN;
  if (orgAccessRecord) return ORG_USER;
  return NO_ACCESS;
}
export function assessVenueAccessLevel(venueAccess) {
  if (!venueAccess) return NO_ACCESS;
  if (venueAccess.isAdmin) return VENUE_ADMIN;
  if (venueAccess.isCollab) return VENUE_COLLAB;
  if (venueAccess.isKiosk) return VENUE_KIOSK;
  if (venueAccess) return VENUE_VIEWER;
  return NO_ACCESS;
}

export function getCompositeAccess(venue, org) {
  if (!org || !venue) return NO_ACCESS;
  return Math.max(assessOrgAccessLevel(org), assessVenueAccessLevel(venue));
}

export const getOrgRoleText = ({ isOrgOwner, isOrgAdmin, isVenueAdmin, isInvite }) => {
  if (isOrgOwner) return 'Owner';
  if (isOrgAdmin) return 'Admin';
  // Skip this, it's confusing in the front end
  // if (isVenueAdmin) return 'Venue Admin';
  // if (isInvite) return 'Invited';
  return 'User';
};

export const getOrgStatus = ({ isInvite, isOrgOwner, acceptedAtDate, createdAtDate }) => {
  if (isInvite) return { statusText: 'Invited', isInvite: true, date: createdAtDate };
  return {
    statusText: isOrgOwner ? 'Owner' : 'Member',
    isInvite: false,
    date: acceptedAtDate || createdAtDate,
  };
};

// Get plain text venue role for a given set of venues_users permissions
export const getVenueRoleText = ({ isCollab, isAdmin, isOrgAdmin, isOrgOwner, isKiosk }) => {
  if (isOrgOwner) return 'Org Owner';
  if (isOrgAdmin) return 'Org Admin';
  if (isAdmin) return 'Admin';
  if (isCollab) return 'Collaborator';
  if (isKiosk) return 'Kiosk';
  return 'Viewer';
};

// Returns array of venue groups from a given array of venues
export const getVenueGroups = (venues = []) => {
  const venueGroups = new Set();
  venues.forEach((venue) => {
    if (venue.venueGroup) venueGroups.add(venue.venueGroup);
  });
  return [...venueGroups];
};

// Returns object of calculated pagination values
export const getPaginationValues = (totalItems, pageSize, page) => {
  return {
    totalPages: Math.max(Math.ceil(totalItems / pageSize), 1),
    firstIndex: (page - 1) * pageSize,
    finalIndex: page * pageSize,
  };
};

// Returns a text colour or hex colour
export const getColourValue = (string) => {
  if (string.slice(0, 1) === '!') {
    return string.slice(1, string.length).trim();
  }
  if (string.slice(0, 1) === '#') {
    return string;
  }
  return '#000000';
};

// Get position full name elements
export const getPositionCompositeName = (position, separator = '/', excludeVenue = false) => {
  if (!position || typeof position === 'string') return 'unassigned';
  // console.log('commonResolvers - positionCompositeName', position);
  const parent = position.parent || null;
  const venue = position.venue;

  const venueName = venue?.shortname?.toUpperCase() || venue?.name || '';
  const parentName = parent?.name || '';
  const positionName = position.name;

  if (excludeVenue) {
    return `${parentName && `${parentName} ${separator} `}${positionName}`;
  }

  return `${venueName} ${separator} ${parentName && `${parentName} ${separator} `}${positionName}`;
};

// Get position composite string from logMeta
export const getPositionShortNameFromLog = (log, separator = '/', context = 'current') => {
  const position = log?.logMeta?.position;
  const venue = log?.logMeta?.venue;

  if (!position || !venue) return 'unknown';
  if (venue && !position[context]) return 'unassigned';
  const venueName = venue.current.shortname || venue.current.name || '';
  const parentName = position[context]?.parentShortname || position[context]?.parentName || '';
  const positionName = position[context]?.shortname || position[context]?.name || '';

  return `${venueName.toUpperCase()} ${separator} ${
    parentName && `${parentName.toUpperCase()} ${separator} `
  }${positionName.toUpperCase()}`;
};

// Get fixture composite name
export const getFixtureCompositeName = (fixture) => {
  return `${fixture?.identifier}${fixture?.nickname ? ` (${fixture.nickname})` : ''}`;
};

// Get issue ticket composite name
export const getIssueTicketCompositeName = (ticket) => {
  return `[${ticket?.prefix.toUpperCase()}${ticket?.number}] ${ticket?.name}`;
};
