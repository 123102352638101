import React from 'react';
import { Box, Paper, Center, Container } from '@mantine/core';
import { flexCenterCenter } from '../../common/commonStyles';
import Logo from '../Logo/Logo';
import LoginForm from '../LoginForm/LoginForm';

function LoginScreen({ mode }) {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.colors.dark[6],
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        ...flexCenterCenter,
      })}
    >
      <Container>
        <Center>
          <Logo size={50} />
        </Center>

        <LoginForm mode={mode} />
      </Container>
    </Box>
  );
}

export default LoginScreen;
