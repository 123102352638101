import React from 'react';
import { Text, Center } from '@mantine/core';

function Logo({ size = 50, ...rest }) {
  return (
    <Center style={{ width: 90, maxWidth: 90, height: '100%' }} {...rest}>
      <Text
        weight='500'
        variant='gradient'
        gradient={{ from: 'indigo', to: 'cyan', deg: 45 }}
        sx={(theme) => ({
          fontSize: size,
        })}
      >
        fixtr
      </Text>
    </Center>
  );
}

export default Logo;
