import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import app from '../../app/feathersClient';

export const authenticate = createAsyncThunk('auth/create', async (payload) => {
  return await app.authenticate({ ...payload, strategy: 'local' });
});

export const createUser = createAsyncThunk('users/create', async (payload) => {
  return await app.service('users').create(payload);
});

const initialState = {
  readyToRender: false,
  isAuthenticating: false,
  isCreating: false,
  authState: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: {
    'auth/create/pending': (state) => {
      state.isAuthenticating = true;
      state.readyToRender = true;
    },
    'auth/create/rejected': (state) => {
      state.isAuthenticating = false;
    },
    'auth/create/fulfilled': (state, action) => {
      state.isAuthenticating = false;
      state.authState = 'authenticated';
      Object.assign(state, action.payload);
    },
    'auth/reauth/fulfilled': (state, action) => {
      state.authState = 'authenticated';
      Object.assign(state, action.payload, { readyToRender: true });
    },
    'auth/reauth/rejected': (state) => {
      state.authState = 'failedReauthentication';
      state.readyToRender = true;
    },
    'auth/logout/fulfilled': () => {
      return { ...initialState, readyToRender: true };
    },
    'auth/usercreate/pending': (state) => {
      state.isCreating = true;
    },
    'auth/usercreate/rejected': (state) => {
      state.isCreating = false;
    },
    'auth/usercreate/fulfilled': (state, action) => {
      return {
        ...state,
        isCreating: false,
        data: {
          ...state.data,
          [action.payload.id]: action.payload,
        },
      };
    },
  },
});

export const logout = { type: 'auth/logout', payload: {} };

export const selectAppShouldRender = (state) => state.auth.readyToRender;
export const selectIsAuthenticating = (state) => state.auth.isAuthenticating;
export const selectIsAuthenticated = (state) =>
  (state.auth?.accessToken && state.auth?.authentication) || null;
export const selectAuth = (state) => state.auth?.authentication || null;
export const selectAuthUserId = (state) => state.auth.user.id;
export const selectUserIsCreating = (state) => state.auth.isCreating;
export const selectAuthUser = (state) => state.auth?.user || null;
export const selectAuthState = (state) => state.auth.authState;

export default authSlice.reducer;
