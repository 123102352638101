import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Fragment } from 'react';
import { Group, Stack, Text, Title } from '@mantine/core';
import ActionButtons from '../../components/ActionButtons/ActionButtons';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import GenericDeleteConfirmation from '../../components/ObjectAction/GenericDeleteConfirmation';
import FixtureForm from '../../components/fixtures/FixtureForm';
import PositionBadgeRenderer from '../../components/positions/PositionBadgeRenderer';
import StatusBadgeRenderer from '../../components/statusTypes/StatusBadgeRenderer';
import FixtureLink from '../../components/fixtures/FixtureLink';

const fixturesModel = {
  title: 'Fixtures',
  readFrom: 'swr',
  service: 'fixtures',
  defaultSort: 'identifier',
  injectGetQuery: ['orgId', 'venueId'],
  key: 'id',
  nestedRows: false,
  logging: false,
  colDefs: [
    {
      key: 'statusCondition',
      label: '',
      getter: (data) => data,
      cellRenderer: (props) => <StatusBadgeRenderer statusId={props.data.status} chip />,
      width: '12px',
      minWidth: '12px',
      maxWidth: '12px',
      flex: '0',
      hideBreak: 'sm',
    },
    {
      key: 'identifier',
      label: 'Identifier',
      getter: (data) => (data.nickname ? `${data.identifier} (${data.nickname})` : data.identifier),
      cellRenderer: ({ data, cellData }) => {
        // console.log(data, cellData);
        return <FixtureLink data={data} cellData={cellData} />;
      },
      width: '35%',
      flex: '2 1',
      hideBreak: 'sm',
    },
    {
      key: 'fixtureType',
      label: 'Fixture Type',
      getter: (data) => (data.fixtureType ? data.fixtureType?.name : ''),
      width: '15%',
      flex: '2 1',
      hideBreak: 'sm',
    },
    {
      key: 'position',
      label: 'Position',
      getter: (data) => data.position,
      cellRenderer: (props) => {
        return <PositionBadgeRenderer positionId={props.data?.position?.id} showVenue />;
      },
      width: '20%',
      hideBreak: 'sm',
    },
    {
      key: 'update',
      label: 'Actions',
      getter: (data) => data,
      width: '56px',
      minWidth: '56px',
      hideBreak: 'sm',
      cellRenderer: (props) => <ActionButtons {...props} />,
    },
    {
      key: 'mobile',
      label: 'Fixtures',
      getter: (data) => data,
      cellRenderer: (props) => (
        <Stack style={{ width: '100%' }} spacing={0}>
          <Group position='apart' style={{ width: '100%' }}>
            <Group>
              <StatusBadgeRenderer statusId={props.data.status} chip />
              <FixtureLink
                data={props.data}
                cellData={
                  props.data.nickname
                    ? `${props.data.identifier} (${props.data.nickname})`
                    : props.data.identifier
                }
              />
            </Group>

            <PositionBadgeRenderer positionId={props.data?.position?.id} showVenue />
          </Group>
          <Group position='apart' mt='md'>
            <Group>
              <div style={{ width: 12, minWidth: 12 }}></div>
              <Text align='right' size='sm' color='dimmed'>
                {props.data.fixtureType ? props.data.fixtureType?.name : ''}
                {props.data.limitQty ? ` (${props.data.limitQty})` : ''}
              </Text>
            </Group>

            <ActionButtons {...props} />
          </Group>
        </Stack>
      ),
      width: '100%',
      minWidth: '100%',
      showBreak: 'sm',
    },
  ],
  toolbar: ['create'],
  actionButtons: ['patch', 'remove'],
  actions: {
    create: {
      type: 'modal',
      icon: <FontAwesomeIcon icon={faPlus} />,
      label: 'New Fixture',
      title: 'New Fixture',
      content: <FixtureForm action={'create'} />,
    },
    patch: {
      type: 'modal',
      icon: <FontAwesomeIcon icon={faEdit} />,
      label: 'Edit Fixture',
      title: 'Edit Fixture',
      content: <FixtureForm />,
    },
    remove: {
      type: 'modal',
      icon: <FontAwesomeIcon icon={faTrash} />,
      label: 'Delete Fixture',
      title: 'Delete Fixture',
      content: <GenericDeleteConfirmation />,
    },
  },
  sidebar: (
    <Fragment>
      <Title order={5}>Fixtures</Title>
      <Text size='sm' color={'dimmed'}>
        Fixtures belong to venues.
      </Text>
    </Fragment>
  ),
};

export default fixturesModel;
