import { Box, Group, Title, Text, Loader, Mark, ActionIcon } from '@mantine/core';
import React from 'react';
import useSWR from 'swr';
import { getter } from '../../app/feathersClient';
import PositionBadgeRenderer from '../positions/PositionBadgeRenderer';
import StatusBadgeRenderer from '../statusTypes/StatusBadgeRenderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

function FixtureLabelBlock({ fixtureId }) {
  const { data: fixture } = useSWR(`fixtures/${fixtureId}`, getter);
  const { data: type } = useSWR(
    fixture && fixture.typeId ? `fixture-types/${fixture.typeId}` : false,
    getter
  );

  if (!fixture) return <Loader />;

  return (
    <>
      <Group position='apart'>
        <Title order={2}>
          {fixture.identifier}
          {fixture.nickname && ` ( ${fixture.nickname} )`}
        </Title>
        <Box>
          <PositionBadgeRenderer positionId={fixture.positionId} />
        </Box>
      </Group>
      <Group position='apart' mt='xs' mb='xs'>
        <Text color='dimmed'>{type?.name || '?'}</Text>
        <StatusBadgeRenderer statusId={fixture.status} />
      </Group>
      <Group position='right'>
        <Group spacing={'xs'}>
          <Text color='dimmed' size='xs'>
            Short ID:{' '}
            <span style={{ userSelect: 'all', fontFamily: 'Courier, monospace', fontSize: '14px' }}>
              {fixture.shortId}
            </span>
          </Text>
          <ActionIcon
            onClick={() => {
              navigator.clipboard.writeText(
                `${
                  window.location.protocol === 'http:' ? 'localhost:3000' : 'https://fixtr.io'
                }/f/${fixture.shortId}`
              );
            }}
          >
            {' '}
            <FontAwesomeIcon icon={faCopy} size='sm' color='grey' />
          </ActionIcon>
        </Group>
      </Group>
    </>
  );
}

export default FixtureLabelBlock;
