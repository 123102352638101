import { Accordion, LoadingOverlay, useMantineTheme, Pagination } from '@mantine/core';
import React, { useState } from 'react';
import useSWR from 'swr';
import { fetcher } from '../../app/feathersClient';
import { getPaginationValues } from '../../common/commonResolvers';

import LogContent from './LogContent';
import LogEntryLabel from './LogEntryLabel';

function FixtureLogList({ fixtureId, pageSize = 10, query = {} }) {
  const [page, setPage] = useState(1);
  const { data, error } = useSWR(
    {
      service: 'logs',
      query: {
        ...query,
        fixtureId,
        $sort: { createdAt: -1 },
        $limit: pageSize,
        $skip: (page - 1) * pageSize,
      },
    },
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );
  const [accordionState, setAccordionState] = useState([]);

  const pg = getPaginationValues(data?.total || 0, pageSize, page);
  const theme = useMantineTheme();

  if (data === undefined) return <LoadingOverlay visible />;

  const borderColour = theme.colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.gray[3];

  return (
    <>
      <Accordion multiple value={accordionState} onChange={setAccordionState}>
        {data?.data.map((log, index) => {
          return (
            <Accordion.Item
              key={log.id}
              value={log.id}
              sx={(theme) => ({
                backgroundColor:
                  theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.colors.gray[0],
                border: `1px solid ${borderColour}`,
                borderRadius: 4,
              })}
              mb='xs'
            >
              <Accordion.Control>
                <LogEntryLabel log={log} />
              </Accordion.Control>
              <Accordion.Panel>
                {accordionState.find((v) => v === log.id) && <LogContent log={log} />}
              </Accordion.Panel>
            </Accordion.Item>
          );
        })}
      </Accordion>
      <Pagination mt='md' initialPage={1} page={page} total={pg.totalPages} onChange={setPage} />
    </>
  );
}

export default FixtureLogList;
