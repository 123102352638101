import {
  Box,
  Button,
  Chip,
  Divider,
  Group,
  Loader,
  SegmentedControl,
  Text,
  Textarea,
  TextInput,
  Title,
} from '@mantine/core';
import { showNotification } from '@mantine/notifications';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher, getter } from '../../app/feathersClient';
import { getFixtureCompositeName } from '../../common/commonResolvers';
import useMatchMutate from '../../hooks/useMatchMutate';

function EditLogForm({
  target,
  secondaryTarget: initialFixture,
  onAbort,
  onClose,
  onSubmit,
  error,
  success,
  ...rest
}) {
  const { venueId } = useParams();

  // **************
  //  Data Loaders
  // **************
  const { data: log } = useSWR(target ? `logs/${target}` : false, getter);
  const { data: fixture } = useSWR(log ? `fixtures/${log.fixtureId}` : false, getter);

  const { data: fixtureType } = useSWR(
    fixture?.typeId ? `fixture-types/${fixture.typeId}` : false,
    getter
  );
  const { data: serviceTags } = useSWR(
    fixture?.id
      ? {
          service: 'service-tags',
          query: {
            orgId: fixture.orgId,
            $limit: 100,
          },
        }
      : false,
    fetcher
  );
  const mutate = useMatchMutate();

  // *******
  //  State
  // *******
  // Log Data State
  const [logType, setLogType] = useState('1');
  const [tags, setTags] = useState([]);
  const [suppressClose, setSuppressClose] = useState(false);

  const [logTitle, setLogTitle] = useState('');
  const [logDescription, setLogDescription] = useState('');

  // Form State
  const [logTitlePristine, setLogTitlePristine] = useState(true);

  useEffect(() => {
    if (log) {
      console.log(log);
      setLogType(log.logType.toString());
      setLogTitle(log.title);
      setLogDescription(log.description);
      setTags(log.tagData.map((tag) => tag.id));
    }
    // eslint-disable-next-line
  }, [log]);

  useEffect(() => {
    if (success) {
      mutate(new RegExp(`fixtures/${fixture.id}`));
      if (!suppressClose) onClose();
      if (suppressClose) {
        showNotification({
          color: 'green',
          title: 'Log Saved',
          message: 'Log saved successfully',
        });
      }
    }
    // eslint-disable-next-line
  }, [success]);

  // SUBMIT
  const handleSubmit = () => {
    const data = {
      logType: parseInt(logType),
      title: logTitle,
      description: logDescription,
      serviceTags: tags,
    };

    onSubmit(data, {});
  };

  // ***************
  //  Form Validity
  // ***************
  const formIsValid = () => {
    if (!logType) return false;
    if (!logTitle) return false;
    return true;
  };

  if (!log) return <Loader />;

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        <Title>{fixture ? getFixtureCompositeName(fixture) : ''}</Title>

        <Text color='dimmed'>{fixtureType?.name}</Text>

        <Divider mt='md' mb='md' />

        <Text size='sm' mb='0' weight='500'>
          Log Type
        </Text>
        <SegmentedControl
          fullWidth
          size='sm'
          label='Log Type'
          value={logType}
          onChange={(data) => {
            setLogType(data);
          }}
          data={[
            { label: 'Info', value: '1' },
            { label: 'Service', value: '2' },
            { label: 'Problem', value: '3' },
            { label: 'Move', value: '4' },
          ]}
        />

        <Text size='sm' mb='0' weight='500' mt='lg'>
          Log Details
        </Text>
        <TextInput
          mt='sm'
          placeholder='Log title'
          value={logTitle}
          onChange={(e) => setLogTitle(e.target.value)}
          onBlur={() => {
            if (logTitle && logTitlePristine) {
              setLogTitlePristine(false);
            }
          }}
        />
        <Textarea
          mt='sm'
          placeholder='Log description'
          value={logDescription}
          onChange={(e) => setLogDescription(e.target.value)}
        ></Textarea>

        <Text size='sm' mb='0' mt='md' weight='500'>
          Service Tags
        </Text>
        <Chip.Group mt='sm' position='center' multiple={true} value={tags} onChange={setTags}>
          {serviceTags?.data &&
            serviceTags.data.map((tag) => (
              <Chip key={tag.id} value={tag.id}>
                {tag.name}
              </Chip>
            ))}
        </Chip.Group>

        <Group position='center' mt='xl'>
          <Button
            disabled={!formIsValid()}
            onClick={() => {
              setSuppressClose(false);
              handleSubmit();
            }}
          >
            Update
          </Button>
        </Group>
        {error && (
          <Text mt='xl' align='center' color={'red'}>
            {error}
          </Text>
        )}
      </Box>
    </>
  );
}

export default EditLogForm;
