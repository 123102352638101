import { Text, Title } from '@mantine/core';
import { Fragment } from 'react';
import FixtureLink from '../../components/fixtures/FixtureLink';

const issuesModel = {
  title: 'Issue Tickets',
  readFrom: 'swr',
  service: 'issues-by-venue',
  injectGetQuery: ['orgId', 'venueId'],
  defaultSort: 'number',
  sortDirection: 'desc',
  key: 'id',
  colDefs: [
    {
      key: 'number',
      label: '#',
      getter: (data) => `[${data.number}]`,
      width: '56px',
      flex: '1 1',
      align: 'center',
    },
    {
      key: 'name',
      label: 'Title',
      getter: (data) => data.name,
      width: '35%',
      flex: '2 1',
      hideBreak: 'sm',
    },
    // Add fixture link
    {
      key: 'fixture',
      label: 'Fixture',
      getter: (data) => data.fixtureId,
      cellRenderer: (data) => {
        // console.log(data);
        return <FixtureLink targetId={data.cellData} size='sm' withStatus />;
      },
      width: '35%',
      flex: '2 1',
      hideBreak: 'sm',
    },
  ],
  toolbar: [],
  actionButtons: [],
  sidebar: (
    <Fragment>
      <Title order={5}>Your Issue Tickets</Title>
      <Text size='sm' color='dimmed'>
        Issue tickets belong to fixtures and have a specific issue type for tracking of common
        issues across fixtures of the same type.
      </Text>
    </Fragment>
  ),
};
export default issuesModel;
