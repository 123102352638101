import React, { useState } from 'react';
import { Text, TextInput, Group, Button, Box, LoadingOverlay } from '@mantine/core';
import { useForm } from '@mantine/form';

import { useSWRConfig } from 'swr';
import app from '../../app/feathersClient';

function EditOrgForm({ org }) {
  const { mutate } = useSWRConfig();
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);
  const form = useForm({
    initialValues: {
      name: org.name,
    },
    validationRules: {
      name: (value) => value.trim().length >= 2 && value.trim().length <= 64,
    },
    errorMessages: {
      name: 'Organization name should be between 2 and 64 characters',
    },
  });

  const handleSubmit = async () => {
    setPending(true);
    try {
      await app.service('orgs').patch(org.id, form.values, {});
      mutate(`orgs/${org.id}`);
      setPending(false);
    } catch (error) {
      setError(error.message);
      setPending(false);
    }
  };

  return (
    <Box>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <LoadingOverlay visible={pending} />
        <TextInput
          placeholder='Organization name'
          label='Organization Name'
          required
          data-autofocus
          {...form.getInputProps('name')}
        />
        {error && (
          <Text color='red' size='sm' mt='sm'>
            {error}
          </Text>
        )}
        <Group position='right' mt='sm'>
          <Button type='submit'>Update</Button>
          <Button variant='outline' type='button' onClick={() => form.reset()}>
            Cancel
          </Button>
        </Group>
      </form>
    </Box>
  );
}

export default EditOrgForm;
