import React from 'react';
import { faCommentDots, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Group, Text, Box, useMantineTheme, Badge, Stack } from '@mantine/core';

import StatusBadgeRenderer from '../statusTypes/StatusBadgeRenderer';
import logTypesMap from '../../common/logTypesMap';
import PositionBadgeRenderer from '../positions/PositionBadgeRenderer';
import { useViewportSize } from '@mantine/hooks';

function LogEntryLabel({ log, clickable = true }) {
  const screenWidth = useViewportSize().width;
  const { title, logType: logTypeId, logMeta } = log;
  const logDate = log.logDate || log.createdAt;

  const theme = useMantineTheme();
  const logType = logTypesMap[logTypeId];
  const logIcon = logType.icon || faQuestionCircle;
  const logColour = 'gray'; //logType.color || 'blue';
  const logColourHex = theme.colors[logColour]?.[6] || '#00bcd4';

  const status = logMeta?.status?.current;

  if (screenWidth < 800) {
    return (
      <Stack spacing='xs' style={{ cursor: clickable ? 'pointer' : 'default' }}>
        <Group position='apart' spacing={'xs'}>
          <Group position='left' spacing='0' style={{ width: '30%', flex: '1 1' }} noWrap>
            <Box
              p='0'
              style={{ width: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <FontAwesomeIcon icon={logIcon} color={logColourHex} />
            </Box>
            <Box
              p='0'
              style={{ width: 32, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              {status && <StatusBadgeRenderer statusId={status.id} chip />}
            </Box>
            {log.issue?.number ? (
              <Text weight='600' color='dimmed' mr='0' pr='0'>{`[${log.issue.prefix.toUpperCase()}${
                log.issue.number
              }] `}</Text>
            ) : null}

            <Text weight={500} size={'sm'}>
              {title}
            </Text>
          </Group>
          <Group>
            <PositionBadgeRenderer
              position={logMeta.position?.current}
              venue={logMeta.venue?.current}
              compact
            />
          </Group>
        </Group>
        <Group position='apart' spacing={'xs'}>
          {log?.tagData?.length > 0 && (
            <Box>
              {log?.tagData?.map((tag) => (
                <Badge key={tag.id} variant='light' style={{ marginRight: 4, marginBottom: 4 }}>
                  {tag.shortname}
                </Badge>
              ))}
            </Box>
          )}
          <Group position='right' spacing='xs'>
            {log?.totalComments > 0 && (
              <Box
                style={{
                  width: 16,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <FontAwesomeIcon icon={faCommentDots} color={logColourHex} />
              </Box>
            )}
            <Text color='dimmed' size='xs'>
              {new Date(logDate).toLocaleDateString()}
            </Text>
          </Group>
        </Group>
      </Stack>
    );
  }

  return (
    <Group position='apart' style={{ cursor: clickable ? 'pointer' : 'default' }}>
      <Group position='left'>
        <Box style={{ width: 16, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <FontAwesomeIcon icon={logIcon} color={logColourHex} />
        </Box>
        <Box
          style={{ width: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          {status && <StatusBadgeRenderer statusId={status.id} />}
        </Box>

        <Group>
          {log.issue?.number ? (
            <Text weight='600' color='dimmed' mr='0' pr='0'>{`[${log.issue.prefix.toUpperCase()}${
              log.issue.number
            }] `}</Text>
          ) : null}

          <Text weight={500}>{title}</Text>
        </Group>
      </Group>
      <Group>
        {log?.tagData?.length > 0 && (
          <Box>
            {log?.tagData?.map((tag) => (
              <Badge key={tag.id} variant='light' style={{ marginRight: 4, marginBottom: 4 }}>
                {tag.shortname}
              </Badge>
            ))}
          </Box>
        )}
        {log?.totalComments > 0 && (
          <Box
            style={{ width: 16, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <FontAwesomeIcon icon={faCommentDots} color={logColourHex} />
          </Box>
        )}
        <PositionBadgeRenderer
          position={logMeta.position?.current}
          venue={logMeta.venue?.current}
        />
        <Text color='dimmed' size='xs'>
          {new Date(logDate).toLocaleDateString()}
        </Text>
      </Group>
    </Group>
  );
}

export default LogEntryLabel;
