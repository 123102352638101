import React, { useEffect, useState } from 'react';
import { Text, TextInput, Group, Button, Box, LoadingOverlay } from '@mantine/core';
import { useForm } from '@mantine/form';
import app from '../../app/feathersClient';
import { useNavigate, useParams } from 'react-router-dom';

function DeleteVenueForm({ handleClose, venue }) {
  const navigate = useNavigate();
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);
  const [remove, setRemove] = useState(false);
  const { orgId } = useParams();

  const form = useForm({
    initialValues: {
      name: '',
    },
    validationRules: {
      name: (value) => value.trim().length >= 2 && value.trim().length <= 64,
    },
    errorMessages: {
      name: 'Venue name should match (case sensitive)',
    },
  });

  useEffect(() => {
    if (!remove) return;
    let cancel = false;

    async function removeVenue() {
      const params = { query: { name: form.values.name, id: venue.id, orgId: venue.orgId } };

      try {
        await app.service('venues').remove(venue.id, params);
        if (!cancel) {
          navigate(`/orgs/${orgId}/venues`);
          setPending(false);
          setRemove(false);
        }
      } catch (error) {
        setError(error.message);
        setPending(false);
        setRemove(false);
        console.log(error);
      }
    }

    removeVenue();

    return () => (cancel = true);
    //eslint-disable-next-line
  }, [remove]);

  const handleSubmit = () => {
    setPending(true);
    setRemove(true);
  };

  return (
    <Box>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <LoadingOverlay visible={pending} />
        <Text weight={600} color='red'>
          Delete Venue
        </Text>
        <Text size='sm' mt='xs'>
          Deleting a venue will permenantly delete all positions, fixtures and associated logs which
          are currently assigned to the venue.
        </Text>
        <Text size='sm' mt='xs'>
          To permenantly delete this venue and all associated data, type the venue name (case
          sensitive) in the box below.
        </Text>

        <TextInput
          placeholder='Venue name'
          label='Confirm Venue To Delete'
          data-autocomplete='off'
          required
          data-autofocus
          mt='md'
          {...form.getInputProps('name')}
        />
        {error && (
          <Text color='red' size='sm' mt='sm'>
            {error}
          </Text>
        )}
        <Group position='right' mt='sm'>
          <Button type='submit' color='red'>
            Permenantly Delete
          </Button>
          <Button variant='outline' type='button' onClick={handleClose}>
            Cancel
          </Button>
        </Group>
      </form>
    </Box>
  );
}

export default DeleteVenueForm;
