import { Box, Loader, Tabs } from '@mantine/core';
import React from 'react';
import { assessOrgAccessLevel, ORG_ADMIN, ORG_OWNER } from '../../common/commonResolvers';
import DeleteOrgForm from './DeleteOrgForm';
import EditOrgForm from './EditOrgForm';
import InviteUserForm from './InviteUserForm';
import OrgUserEditor from './OrgUserEditor';
import LeaveOrgForm from './LeaveOrgForm';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { getter } from '../../app/feathersClient';

function OrgConfigCard() {
  const { orgId } = useParams();
  const { data: org } = useSWR(`orgs/${orgId}`, getter);

  if (!org) {
    return <Loader />;
  }

  const orgAccessLevel = assessOrgAccessLevel(org);

  return (
    <Box>
      <Tabs defaultValue={'members'}>
        <Tabs.List>
          <Tabs.Tab value='members'>Members</Tabs.Tab>
          {orgAccessLevel >= ORG_ADMIN && <Tabs.Tab value='invite'>Invite User</Tabs.Tab>}
          {orgAccessLevel >= ORG_ADMIN && <Tabs.Tab value='details'>Details</Tabs.Tab>}
          {orgAccessLevel >= ORG_OWNER && <Tabs.Tab value='danger'>Danger Zone</Tabs.Tab>}
          {orgAccessLevel < ORG_OWNER && <Tabs.Tab value='leave'>Leave Org</Tabs.Tab>}
        </Tabs.List>

        <Tabs.Panel value='members' mt='md'>
          <OrgUserEditor org={org} />
        </Tabs.Panel>
        {orgAccessLevel >= ORG_ADMIN && (
          <Tabs.Panel value='invite' mt='md'>
            <InviteUserForm org={org} />
          </Tabs.Panel>
        )}
        {orgAccessLevel >= ORG_ADMIN && (
          <Tabs.Panel value='details' mt='md'>
            <EditOrgForm org={org} />
          </Tabs.Panel>
        )}
        {orgAccessLevel >= ORG_OWNER && (
          <Tabs.Panel value='danger' mt='md'>
            <DeleteOrgForm org={org} />
          </Tabs.Panel>
        )}
        <Tabs.Panel value='leave' mt='md'>
          <LeaveOrgForm org={org} />
        </Tabs.Panel>
      </Tabs>
    </Box>
  );
}

export default OrgConfigCard;
