export const logTypes = [
  {
    id: 1,
    name: 'Info',
    color: 'blue',
    icon: 'circle-info',
  },
  {
    id: 2,
    name: 'Service',
    color: 'teal',
    icon: 'cog',
  },
  {
    id: 3,
    name: 'Problem',
    color: 'orange',
    icon: 'lightning',
  },
  {
    id: 4,
    name: 'Move',
    color: 'violet',
    icon: 'arrows',
  },
];
