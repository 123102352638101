import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Group, Stack, Text, Title } from '@mantine/core';
import { Fragment } from 'react';
import ServiceTagForm from '../../components/ServiceTags/ServiceTagForm';
import GenericDeleteConfirmation from '../../components/ObjectAction/GenericDeleteConfirmation';
import ActionButtons from '../../components/ActionButtons/ActionButtons';
import { getter } from '../../app/feathersClient';
import { width } from '@fortawesome/free-regular-svg-icons/faAddressBook';

const serviceTagsModel = {
  title: 'Service Tags',
  readFrom: 'swr',
  service: 'service-tags',
  injectGetQuery: ['orgId'],
  defaultSort: 'name',
  // sortDirection: 'desc',
  nestedRows: false,
  key: 'id',
  colDefs: [
    {
      key: 'name',
      label: 'Tag',
      getter: (data) => data.name,
      width: '15%',
      flex: '1 1',
      hideBreak: 'sm',
    },
    {
      key: 'shortname',
      label: 'Short Tag',
      getter: (data) => data.shortname,
      width: '15%',
      flex: '1 1',
      hideBreak: 'sm',
    },
    {
      key: 'description',
      label: 'Description',
      getter: (data) => data.description,
      width: '70%',
      flex: '2 1',
      hideBreak: 'sm',
    },
    {
      key: 'update',
      label: 'Actions',
      getter: (data) => data,
      width: '56px',
      minWidth: '56px',
      hideBreak: 'sm',
      cellRenderer: (props) => <ActionButtons {...props} />,
    },
    {
      key: 'mobile',
      label: 'Tags',
      showBreak: 'sm',
      getter: (data) => data,
      cellRenderer: (props) => (
        <Stack style={{ width: '100%' }} spacing={0}>
          <Group position='apart'>
            <Text size='sm'>{`${props.data.name} (${props.data.shortname})`}</Text>
            <ActionButtons {...props} />
          </Group>
          <Text size='xs' color='dimmed'>
            {props.data.description}
          </Text>
        </Stack>
      ),
      width: '100%',
      minWidth: '100%',
    },
  ],
  toolbar: ['create'],
  actionButtons: ['patch', 'remove'],
  actions: {
    create: {
      type: 'modal',
      icon: <FontAwesomeIcon icon={faPlus} />,
      label: 'New Tag',
      title: 'New Tag',
      content: <ServiceTagForm action='create' />,
    },
    patch: {
      type: 'modal',
      icon: <FontAwesomeIcon icon={faEdit} />,
      label: 'Edit Fixture',
      title: 'Edit Fixture',
      content: <ServiceTagForm action='patch' />,
    },
    remove: {
      type: 'modal',
      icon: <FontAwesomeIcon icon={faTrash} />,
      label: 'Delete Fixture',
      title: 'Delete Fixture',
      content: (
        <GenericDeleteConfirmation warning='This will remove all instances of this tag from all fixture logs' />
      ),
    },
  },
  sidebar: (
    <Fragment>
      <Title order={5}>Your Service Tags</Title>
      <Text size='sm' color='dimmed'>
        Service tags belong to the organization and can be applied to logs to track specific service
        actions performed on fixtures. Tags can be used to view your fixtures in order of when they
        were last serviced.
      </Text>
    </Fragment>
  ),
};
export default serviceTagsModel;
