import { Group, Burger } from '@mantine/core';
import React from 'react';
import QuickSearchButton from '../QuickSearch/QuickSearchButton';
import HeaderLogo from './HeaderLogo';

function HeaderContent({ setOpened, opened }) {
  return (
    // Header Group
    <Group spacing={0} position='apart' style={{ flex: 1, marginRight: 0, marginLeft: 16 }}>
      <Group spacing='xs'>
        <Burger color='#2a6cab' opened={opened} onClick={() => setOpened(!opened)} title='Fixtr' />
        <HeaderLogo />
      </Group>
      <QuickSearchButton />
    </Group>
  );
}

export default HeaderContent;
