import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon, Group } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

function VenueSettingsButton({ venue }) {
  return (
    <Group position='center' noWrap style={{ minWidth: 20, width: '100%' }} spacing='xs'>
      <ActionIcon
        color='gray'
        size='md'
        component={Link}
        to={`/orgs/${venue.orgId}/venues/${venue.id}/config`}
      >
        <FontAwesomeIcon icon={faCog}></FontAwesomeIcon>
      </ActionIcon>
    </Group>
  );
}

export default VenueSettingsButton;
