import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { selectAuth } from '../../features/auth/authSlice';

function RequireUnauth({ children }) {
  const isAuthenticated = useSelector(selectAuth);
  const location = useLocation();

  if (isAuthenticated) return <Navigate to='/' state={{ from: location }} replace />;

  return children;
}

export default RequireUnauth;
