import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Modal } from '@mantine/core';
import app from '../../app/feathersClient';
import useMatchMutate from '../../hooks/useMatchMutate';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from '@mantine/hooks';

// Handles an service call destined for Redux store

function ObjectActionHandler({
  children = [],
  action = null,
  modal = true,
  targetData,
  secondaryTarget,
  onClose,
  model,
  data,
  reset,
  ...rest
}) {
  const { orgId } = useParams();
  const matchMutate = useMatchMutate();
  const target = targetData?.[model?.key] || null;
  const params = model?.actions[action];

  const isMobile = useMediaQuery('(max-width: 600px)');

  const [applyAction, setApplyAction] = useState(false);
  const [submitPayload, setSubmitPayload] = useState({});
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState('');

  const submitHandler = useCallback((payload) => {
    setApplyAction(true);
    setSuccess(null);
    setSubmitPayload({ ...payload, orgId });
    //eslint-disable-next-line
  }, []);

  const closeHandler = useCallback(() => {
    setApplyAction(false);
    setSubmitPayload(false);
    setSuccess(null);
    setError('');
    onClose();
  }, [onClose]);

  const abortHandler = useCallback(
    (error = '') => {
      setApplyAction(false);
      setSubmitPayload(false);
      setError(error);
      onClose();
    },
    [onClose]
  );

  useEffect(() => {
    if (!applyAction) return;

    let cancel = false;

    async function handleServiceCall() {
      try {
        const callAction = params.method || action;
        const actionPayload =
          callAction === 'create' ? [submitPayload] : [target || undefined, submitPayload];

        await app
          .service(params?.service || model.service)
          [params.method || action](...actionPayload, {});

        matchMutate(new RegExp(`service:"${model.service}"`));

        if (!cancel) {
          setApplyAction(false);
          setSubmitPayload({});
          setSuccess(true);
        }
      } catch (error) {
        console.log(error);
        setError(error.message);
        setSubmitPayload({});
        setApplyAction(false);
        setSuccess(false);
      }
    }
    handleServiceCall();

    return () => (cancel = true);

    // eslint-disable-next-line
  }, [applyAction]);

  if (!action) return null;

  const newProps = {
    target: action === 'create' ? null : target,
    targetData,
    secondaryTarget,
    action,
    onClose: closeHandler,
    onSubmit: submitHandler,
    onAbort: abortHandler,
    pending: applyAction,
    success,
    error,
    ...rest,
  };

  return (
    <Fragment>
      {modal ? (
        <Modal
          opened={action}
          title={params?.title}
          onClose={closeHandler}
          size={params?.modalSize || 'md'}
          fullScreen={isMobile}
        >
          {params?.content && React.cloneElement(params?.content, newProps)}
          {params?.contextContent &&
            React.cloneElement(params?.contextContent(targetData), newProps)}
        </Modal>
      ) : (
        params?.content && React.cloneElement(params?.content, newProps)
      )}
    </Fragment>
  );
}

export default ObjectActionHandler;
