import { Avatar, Group, LoadingOverlay, Text, UnstyledButton } from '@mantine/core';
import React from 'react';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthUser } from '../../features/auth/authSlice';

const UserButton = forwardRef(({ collapse = false, ...others }, ref) => {
  const user = useSelector(selectAuthUser);

  if (!user) return <LoadingOverlay display={true} />;
  return (
    <UnstyledButton
      ref={ref}
      sx={(theme) => ({
        display: 'flex',
        width: '100%',
        padding: theme.spacing.xs,
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
        justifyContent: collapse ? 'center' : 'flex-start',

        '&:hover': {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
        },
      })}
      {...others}
    >
      <Group spacing={'xs'} position='center'>
        <Avatar radius={'100%'} color={'blue'} size={collapse ? 'sm' : 'md'}>
          {typeof user.username === 'string' ? user.username.slice(0, 1).toUpperCase() : '?'}
        </Avatar>
        {!collapse && (
          <div style={{ flex: 1 }}>
            <Text size='sm' weight={500}>
              {user?.username}
            </Text>

            <Text color='dimmed' size='xs'>
              {user?.email}
            </Text>
          </div>
        )}
      </Group>
    </UnstyledButton>
  );
});

export default UserButton;
