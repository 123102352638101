import { Box, Switch } from '@mantine/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { flexCenterCenter } from '../../common/commonStyles';
import MoonIcon from '../../common/icons/MoonIcon';
import SunIcon from '../../common/icons/SunIcon';
import { selectColourScheme, setColourScheme } from '../../features/ui/uiSlice';

// Todo: Move localStorage setting into Saga
function ThemeToggle() {
  const colourScheme = useSelector(selectColourScheme);
  const dispatch = useDispatch();

  const handleChange = () => {
    const newColourScheme = colourScheme === 'light' ? 'dark' : 'light';
    dispatch(setColourScheme({ colourScheme: newColourScheme }));
    window.localStorage.setItem('color-scheme', newColourScheme);
  };

  return (
    <Box style={{ ...flexCenterCenter }}>
      <SunIcon filled={colourScheme !== 'light'} />
      <Switch
        color='gray'
        checked={colourScheme !== 'light'}
        onChange={handleChange}
        styles={{ root: { paddingLeft: 4, paddingRight: 4 }, input: { cursor: 'pointer' } }}
      />
      <MoonIcon filled={colourScheme !== 'light'} />
    </Box>
  );
}

export default ThemeToggle;
