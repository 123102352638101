import { getOrgRoleText } from '../../common/commonResolvers';
import OrgActionButtons from '../../components/orgs/OrgActionButtons';
import OrgLink from '../../components/orgs/OrgLink';
import NewOrgForm from '../../components/orgs/NewOrgForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Fragment } from 'react';
import { Text, Title } from '@mantine/core';

const orgsModel = {
  title: 'Organizations',
  readFrom: 'swr',
  service: 'orgs',
  defaultSort: 'name',
  key: 'id',
  colDefs: [
    {
      key: 'name',
      label: 'Name',
      getter: (data) => data.name,
      width: '100%',
      flex: '2 1',
      cellRenderer: ({ data, cellData }) => <OrgLink data={data} cellData={cellData} />,
    },
    {
      key: 'role',
      label: 'Role',
      getter: (data) => getOrgRoleText(data),
      width: '80px',
      hideBreak: 'xs',
    },
    {
      key: 'settings',
      label: 'Settings',
      cellRenderer: ({ data, swrKey }) => <OrgActionButtons org={data} swrKey={swrKey} />,
      width: '56px',
    },
  ],
  toolbar: ['create'],
  actions: {
    create: {
      type: 'modal',
      icon: <FontAwesomeIcon icon={faPlus} />,
      label: 'New Org',
      title: 'New Organization',
      content: <NewOrgForm />,
    },
  },
  sidebar: (
    <Fragment>
      <Title order={5}>Your Organizations</Title>
      <Text size='sm' color={'dimmed'}>
        These are the organizations you currently have access to.
      </Text>
      <Text size='sm' weight={'bold'} mt='sm'>
        Roles
      </Text>
      <Text size='sm' color={'dimmed'} mt='xs'>
        <strong>Owner:</strong> Can manage at the organization level.
      </Text>
      <Text size='sm' color={'dimmed'} mt='xs'>
        <strong>Admin:</strong> Admins can manage the venues within the organization.
      </Text>
      <Text size='sm' color={'dimmed'} mt='xs'>
        <strong>Users:</strong> Can view, collaborate on or administrate any venues to which
        permission has been granted.
      </Text>
    </Fragment>
  ),
};

export default orgsModel;
