import React, { useEffect, useState } from 'react';
import { Group, Button } from '@mantine/core';

import app from '../../app/feathersClient';
import { selectAuthUserId } from '../../features/auth/authSlice';
import { useSelector } from 'react-redux';
import useMatchMutate from '../../hooks/useMatchMutate';
import { useParams } from 'react-router-dom';

function OrgInviteButtons({ org }) {
  const matchMutate = useMatchMutate();
  const [respondToInvite, setRespondToInvite] = useState(false);
  const userId = useSelector(selectAuthUserId);
  const { orgId: activeOrgId } = useParams();

  useEffect(() => {
    if (!respondToInvite) return;
    let cancel = false;
    const id = `${userId},${org.id}`;

    async function acceptInvite() {
      const payload = { isInvite: false };
      await app.service('org-access').patch(id, payload);
      matchMutate(/service:"orgs"/);
      if (!cancel) setRespondToInvite(false);
    }
    async function rejectInvite() {
      await app.service('org-access').remove(id);
      matchMutate(/service:"orgs"/);
      if (!cancel) setRespondToInvite(false);
    }

    if (respondToInvite === 'ACCEPT') {
      acceptInvite();
    } else {
      rejectInvite();
    }

    return () => (cancel = true);
    //eslint-disable-next-line
  }, [respondToInvite]);

  const handleInvite = (response) => {
    setRespondToInvite(response);
  };

  return (
    <Group style={{ width: 56 }} position='right' spacing='xs'>
      <Button size='xs' disabled={activeOrgId === org.id} onClick={() => handleInvite('ACCEPT')}>
        Accept
      </Button>
      <Button
        size='xs'
        variant='outline'
        disabled={activeOrgId === org.id}
        onClick={() => handleInvite('REJECT')}
      >
        Decline
      </Button>
    </Group>
  );
}

export default OrgInviteButtons;
