import React from 'react';
import { Link } from 'react-router-dom';

import { Anchor, Text } from '@mantine/core';

function VenueLink({ data, cellData }) {
  return (
    <Anchor component={Link} to={`/orgs/${data.orgId}/venues/${data.id}/fixtures`}>
      <Text lineClamp={1}>{cellData}</Text>
    </Anchor>
  );
}

export default VenueLink;
