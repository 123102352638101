import React, { useState } from 'react';
import useSWR from 'swr';
import { getter } from '../../app/feathersClient';
import {
  Card,
  Avatar,
  Text,
  useMantineTheme,
  Group,
  Menu,
  ActionIcon,
  Image,
  Stack,
} from '@mantine/core';
import { useSelector } from 'react-redux';
import { selectAuthUserId } from '../../features/auth/authSlice';
import {
  assessOrgAccessLevel,
  assessVenueAccessLevel,
  ORG_ADMIN,
  VENUE_ADMIN,
} from '../../common/commonResolvers';

import CommentUpdater from './CommentUpdater';
import CommentDeleter from './CommentDeleter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import ImageThumbnail from '../Image/ImageThumbnail';

function Comment({ comment, log }) {
  const [mode, setMode] = useState(null);
  const theme = useMantineTheme();
  const { data: commentFixture } = useSWR(
    log.fixtureId ? `fixtures/${log.fixtureId}` : false,
    getter
  );
  const { data: venue } = useSWR(
    commentFixture ? `venues/${commentFixture.venueId}` : false,
    getter
  );
  const { data: org } = useSWR(commentFixture ? `orgs/${commentFixture.orgId}` : false, getter);

  const currentUserId = useSelector(selectAuthUserId);

  const commentDate = new Date(comment.updatedAt || comment.createdAt);
  const { data: userData } = useSWR(`userprofiles/${comment.userId}`, getter, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });

  // Don't allow ORG_VENUE_ADMIN in this case
  const venueAccess = assessVenueAccessLevel(venue);
  const orgAccess = assessOrgAccessLevel(org);
  const shouldDisplayMenu =
    venueAccess >= VENUE_ADMIN || orgAccess >= ORG_ADMIN || currentUserId === comment.userId;
  const shouldAllowEdit = currentUserId === comment.userId;

  return (
    <Card
      mt='xs'
      mb='xs'
      padding='xs'
      shadow='md'
      style={{
        maxWidth: mode === 'edit' ? '100%' : '80%',
        width: mode === 'edit' ? '100%' : 'fit-content',
        minWidth: '30%',
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        flexWrap: 'nowrap',
        border: `1px solid ${
          theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[4]
        }`,
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : undefined,
        alignSelf: currentUserId === comment.userId ? 'flex-end' : 'flex-start',
      }}
    >
      <Group>
        <div style={{ width: 56, minWidth: 56, flex: 0 }}>
          <Avatar color='yellow' radius='xl'>
            {userData?.username?.slice(0, 1).toUpperCase() || ''}
          </Avatar>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: mode === 'edit' ? '100%' : undefined,
          }}
        >
          <Group position='apart'>
            <Text size='xs' color='dimmed' weight='600' style={{ whiteSpace: 'nowrap' }}>
              {userData?.username || ''} - [{commentDate.toLocaleString()}]
            </Text>
            {shouldDisplayMenu && (
              <Menu size={'xs'} shadow='sm' withinPortal>
                <Menu.Target>
                  <ActionIcon size={'lg'}>
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </ActionIcon>
                </Menu.Target>
                <Menu.Dropdown>
                  {shouldAllowEdit && <Menu.Item onClick={() => setMode('edit')}>Edit</Menu.Item>}
                  <Menu.Item onClick={() => setMode('delete')}>Delete</Menu.Item>
                </Menu.Dropdown>
              </Menu>
            )}
          </Group>

          <Text size='sm' style={{ marginTop: 4 }}>
            {mode === null && comment.text}

            {mode === 'edit' && (
              <CommentUpdater
                targetId={comment.id}
                mode='patch'
                suppressLabel
                initialValue={comment.text}
                onCancel={() => setMode(null)}
                logId={comment.logId}
              />
            )}
            {mode === 'delete' && (
              <CommentDeleter targetId={comment.id} onClose={() => setMode(null)} />
            )}
          </Text>
        </div>
      </Group>
      <Stack>
        {comment.images.map((image, index) => {
          return <ImageThumbnail key={`image-${index}`} image={image} />;
        })}
      </Stack>
    </Card>
  );
}

export default Comment;
