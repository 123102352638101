import { Text, Title } from '@mantine/core';
import { Fragment } from 'react';
import FixtureLink from '../../components/fixtures/FixtureLink';

const issueTypesModel = {
  title: 'Issues',
  readFrom: 'swr',
  service: 'issue-types',
  injectGetQuery: ['orgId', 'venueId'],
  defaultSort: 'number',
  // sortDirection: 'desc',
  nestedRows: true,
  hasChildren: (data) => data.openIssues > 0 || data.closedIssues > 0,
  getChildren: (data) => {
    return {
      inject: ['venueId'],
      service: 'fixtures-by-issue-type',
      query: {
        issueTypeId: data.id,
      },
    };
  },
  nestedKey: 'issueId',
  nestedColDefs: [
    {
      key: 'fixture',
      label: 'Fixture',
      getter: (data) => data.id,
      cellRenderer: (data) => {
        return <FixtureLink data={data.data} size='sm' withStatus />;
      },
      width: '35%',
      flex: '2 1',
      hideBreak: 'sm',
    },
    {
      key: 'closed',
      label: 'Open/Closed',
      getter: (data) => data.closed,
      cellRenderer: (data) => {
        return <Text size='sm'>{data.cellData ? 'Closed' : 'Open'}</Text>;
      },
      width: '96px',
      flex: '1 1',
      align: 'center',
    },
  ],
  key: 'id',
  colDefs: [
    {
      key: 'number',
      label: '#',
      getter: (data) => `[${data.number}]`,
      width: '56px',
      flex: '1 1',
      align: 'center',
    },
    {
      key: 'name',
      label: 'Title',
      getter: (data) => data.name,
      width: '35%',
      flex: '2 1',
      hideBreak: 'sm',
    },
    // Number opened and number closed
    {
      key: 'closed',
      label: 'Open/Closed',
      getter: (data) => `${data.openIssues} / ${data.closedIssues}`,
      width: '96px',
      flex: '1 1',
      align: 'center',
    },
  ],
  toolbar: [],
  actionButtons: [],
  sidebar: (
    <Fragment>
      <Title order={5}>Your Issue Tickets</Title>
      <Text size='sm' color='dimmed'>
        Issues belong to the organization and are associated with one or more fixture types.
      </Text>
    </Fragment>
  ),
};
export default issueTypesModel;
