import { ActionIcon, Autocomplete, Group, Loader, Text } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher, getter } from '../../app/feathersClient';
import { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

const AutoCompleteItem = forwardRef(({ id, value, ...others }, ref) => {
  return (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Text>{value}</Text>
      </Group>
    </div>
  );
});

function FullTextSearch({
  model,
  onChange,
  value,
  placeholder = '',
  additionalQuery = {},
  getValueMap = (item) => ({ value: item.name, id: item.id, key: item.id, ...item }),
  customItem = null,
  passRef,
  exact = false,
  getService,
  initialGetFormatter = (data) => data,
  triggerReset = false,
  setTriggerReset = () => {},
  ...rest
}) {
  const [inputValue, setInputValue] = useState(value || '');
  const [shouldFetch, setShouldFetch] = useState(value ? true : false);
  const { data: itemData } = useSWR(shouldFetch ? `${getService}/${value}` : null, getter);

  // If a value is provided, fetch the value and insert it
  useEffect(() => {
    if (shouldFetch && itemData) {
      setShouldFetch(false);
      setInputValue(initialGetFormatter(itemData) || itemData.name);
    }
    // Trigger external reset
    if (triggerReset && !shouldFetch) {
      setShouldFetch(true);
      setTriggerReset(false);
    }

    //eslint-disable-next-line
  }, [itemData, shouldFetch, triggerReset]);

  const urlParams = useParams();

  let injectQuery = {};
  if (model.injectGetQuery) {
    model.injectGetQuery.forEach((item) => {
      if (urlParams[item]) {
        injectQuery[item] = urlParams[item];
      }
    });
  }

  const searchParams = inputValue
    ? {
        query: encodeURIComponent(inputValue),
        exact: exact ? true : false,
        ...additionalQuery,
      }
    : { ...additionalQuery };

  const handleReset = () => {
    setInputValue(''); // Reset input
    onChange(''); // Send null to parent form
  };

  const { data: response } = useSWR(
    inputValue
      ? { service: model.service, query: { ...searchParams, ...injectQuery, $limit: 10 } }
      : false,
    fetcher
  );

  const data = response?.data?.map((item) => getValueMap(item)) || [];

  return (
    <Autocomplete
      ref={passRef}
      placeholder={placeholder}
      data={data || []}
      filter={(value, item) => item?.value?.toLowerCase().includes(value?.toLowerCase().trim())}
      itemComponent={customItem || AutoCompleteItem}
      onItemSubmit={(value) => onChange(value?.id)}
      value={inputValue}
      onChange={setInputValue}
      rightSectionWidth={70}
      rightSection={
        <Group noWrap style={{ width: 70 }} spacing={0} position={'right'} pr='xs'>
          {shouldFetch && !data && <Loader size='sm' />}
          <ActionIcon varient='transparent' onClick={handleReset}>
            <FontAwesomeIcon icon={faClose} />
          </ActionIcon>
        </Group>
      }
      {...rest}
    />
  );
}

export default FullTextSearch;
