import { showNotification } from '@mantine/notifications';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuth } from '../../features/auth/authSlice';

const EXPIRY_WARNING_INTERVAL = 30;
const EXPIRY_LOGOUT_HEADSTART = 2;

function AuthMonitor() {
  const { accessToken } = useSelector(selectAuth);
  const dispatch = useDispatch();

  useEffect(() => {
    let expiryWarningIssued = false;
    function checkJWTValidity() {
      if (!accessToken) return;
      const payload = JSON.parse(window.atob(accessToken.split('.')[1]));
      const expiry = payload.exp;
      const now = Math.floor(Date.now() / 1000);
      if (
        now > expiry - (EXPIRY_WARNING_INTERVAL + EXPIRY_LOGOUT_HEADSTART) &&
        !expiryWarningIssued
      ) {
        expiryWarningIssued = true;
        showNotification({
          color: 'red',
          disallowClose: true,
          autoClose: (EXPIRY_LOGOUT_HEADSTART + EXPIRY_WARNING_INTERVAL) * 1000,
          title: 'End of Session Warning',
          message: 'Your session will end in 5 seconds, you will be logged out.',
        });
      }
      if (now > expiry - EXPIRY_LOGOUT_HEADSTART) {
        console.log('JWT expired');
        dispatch({ type: 'auth/logout' });
      }
    }

    const monitor = setInterval(checkJWTValidity, 1000);

    return () => {
      clearInterval(monitor);
    };
    // eslint-disable-next-line
  }, [accessToken]);

  return null;
}

export default AuthMonitor;
