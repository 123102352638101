import { faRemove } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  ActionIcon,
  Anchor,
  Box,
  Button,
  Container,
  Grid,
  Group,
  SegmentedControl,
  Select,
  Title,
} from '@mantine/core';
import React, { useState } from 'react';
import { useEffect } from 'react';
import useSWR from 'swr';
import { fetcher } from '../../app/feathersClient';
import DataAutocomplete from '../DataAutocomplete/DataAutocomplete';
import ObjectList from '../ObjectList/ObjectList';
import { useSearchParams } from 'react-router-dom';

function ViewCriteria({ criteriaRow, setCriteriaRow, removeCriteria, length, propertyCount: pc }) {
  const { property, propertyId } = criteriaRow;
  const [shouldReset, setShouldReset] = useState(false);

  const injectIds = ['orgId'];
  if (property === 'positions') injectIds.push('venueId');

  const shouldDisable = (p) => {
    // Return false if no properties have multiple entries
    if (Object.values(pc).filter((v) => v > 1).length === 0) return false;
    // Return false if this property has NO entries
    if (pc[p] === 0) return false;
    // Return false if this property has multiple entries
    if (pc[p] > 1) return false;
    // Otherwise return true
    return true;
  };

  return (
    <Group>
      <SegmentedControl
        value={property}
        onChange={(v) => {
          setCriteriaRow({ ...criteriaRow, property: v, propertyId: '' });
          setShouldReset(true);
        }}
        data={[
          { label: 'Type', value: 'fixture-types', disabled: shouldDisable('fixture-types') },
          { label: 'Position', value: 'positions', disabled: shouldDisable('positions') },
          { label: 'Status', value: 'status', disabled: shouldDisable('status') },
        ]}
      />
      <DataAutocomplete
        disabled={property === ''}
        model={
          property
            ? {
                service: property,
                injectGetQuery: injectIds,
              }
            : null
        }
        value={propertyId}
        onChange={(v) => setCriteriaRow({ ...criteriaRow, propertyId: v })}
        pinnedOption={property === 'positions' ? { id: 0, value: '(unassigned)', data: {} } : null}
        mt='xs'
        mb='xs'
        matchField={'name'}
        getValueMap={(item) => ({
          id: item.id,
          value: item.name,
          data: item,
        })}
        reset={shouldReset}
        setReset={setShouldReset}
      />
      {length > 1 && (
        <ActionIcon size='sm' onClick={removeCriteria}>
          <FontAwesomeIcon icon={faRemove} />
        </ActionIcon>
      )}
    </Group>
  );
}

const propMap = {
  'fixture-types': 'typeId',
  positions: 'positionId',
  status: 'status',
};

function ViewsPage({ model }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const criteria = searchParams.get('criteria')
    ? JSON.parse(decodeURI(searchParams.get('criteria')))
    : [];
  const setCriteria = (data) => {
    const currentParams = {};
    searchParams.forEach((value, key) => {
      currentParams[key] = value;
    });
    setSearchParams({
      ...currentParams,
      criteria: encodeURI(JSON.stringify(data)),
    });
  };

  // If no searchParam for criteria is set, initialize it
  // with a single empty criteria row with a unique key
  useEffect(() => {
    if (!searchParams.get('criteria')) {
      setCriteria([{ property: '', propertyId: '', key: new Date().getTime().toString() }]);
    }
    //eslint-disable-next-line
  }, []);

  // Generate a timestamp as a string
  // const [criteria, setCriteria] = useState(
  //   searchParams.get('criteria')
  //     ? JSON.parse(searchParams.get('criteria'))
  //     : [{ property: '', propertyId: '', key: new Date().getTime().toString() }]
  // );

  const propertyCount = { 'fixture-types': 0, positions: 0, status: 0 };

  criteria.forEach((c) => {
    if (c.property) propertyCount[c.property]++;
  });

  const query = {};

  criteria.forEach((c) => {
    const key = propMap[c.property];
    let val = c.propertyId;
    let hasVal = false;

    // Correct unassigned positions and unknown status values
    if (key === 'status' && val === 0) {
      val = 0;
      hasVal = true;
    }
    if (key === 'positionId' && val === 0) {
      val = 'null';
      hasVal = true;
    }
    if (val) hasVal = true;

    if (propertyCount[c.property] <= 1 && hasVal) {
      query[key] = val;
    }

    if (propertyCount[c.property] >= 2 && hasVal) {
      if (!query.hasOwnProperty('$or')) {
        query['$or'] = [];
      }
      query['$or'].push({ [key]: val });
    }
  });

  const addCriteria = () => {
    setCriteria([
      ...criteria,
      { property: '', propertyId: '', key: new Date().getTime().toString() },
    ]);
  };

  // Function that updates the given index in the properties array
  const updateCriteria = (data, index) => {
    const newCriteria = [...criteria];
    newCriteria[index] = data;
    setCriteria(newCriteria);
  };

  // Function that removes the given index in the properties array
  const removeCriteria = (index) => {
    const newCriteria = [...criteria];
    newCriteria.splice(index, 1);
    setCriteria(newCriteria);
  };

  if (!model) return null;
  return (
    <Box>
      <Box
        p='lg'
        sx={(theme) => ({
          borderBottom: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.gray[9] : theme.colors.gray[1]
          }`,
        })}
      >
        <Title order={2}>{model.title} Views</Title>
      </Box>

      <Container mt='xl' mb='xl'>
        <Accordion defaultValue={'criteria'}>
          <Accordion.Item value='criteria'>
            <Accordion.Control>Criteria</Accordion.Control>
            <Accordion.Panel>
              {criteria.map((criteriaRow, i) => {
                return (
                  <ViewCriteria
                    key={criteriaRow.key}
                    criteriaRow={criteriaRow}
                    setCriteriaRow={(data) => updateCriteria(data, i)}
                    removeCriteria={() => removeCriteria(i)}
                    length={criteria.length}
                    propertyCount={propertyCount}
                  />
                );
              })}
              <Anchor size='sm' onClick={addCriteria}>
                + Add criteria
              </Anchor>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </Container>
      <Container>
        <ObjectList model={model} query={query} />
      </Container>
    </Box>
  );
}

export default ViewsPage;
