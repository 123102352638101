import React, { useEffect } from 'react';
import { fetcher } from '../../app/feathersClient';
import useSWR from 'swr';

import { getPaginationValues } from '../../common/commonResolvers';

import { Box, Group, NumberInput, Pagination } from '@mantine/core';

import GroupRow from '../RepeatingGroup/GroupRow';
import HeaderRow from '../RepeatingGroup/HeaderRow';
import { useParams, useSearchParams } from 'react-router-dom';
import { themeRepeatingGroup } from '../../common/commonStyles';
import { useState } from 'react';

function ObjectList({ model, query = {}, pageSize = 10, resetOnQueryChange = false }) {
  const [readyToFetch, setReadyToFetch] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page');
  const urlParams = useParams();

  useEffect(() => {
    if (model.service !== readyToFetch) {
      setReadyToFetch(model.service);
      return;
    }
    //eslint-disable-next-line
  }, [model]);

  let injectQuery = {};
  if (model.injectGetQuery) {
    model.injectGetQuery.forEach((item) => {
      if (urlParams[item]) {
        injectQuery[item] = urlParams[item];
      }
    });
  }

  const swrKey = {
    service: model.service,
    query: {
      ...query,
      ...injectQuery,
      $sort: { [model.defaultSort]: model.sortDirection === 'desc' ? -1 : 1 },
      $limit: pageSize,
      $skip: Math.max((page - 1) * pageSize, 0),
    },
  };

  useEffect(() => {
    if (page !== 1 && resetOnQueryChange) {
      const currentParams = {};
      searchParams.forEach((value, key) => {
        currentParams[key] = value;
      });
      setSearchParams({ ...currentParams, page: 1 });
    }
    //eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    if (!page) {
      const currentParams = {};
      searchParams.forEach((value, key) => {
        currentParams[key] = value;
      });
      setSearchParams({ ...currentParams, page: 1 });
    }
    //eslint-disable-next-line
  }, [page]);

  const { data: result } = useSWR(readyToFetch === model.service ? swrKey : null, fetcher);
  if (model.logging) console.log(result);

  const pg = getPaginationValues(result?.total, pageSize, page);
  const data = result?.data || [];

  return (
    <Box>
      <Box
        sx={(theme) => ({
          ...themeRepeatingGroup(theme),
        })}
      >
        <HeaderRow model={model} />
        {data?.map((row) => (
          <GroupRow model={model} row={row} key={row[model.key]} />
        ))}
      </Box>
      <Group position='left'>
        <Pagination
          mt='xl'
          mb='xl'
          initialPage={1}
          withEdges={pg.totalPages > 10}
          siblings={pg.totalPages > 10 ? 2 : 1}
          page={parseInt(page)}
          total={pg.totalPages}
          onChange={(page) => {
            const currentParams = {};
            searchParams.forEach((value, key) => {
              currentParams[key] = value;
            });
            setSearchParams({ ...currentParams, page });
          }}
        />
        {/* {pg.totalPages > 10 && (
          <NumberInput
            width={'xs'}
            min={1}
            max={pg.totalPages}
            value={parseInt(page)}
            onChange={(value) => {
              const currentParams = {};
              searchParams.forEach((value, key) => {
                currentParams[key] = value;
              });
              setSearchParams({ ...currentParams, page: value });
            }}
          />
        )} */}
      </Group>
    </Box>
  );
}

export default ObjectList;
