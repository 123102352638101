import React, { useState } from 'react';
import { Text, TextInput, Space, Group, Button, Box, LoadingOverlay } from '@mantine/core';
import { useForm } from '@mantine/form';
import app from '../../app/feathersClient';
import useMatchMutate from '../../hooks/useMatchMutate';

function NewOrgForm({ onClose = () => console.log('close') }) {
  const matchMutate = useMatchMutate();
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);
  const form = useForm({
    initialValues: {
      name: '',
      id: '',
    },
    validationRules: {
      name: (value) => value.trim().length >= 2 && value.trim().length <= 64,
      id: (value) => /^[a-z0-9_/-]{1,16}$/.test(value), // Must be a-z0-9 and less than 16 chars
    },
    errorMessages: {
      name: 'Organization name should be between 2 and 64 characters',
      id: 'The short name must be less than 16 characters, unique, and can only contain lowercase letters, numbers, dashes and/or underscores',
    },
  });

  const handleSubmit = async () => {
    setPending(true);
    try {
      const result = await app.service('orgs').create(form.values, {});
      matchMutate(/service:"orgs"/);
      onClose();
    } catch (error) {
      setError(error.message);
      setPending(false);
    }
  };

  return (
    <Box>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <LoadingOverlay visible={pending} />
        <TextInput
          placeholder='Organization name'
          label='Organization Name'
          required
          data-autofocus
          {...form.getInputProps('name')}
        />
        <TextInput
          placeholder='Short ID'
          label='Organization Short Name'
          description='This short identifier must be a unique, short lowercase identifier for the organization'
          required
          data-autofocus
          {...form.getInputProps('id')}
        />
        {error && (
          <Text color='red' size='sm' mt='sm'>
            {error}
          </Text>
        )}
        <Space h='xl' />
        <Group position='right'>
          <Button type='submit'>Create</Button>
          <Button variant='outline' type='button' onClick={onClose}>
            Cancel
          </Button>
        </Group>
      </form>
    </Box>
  );
}

export default NewOrgForm;
