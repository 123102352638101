import { Anchor, Box, Breadcrumbs, Group, MediaQuery } from '@mantine/core';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { getter } from '../../app/feathersClient';
import {
  selectCurrentOrgId,
  selectCurrentVenueId,
  selectInitialRedirect,
} from '../../features/ui/uiSlice';
import ThemeToggle from '../HeaderContent/ThemeToggle';

function NavOrgCrumb({ match }) {
  const orgId = match.params.orgId;
  const { data } = useSWR(`orgs/${orgId}`, getter);
  return data?.name ?? 'Org';
}

function NavVenueCrumb({ match }) {
  const venueId = match.params.venueId;
  const { data } = useSWR(`venues/${venueId}`, getter);
  return data?.name ?? 'Venue';
}

function NavFixtureCrumb({ match }) {
  const fixtureId = match.params.fixtureId;
  const { data } = useSWR(`fixtures/${fixtureId}`, getter);
  if (!data) return 'Fixture';
  return data.nickname ? `${data.identifier} (${data.nickname})` : data.identifier ?? 'Fixture';
}

const routes = [
  // Remove "Home" from breadcrumb list
  { path: '/', breadcrumb: null },
  { path: '/orgs/', breadcrumb: null },
  // Define dynamic breadcrumbs
  { path: '/orgs/:orgId', breadcrumb: NavOrgCrumb },
  { path: '/orgs/:orgId/venues/:venueId', breadcrumb: NavVenueCrumb },
  { path: '/orgs/:orgId/venues/:venueId/fixtures/:fixtureId', breadcrumb: NavFixtureCrumb },
];

function NavTrail() {
  const breadcrumbs = useBreadcrumbs(routes);

  // Logic to persist selected venue/org is here
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentOrgId = useSelector(selectCurrentOrgId);
  const currentVenueId = useSelector(selectCurrentVenueId);
  const initialRedirect = useSelector(selectInitialRedirect);
  const [shouldRedirect, setShouldRedirect] = useState(null);

  const orgMatch = useMatch({
    path: `/orgs/:orgId/*`,
    exact: false,
    strict: false,
    end: false,
  });
  const venueMatch = useMatch({
    path: `/orgs/:orgId/venues/:venueId/*`,
    exact: false,
    strict: false,
    end: false,
  });
  const orgId = orgMatch?.params?.orgId || null;
  const venueId = venueMatch?.params?.venueId || null;

  useEffect(() => {
    if (orgId !== currentOrgId) {
      dispatch({ type: 'ui/changeOrg', payload: orgId });
    }
    if (venueId !== currentVenueId) {
      dispatch({ type: 'ui/changeVenue', payload: venueId });
    }
    if (initialRedirect && !shouldRedirect && !orgId) {
      setShouldRedirect(initialRedirect);
      dispatch({ type: 'ui/redirectSuccess' });
    }
    if (!initialRedirect && shouldRedirect) {
      navigate(shouldRedirect);
      setShouldRedirect(null);
    }
    //eslint-disable-next-line
  }, [orgId, venueId, initialRedirect, shouldRedirect]);

  const items = breadcrumbs.map(({ breadcrumb }) => (
    <Anchor key={breadcrumb} component={Link} to={breadcrumb.key || '#'}>
      {breadcrumb}
    </Anchor>
  ));

  const lastItem = breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1] : null;
  const secondLastItem = breadcrumbs.length > 1 ? breadcrumbs[breadcrumbs.length - 2] : null;

  return (
    <Group
      p={0}
      pl={20}
      pr={16}
      sx={(theme) => ({
        width: '100%',
        borderBottom: `1px solid ${
          theme.colorScheme === 'dark' ? theme.colors.gray[8] : theme.colors.gray[2]
        }`,
        height: '40px',
        // backgroundColor: theme.colors.dark[7],
      })}
      spacing={0}
      position={'apart'}
    >
      <MediaQuery largerThan='md' styles={{ display: 'none' }}>
        <Breadcrumbs>
          {secondLastItem && (
            <Anchor key={secondLastItem.key} component={Link} to={secondLastItem?.key || '#'}>
              ...
            </Anchor>
          )}
          <Anchor key={lastItem?.key} component={Link} to={lastItem?.key || '#'}>
            {lastItem?.breadcrumb}
          </Anchor>
        </Breadcrumbs>
      </MediaQuery>
      <MediaQuery smallerThan='md' styles={{ display: 'none' }}>
        <Breadcrumbs>{items}</Breadcrumbs>
      </MediaQuery>

      <MediaQuery smallerThan='xs' styles={{ display: 'none' }}>
        <Box>
          <ThemeToggle />
        </Box>
      </MediaQuery>
    </Group>
  );
}

export default NavTrail;
