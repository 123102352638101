import React from 'react';
import { Code, Group, Text } from '@mantine/core';
import { getColourValue, getPositionShortNameFromLog } from '../../common/commonResolvers';
import PositionBadgeRenderer from '../positions/PositionBadgeRenderer';
import logTypesMap from '../../common/logTypesMap';

function LogMeta({ log, simple = true }) {
  const { logMeta } = log;

  const logType = logMeta?.logType || null;

  const status = logMeta?.status?.current || null;
  const oldStatus = logMeta?.status?.previous || null;

  const position = logMeta?.position?.current || null;
  const oldPosition = logMeta?.position?.previous || null;

  const parent = {
    id: position?.parentId || null,
    name: position?.parentName || null,
    type: position?.parentType || null,
  };
  const oldParent = {
    id: oldPosition?.parentId || null,
    name: oldPosition?.parentName || null,
    type: oldPosition?.parentType || null,
  };

  const venue = logMeta?.venue?.current || null;
  const oldVenue = logMeta?.venue?.previous || logMeta?.venue?.current || null;

  if (simple) {
    return (
      <Code p={'0'}>
        Log Type: {logTypesMap[logType]?.name || 'unknown'}
        <br />
        Status:{' '}
        {oldStatus
          ? `Changed from ${oldStatus.name} to ${status?.name}`
          : status?.name || 'unknown'}
        <br />
        Position:{' '}
        {oldPosition
          ? `Changed from [${getPositionShortNameFromLog(
              log,
              '/',
              'previous'
            )}] to [${getPositionShortNameFromLog(log)}]`
          : position?.name || 'unknown'}
        <br />
        Service Tags:{' '}
        {log?.tagData?.length > 0
          ? log?.tagData?.map((tag) => `${tag.name} (${tag.shortname})`).join(', ')
          : 'none'}
      </Code>
    );
  }

  return (
    <>
      {logType && (
        <Group mt='xs'>
          <Text size='sm' color='dimmed' weight='600'>
            Log Type:
          </Text>
          <Text size='sm' color={logType.colour || 'blue'}>
            {logType.name || 'unknown'}
          </Text>
        </Group>
      )}
      {status && (
        <Group mt='xs'>
          <Text size='sm' color='dimmed' weight='600'>
            Status:
          </Text>
          {oldStatus && (
            <>
              <Text size='sm' color='dimmed'>
                Changed from
              </Text>
              <Text size='sm' color={getColourValue(oldStatus.colour) || 'dimmed'}>
                {oldStatus.name}
              </Text>
              <Text size='sm' color='dimmed'>
                to
              </Text>
            </>
          )}
          <Text size='sm' color={getColourValue(status.colour) || 'blue'}>
            {status.name || 'unknown'}
          </Text>
        </Group>
      )}
      {position && (
        <Group mt='xs'>
          <Text size='sm' color='dimmed' weight='600'>
            Position:
          </Text>
          {oldPosition && (
            <>
              <Text size='sm' color='dimmed'>
                Moved from
              </Text>
              <PositionBadgeRenderer position={oldPosition} parent={oldParent} venue={oldVenue} />
              <Text size='sm' color='dimmed'>
                to
              </Text>
            </>
          )}

          <PositionBadgeRenderer position={position} parent={parent} venue={venue} />
        </Group>
      )}
    </>
  );
}

export default LogMeta;
