import { Box, Button, Group, TextInput, Text, Select, LoadingOverlay } from '@mantine/core';
import { useForm } from '@mantine/form';
import React, { useEffect, useState } from 'react';
import app from '../../app/feathersClient';
import { RX_VALID_EMAIL } from '../../common/commonRegex';
import useMatchMutate from '../../hooks/useMatchMutate';

function InviteUserForm({ org, swrKey }) {
  const [error, setError] = useState(null);
  const [submit, setSubmit] = useState(false);
  const mutate = useMatchMutate();

  const form = useForm({
    initialValues: {
      email: '',
      permission: 'USER',
    },
    validationRules: {
      email: (value) => RX_VALID_EMAIL.test(value),
    },
    errorMessages: {
      email: 'Must be a valid email address',
    },
  });

  useEffect(() => {
    if (!submit) return;
    let cancel = false;

    async function createInvite() {
      const payload = {
        orgId: org.id,
        email: form.values.email,
        isOrgAdmin: form.values.permission === 'ADMIN',
      };
      try {
        await app.service('org-access').create(payload);
        if (!cancel) {
          mutate(/service:"org-access"/);
          setError(null);
          form.reset();
        }
      } catch (error) {
        if (!cancel) setError(error.message);
      }
      if (!cancel) setSubmit(false);
    }
    createInvite();

    return () => (cancel = true);
    //eslint-disable-next-line
  }, [submit]);

  const handleSubmit = () => {
    setSubmit(true);
  };

  return (
    <Box>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <LoadingOverlay visible={submit} />
        <Group mt='sm'>
          <TextInput
            type='email'
            label='User Email Address'
            placeholder='Email'
            style={{ minWidth: 250, flex: 4 }}
            {...form.getInputProps('email')}
          />
          <Select
            label='Permission'
            data={[
              { label: 'Admin', value: 'ADMIN' },
              { label: 'User', value: 'USER' },
            ]}
            style={{ minWidth: 120, flex: 1 }}
            {...form.getInputProps('permission')}
          />
        </Group>
        {error && (
          <Text color='red' size='sm' mt='sm'>
            {error}
          </Text>
        )}
        <Group position='right' mt='lg'>
          <Button type='submit'>Invite</Button>
          <Button variant='outline' type='button' onClick={() => form.reset()}>
            Cancel
          </Button>
        </Group>
      </form>
    </Box>
  );
}

export default InviteUserForm;
