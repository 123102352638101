export const flexRowCenter = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
};
export const flexColCenter = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};
export const flexCenterCenter = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};
export function themeBgColor(theme, light, dark) {
  return { backgroundColor: theme.colorScheme === 'dark' ? dark : light };
}
export function themeTextColor(theme) {
  return { color: theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[8] };
}
export function themeHeaderTextColor(theme) {
  return { color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7] };
}
export function themeGroupTextColor(theme) {
  return { color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7] };
}
export function themeHeaderRow(theme) {
  return {
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
    padding: '7px 20px',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[1],
  };
}
export function themeGroupRow(theme) {
  return {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    padding: '7px 20px',
    minHeight: 56,
  };
}
export function themeGroupSubRow(theme) {
  return {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    padding: '0px ',
    minHeight: 56,
  };
}
export function themeGroupRowContainer(theme) {
  return {
    borderBottom: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 0,
    ':last-child': {
      borderBottom: 'none',
    },
  };
}
export function themeGroupSubRowContainer(theme) {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 0,
  };
}
export function themeRepeatingGroup(theme) {
  return {
    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,
    borderRadius: '8px',
    overflow: 'hidden',
    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
      // width: 'calc(100% + 56px)',
      // marginLeft: '-28px',
      borderRadius: 0,
    },
  };
}
export function themeRepeatingSubGroup(theme) {
  return {
    overflow: 'hidden',
    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
      // width: 'calc(100% + 56px)',
      // marginLeft: '-28px',
      borderRadius: 0,
    },
  };
}
