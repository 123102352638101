import React, { useState } from 'react';
import { fetcher } from '../../app/feathersClient';
import useSWR from 'swr';

import { getPaginationValues } from '../../common/commonResolvers';

import { Box, Pagination } from '@mantine/core';

import GroupRow from '../RepeatingGroup/GroupRow';
import { useParams } from 'react-router-dom';
import { themeRepeatingSubGroup } from '../../common/commonStyles';

function NestedList({ model, query = {}, pageSize = 5 }) {
  const [page, setPage] = useState(1);
  const urlParams = useParams();

  let injectQuery = {};
  if (query.inject) {
    query.inject.forEach((item) => {
      if (urlParams[item]) {
        injectQuery[item] = urlParams[item];
      }
    });
  }

  const swrKey = {
    service: query.service,
    query: {
      ...query.query,
      ...injectQuery,
      $sort: { name: 1 },
      $limit: pageSize,
      $skip: (page - 1) * pageSize,
    },
  };

  const { data: result } = useSWR(swrKey, fetcher);

  const pg = getPaginationValues(result?.total, pageSize, page);
  const data = result?.data || [];
  const total = result?.total || 0;

  return (
    <Box
      sx={(theme) => ({
        width: '100%',
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.gray[2],
      })}
    >
      <Box
        sx={(theme) => ({
          ...themeRepeatingSubGroup(theme),
        })}
      >
        {data?.map((row) => (
          <GroupRow
            model={model.nestedColDefs ? { colDefs: model.nestedColDefs } : model}
            row={row}
            key={model.nestedKey ? row[model.nestedKey] : row[model.key]}
            nested
          />
        ))}
      </Box>
      {data && total > pageSize && (
        <Pagination
          mt='xl'
          mb='xl'
          initialPage={1}
          page={page}
          total={pg.totalPages}
          onChange={setPage}
          position='center'
        />
      )}
    </Box>
  );
}

export default NestedList;
