import { ActionIcon, Group } from '@mantine/core';
import React, { useState } from 'react';
import ObjectActionHandler from '../ObjectAction/ObjectActionHandler';

function ActionButtons({ data, model }) {
  const [action, setAction] = useState(null);

  const buttons = model?.actionButtons.map((action) => {
    return (
      <ActionIcon key={action} onClick={() => setAction(action)}>
        {model.actions[action].icon}
      </ActionIcon>
    );
  });

  const handleClose = () => setAction(null);

  return (
    <Group noWrap spacing={'xs'}>
      <ObjectActionHandler model={model} action={action} onClose={handleClose} targetData={data} />
      {buttons}
    </Group>
  );
}

export default ActionButtons;
