import React from 'react';
import {
  Text,
  TextInput,
  Space,
  Group,
  Button,
  Box,
  LoadingOverlay,
  Textarea,
  Select,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import { fetcher } from '../../app/feathersClient';
import DataAutocomplete from '../DataAutocomplete/DataAutocomplete';
import { useEffect } from 'react';

function FixtureForm({
  target,
  targetData,
  onClose,
  onSubmit,
  success,
  action,
  pending = false,
  error = '',
}) {
  const { venueId, orgId } = useParams();

  const { data: typeList = { data: [] } } = useSWR(
    {
      service: 'fixture-types',
      query: {
        orgId,
      },
    },
    fetcher
  );
  const {
    data: statusList = {
      data: [],
    },
  } = useSWR(
    {
      service: 'status',
      query: {
        orgId,
      },
    },
    fetcher
  );

  const form = useForm({
    initialValues: {
      venueId,
      identifier: target ? targetData.identifier : '',
      nickname: target ? targetData.nickname : '',
      description: target ? targetData.description : '',
      typeId: target ? targetData.typeId : '',
      positionId: target ? targetData.positionId : '',
      status: target ? targetData.status?.toString() : '0',
    },
    validationRules: {
      venueId: (value) => value !== '',
      identifier: (value) => value.trim().length >= 1 && value.trim().length <= 64,
      nickname: (value) => value.trim().length <= 64,
      description: (value) => value.trim().length <= 128,
      typeId: (value) => value !== '',
    },
    errorMessages: {
      venueId: 'Venue must be selected',
      identifier: 'Identifier should be between 1 and 64 characters',
      nickname: 'Nickname should be between 1 and 64 characters',
      description: 'Description should be less than 128 characters',
      typeId: 'Type must be selected',
    },
  });
  useEffect(() => {
    if (success) onClose();
    // eslint-disable-next-line
  }, [success]);

  // console.log(`Location: FixtureForm, Value:`, action);
  return (
    <Box>
      <form
        onSubmit={form.onSubmit(() => {
          onSubmit({ ...form.values, id: target ? target : undefined });
        })}
      >
        <LoadingOverlay visible={pending} />

        <TextInput
          placeholder='Identifier'
          label='Identifier'
          required
          data-autofocus
          mt='md'
          {...form.getInputProps('identifier')}
        />
        <TextInput
          placeholder='Nickname'
          label='Nickname'
          mt='md'
          {...form.getInputProps('nickname')}
        />
        <Select
          placeholder='Fixture Type'
          label='Fixture Type'
          mt='md'
          required
          data={typeList.data.map((t) => ({ value: t.id, label: t.name }))}
          {...form.getInputProps('typeId')}
        />
        {action === 'create' && (
          <>
            <DataAutocomplete
              model={{
                service: 'positions',
                injectGetQuery: ['orgId', 'venueId'],
              }}
              label='Position'
              placeholder='Position'
              mt='md'
              matchField={'name'}
              {...form.getInputProps('positionId')}
            />
            <Select
              placeholder='Status'
              label='Status'
              mt='md'
              searchable
              data={statusList.data.map((v) => ({ value: v.id, label: v.name }))}
              {...form.getInputProps('status')}
            />
          </>
        )}
        <Textarea
          placeholder='Description'
          label='Description'
          mt='md'
          {...form.getInputProps('description')}
        />

        {error && (
          <Text color='red' size='sm' mt='sm'>
            {error}
          </Text>
        )}
        <Space h='xl' />
        <Group position='right'>
          <Button type='submit'>{action === 'create' ? 'Create' : 'Update'}</Button>
          <Button variant='outline' type='button' onClick={onClose}>
            Cancel
          </Button>
        </Group>
      </form>
    </Box>
  );
}

export default FixtureForm;
