import React, { useEffect, useRef, useState } from 'react';
import useSWR, { useSWRConfig } from 'swr';
import {
  Textarea,
  Group,
  Button,
  FileButton,
  ActionIcon,
  CloseButton,
  Paper,
  Image,
  Loader,
} from '@mantine/core';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { selectAuth } from '../../features/auth/authSlice';
import { showNotification } from '@mantine/notifications';
import { getter } from '../../app/feathersClient';

function CommentForm({
  targetId,
  logId = null,
  handleSubmit,
  pending,
  finished,
  error,
  handleReset,
  method = 'create',
  initialValue = '',
  suppressLabel = false,
  onCancel = null,
}) {
  const [text, setText] = useState(initialValue);
  const [file, setFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [imageId, setImageId] = useState(null);
  const { mutate } = useSWRConfig();
  const { orgId } = useParams();
  const { accessToken } = useSelector(selectAuth);
  const resetRef = useRef(null);
  const { data: comment } = useSWR(targetId && logId ? `comments/${targetId}` : false, getter);

  const host =
    window.location.hostname === 'fixtr.io' ? 'https://api.fixtr.io' : window.location.hostname;
  const port = host === 'localhost' || host.startsWith('10.0.0') ? ':3030' : '';
  const protocol = host.includes('https://') ? '' : 'http://';

  useEffect(() => {
    let cancel = false;
    if (uploading) {
      if (!file) setUploading(false);

      let formData = new FormData();
      formData.append('file', file);
      formData.append('orgId', orgId);
      formData.append('name', 'Image');
      formData.append('description', '');

      async function upload() {
        const response = await fetch(`${protocol}${host}${port}/images`, {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        const data = await response.json();

        if (data.code !== 201) {
          setSubmitting(false);
          setUploading(false);
          showNotification({
            title: 'Error Uploading Image',
            message: data.message,
            color: 'red',
          });
          return;
        }
        if (!cancel) {
          showNotification({
            title: 'Image Uploaded',
            message: 'Your image has been uploaded successfully',
            color: 'green',
          });
          setImageId(data.s3Id);
          setUploading(false);
        }
      }
      upload();
    }

    if (submitting === 'create' && !uploading) {
      // Create the comment data
      const data = {
        orgId,
        logId: targetId,
        text,
      };
      if (imageId) data.images = [imageId];
      const params = {};

      // Create the comment with or without the image URL
      handleSubmit(data, params);
    }

    if (submitting === 'patch' && !uploading) {
      // Create the comment data
      const id = targetId;
      const data = {
        text,
      };
      if (imageId) data.images = [...comment.images, imageId];
      const params = {};

      // Create the comment with or without the image URL
      handleSubmit(id, data, params);
    }

    if (error && !cancel) {
      setSubmitting(false);
      setUploading(false);
      showNotification({
        title: 'Error',
        message: error.message,
        color: 'red',
      });
    }

    return () => {
      cancel = true;
    };
    // eslint-disable-next-line
  }, [uploading, submitting, error]);

  useEffect(() => {
    if (finished && !uploading) {
      setText('');
      setSubmitting(false);
      handleReset();
      clearFile();
      mutate({
        service: 'comments',
        query: {
          logId: logId || targetId,
          $sort: { createdAt: 1 },
        },
      });

      if (typeof onCancel === 'function') {
        onCancel();
      }
    }
    // eslint-disable-next-line
  }, [finished, uploading]);

  useEffect(() => {
    if (!file) return;
    // create the preview
    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  const handleCancel = () => {
    setText('');
    handleReset();
    onCancel();
  };

  const buttonFuncs = {
    create: () => {
      setUploading(true);
      setSubmitting('create');
    },
    patch: () => {
      setUploading(true);
      setSubmitting('patch');
    },
  };

  const buttonText = {
    create: 'Submit',
    patch: 'Update',
  };

  const isBusy = pending || uploading || submitting;

  const clearFile = () => {
    setFile(null);
    resetRef.current?.();
  };

  return (
    <Paper p='sm' withBorder>
      <Textarea
        label={suppressLabel ? undefined : 'Comment'}
        placeholder='Comment'
        multiline
        minRows={2}
        maxRows={4}
        autosize
        value={text}
        onChange={(e) => setText(e.currentTarget.value)}
        disabled={isBusy}
        error={error}
        rightSection={
          <Group style={{ marginRight: 8 }} spacing={'xs'} position='right'>
            <FileButton
              onChange={setFile}
              variant='solid'
              color='blue'
              size='lg'
              accept='image/png,image/jpeg'
              resetRef={resetRef}
              disabled={isBusy}
            >
              {(props) => (
                <ActionIcon size='lg' variant='filled' color='blue' {...props}>
                  <FontAwesomeIcon icon={faImage} />
                </ActionIcon>
              )}
            </FileButton>
            {file && !isBusy && (
              <CloseButton onClick={clearFile} variant='transparent' size='lg' m='0' p='0' />
            )}
            <Button onClick={buttonFuncs[method]} disabled={isBusy}>
              {isBusy ? <Loader size='sm' /> : buttonText[method]}
            </Button>
            {typeof onCancel === 'function' && (
              <Button variant='outline' onClick={handleCancel} disabled={isBusy}>
                Cancel
              </Button>
            )}
          </Group>
        }
        rightSectionWidth={typeof onCancel === 'function' ? '290px' : '190px'}
      ></Textarea>
      {file && <Image width='200px' fit='contain' src={preview} m='md' />}
    </Paper>
  );
}

export default CommentForm;
