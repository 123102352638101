import { ActionIcon, Box, Group, Tabs, Text } from '@mantine/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher } from '../../app/feathersClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

function FixtureIssuesList({ fixtureId, closed = false, setIssueId }) {
  const { orgId } = useParams();
  const { data: issues } = useSWR(
    {
      service: 'issues',
      query: {
        fixtureId,
        closed,
        orgId,
      },
    },
    fetcher
  );

  return (
    <Box mt='md'>
      {issues && issues.data?.length > 0 ? (
        issues.data.map((issue) => (
          <Group key={issue.id}>
            <ActionIcon onClick={() => setIssueId(issue.id)} style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faEye} color='gray' />
            </ActionIcon>
            <Text size='sm'>{`[${issue.prefix.toUpperCase()}${issue.number}] ${issue.name}`}</Text>
          </Group>
        ))
      ) : (
        <Text color='dimmed'>No open issues</Text>
      )}
    </Box>
  );
}

function FixtureIssuesBlock({ fixtureId, setIssueId }) {
  return (
    <Box>
      <Tabs defaultValue={'open'}>
        <Tabs.List>
          <Tabs.Tab value='open'>Open Issues</Tabs.Tab>
          <Tabs.Tab value='closed'>Closed Issues</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value='open'>
          <FixtureIssuesList fixtureId={fixtureId} closed={false} setIssueId={setIssueId} />
        </Tabs.Panel>
        <Tabs.Panel value='closed'>
          <FixtureIssuesList fixtureId={fixtureId} closed={true} setIssueId={setIssueId} />
        </Tabs.Panel>
      </Tabs>
    </Box>
  );
}

export default FixtureIssuesBlock;
