import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { selectAuth } from '../../features/auth/authSlice';

function RequireAuth({ children }) {
  const isAuthenticated = useSelector(selectAuth);
  const location = useLocation();

  if (!isAuthenticated) return <Navigate to='/login' state={{ from: location }} replace />;

  return children;
}

export default RequireAuth;
