import React, { useEffect, useState } from 'react';
import app from '../../app/feathersClient';

function ServiceActionProvider({
  render,
  children = [],
  service,
  method,
  params,
  autoStart = false,
  autoArgs = [],
  ...rest
}) {
  const [pending, setPending] = useState(false);
  const [finished, setFinished] = useState(false);
  const [error, setError] = useState(null);
  const [result, setResult] = useState(null);
  const [applyAction, setApplyAction] = useState(autoStart);
  const [args, setArgs] = useState(autoArgs);

  const arrayChildren = React.Children.toArray(children);

  useEffect(() => {
    if (!applyAction || !args) return;

    let cancel = false;
    setPending(true);

    async function handleServiceCall() {
      try {
        const res = await app.service(service)[method](...args);
        if (!cancel) {
          setResult(res);
          setPending(false);
          setFinished(true);
          setApplyAction(false);
        }
      } catch (error) {
        console.log(error);
        if (error.message && !cancel) {
          setError(error.message);
          setApplyAction(false);
          setPending(false);
        }
      }
    }
    handleServiceCall();

    return () => (cancel = true);

    // eslint-disable-next-line
  }, [applyAction]);

  const handleReset = () => {
    setPending(false);
    setFinished(false);
    setError(false);
    setApplyAction(false);
    setArgs([]);
  };

  const handleSubmit = (...args) => {
    setArgs(args);
    setApplyAction(true);
  };

  const newProps = {
    handleSubmit,
    pending,
    handleReset,
    finished,
    result,
    error,
    service,
    method,
    params,
    ...rest,
  };
  if (typeof children === 'function') {
    return children(newProps);
  }

  return <>{arrayChildren.map((child) => React.cloneElement(child, newProps))}</>;
}

export default ServiceActionProvider;
