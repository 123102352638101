import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon, Group } from '@mantine/core';
import React from 'react';
import { Link } from 'react-router-dom';

import OrgInviteButtons from './OrgInviteButtons';

function OrgActionButtons({ org }) {
  if (org.isInvite) return <OrgInviteButtons org={org} />;

  return (
    <Group position='center' noWrap style={{ minWidth: 56 }} spacing='xs'>
      <ActionIcon color='gray' size='md' component={Link} to={`/orgs/${org.id}/config`}>
        <FontAwesomeIcon icon={faCog}></FontAwesomeIcon>
      </ActionIcon>
    </Group>
  );
}

export default OrgActionButtons;
