import { Box, Grid, Title } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import React, { useEffect } from 'react';
import ObjectList from '../ObjectList/ObjectList';
import Toolbar from '../Toolbar/Toolbar';
import { useState } from 'react';

function ListPage({ model, query = {} }) {
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [filters, setFilters] = useState({});

  Object.keys(filters).forEach((key) => {
    query = { ...query, ...filters[key] };
  });

  // If Model changes, flush the filters
  useEffect(() => {
    setFilters({});
  }, [model]);

  if (!model) return null;
  return (
    <Box>
      <Box
        p='lg'
        sx={(theme) => ({
          borderBottom: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.gray[9] : theme.colors.gray[1]
          }`,
        })}
      >
        <Title order={2}>{model.title}</Title>
      </Box>

      <Grid columns={3} p={isMobile ? 0 : 'lg'} m={0}>
        {!model.fullWidth && (
          <Grid.Col
            span={3}
            md={1}
            sx={(theme) => ({
              [`@media (min-width: ${theme.breakpoints.md}px)`]: {
                paddingTop: 72,
                paddingLeft: 8,
                paddingRight: 48,
              },
            })}
          >
            {model.sidebar ?? null}
          </Grid.Col>
        )}
        <Grid.Col span={3} md={model.fullWidth ? 3 : 2}>
          <Toolbar model={model} filters={filters} setFilters={setFilters} />
          <ObjectList model={model} query={query} resetOnQueryChange />
        </Grid.Col>
      </Grid>
    </Box>
  );
}

export default ListPage;
