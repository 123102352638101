import React from 'react';
import ServiceActionProvider from '../ServiceAction/ServiceActionProvider';
import CommentForm from './CommentForm';

function CommentUpdater({ targetId, mode = 'patch', ...rest }) {
  return (
    <ServiceActionProvider service='comments' method={mode}>
      <CommentForm targetId={targetId} method={mode} {...rest} />
    </ServiceActionProvider>
  );
}

export default CommentUpdater;
