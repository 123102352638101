import React from 'react';
import { Link } from 'react-router-dom';

import { Anchor, Text } from '@mantine/core';

function OrgLink({ data, cellData, ...rest }) {
  if (data?.isInvite)
    return (
      <Text lineClamp={1} style={{ padding: '4px 12px' }}>
        {cellData}
      </Text>
    );
  return (
    <Anchor component={Link} to={`/orgs/${data.id}`} {...rest}>
      <Text lineClamp={1}>{cellData}</Text>
    </Anchor>
  );
}

export default OrgLink;
