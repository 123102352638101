import { fork } from 'redux-saga/effects';
import handleAuth from './authSaga';

function* sagaStarted() {
  yield console.log('saga started');
}

export default function* saga() {
  yield fork(sagaStarted);
  yield fork(handleAuth);
}
