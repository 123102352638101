import React, { useEffect, useState } from 'react';
import app, { fetcher } from '../../app/feathersClient';

import useSWR from 'swr';

import RepeatingGroup from '../RepeatingGroup/RepeatingGroup';
import {
  Box,
  Pagination,
  Text,
  Select,
  ActionIcon,
  Group,
  Popover,
  Button,
  LoadingOverlay,
  Loader,
  Stack,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

import {
  assessOrgAccessLevel,
  getOrgRoleText,
  getOrgStatus,
  getPaginationValues,
  ORG_ADMIN,
} from '../../common/commonResolvers';
import useMatchMutate from '../../hooks/useMatchMutate';

function RoleEditor({ user }) {
  const mutate = useMatchMutate();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(getOrgRoleText(user).toUpperCase());
  const [shouldUpdate, setShouldUpdate] = useState(false);

  const triggerMutate = () => {
    mutate(/service:"org-access"/);
  };

  useEffect(() => {
    let cancel = false;

    async function updateAccess() {
      if (value !== 'DELETE') {
        // In this case, user.id is actually the user access Id
        const res = await app
          .service('org-access')
          .patch(`${user.userId},${user.orgId}`, { isOrgAdmin: value === 'ADMIN' });
        // todo: handle notify toast if call fails
        triggerMutate();
        if (!cancel) {
          setShouldUpdate(false);
          setOpen(false);
        }
      } else {
        // In this case, user.id is actually the user access Id
        const res = await app.service('org-access').remove(`${user.userId},${user.orgId}`);
        // todo: handle notify toast if call fails
        triggerMutate();
        if (!cancel) {
          setShouldUpdate(false);
          setOpen(false);
        }
      }
    }

    if (shouldUpdate) {
      updateAccess();
    }
    return () => (cancel = true);
    //eslint-disable-next-line
  }, [shouldUpdate]);

  const handleUpdate = () => {
    setShouldUpdate(true);
  };

  return (
    <Group position='apart' style={{ flex: 0, flexWrap: 'nowrap' }}>
      <Text size='sm' style={{ minWidth: 40 }}>
        {getOrgRoleText(user)}
      </Text>
      {!user.isOrgOwner && (
        <Popover opened={open} onClose={() => setOpen(false)} position='bottom' withArrow>
          <Popover.Target>
            <ActionIcon onClick={() => setOpen(true)}>
              <FontAwesomeIcon icon={faEdit} />
            </ActionIcon>
          </Popover.Target>
          <Popover.Dropdown>
            <LoadingOverlay visible={shouldUpdate} />
            <Select
              label='Permission'
              data={[
                { label: 'Admin', value: 'ADMIN', group: 'Permission' },
                { label: 'User', value: 'USER', group: 'Permission' },
                { label: 'Remove User From Org', value: 'DELETE', group: 'Restrict' },
              ]}
              value={value}
              onChange={setValue}
            />
            <Group position='right'>
              <Button
                size='xs'
                mt='sm'
                color={value === 'DELETE' ? 'red' : 'blue'}
                onClick={handleUpdate}
              >
                {value === 'DELETE' ? 'Remove' : 'Update'}
              </Button>
              <Button variant='outline' size='xs' mt='sm' onClick={() => setOpen(false)}>
                Cancel
              </Button>
            </Group>
          </Popover.Dropdown>
        </Popover>
      )}
    </Group>
  );
}

function OrgUserEditor({ org, pageSize = 10 }) {
  const [page, setPage] = useState(1);
  const swrKey = {
    service: 'org-access',
    query: {
      orgId: org.id,
      $sort: {
        email: 1,
      },
      $skip: (page - 1) * pageSize,
      $limit: pageSize,
    },
  };

  const { data: result } = useSWR(swrKey, fetcher);

  const pg = getPaginationValues(result?.total, pageSize, page);
  const orgAccessLevel = assessOrgAccessLevel(org);

  if (!result) return <Loader />;

  return (
    <Box>
      <RepeatingGroup
        colKey='id'
        colDefs={[
          {
            key: 'username',
            label: 'User',
            getter: (data) => data.username,
            width: '30%',
            hideBreak: 'sm',
          },
          {
            key: 'email',
            label: 'Email',
            getter: (data) => data.email,
            width: '35%',
            hideBreak: 'sm',
          },
          {
            key: 'mobileLeft',
            label: 'User',
            cellRenderer: ({ data }) => (
              <Stack spacing={0}>
                <Text size='md' weight={500}>
                  {data.username}
                </Text>
                <Text size='xs' color='dimmed'>
                  {data.email} - {getOrgStatus(data).statusText}
                </Text>
              </Stack>
            ),
            width: '65%',
            showBreak: 'sm',
          },
          {
            key: 'permission',
            label: 'Permission',
            getter: (data) => data,
            cellRenderer: ({ data }) =>
              orgAccessLevel >= ORG_ADMIN ? (
                <RoleEditor user={data} />
              ) : (
                <Text size='sm'>{getOrgRoleText(data)}</Text>
              ),
            width: '20%',
            minWidth: '65px',
          },
          {
            key: 'status',
            label: 'Status',
            getter: (data) => getOrgStatus(data).statusText,
            width: '15%',
            hideBreak: 'sm',
          },
        ]}
        data={result.data}
      />
      <Pagination
        mt='xl'
        mb='xl'
        initialPage={1}
        page={page}
        total={pg.totalPages}
        onChange={setPage}
      />
    </Box>
  );
}

export default OrgUserEditor;
