import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPlus } from '@fortawesome/free-solid-svg-icons';
import {
  ActionIcon,
  Badge,
  Box,
  Container,
  Divider,
  Group,
  Modal,
  Stack,
  Text,
} from '@mantine/core';

import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import GenericDeleteConfirmation from '../../components/ObjectAction/GenericDeleteConfirmation';

import LogForm from '../../components/Logs/LogForm';
import FixtureLink from '../../components/fixtures/FixtureLink';
import { getFixtureCompositeName } from '../../common/commonResolvers';
import logTypesMap from '../../common/logTypesMap';
import StatusBadgeRenderer from '../../components/statusTypes/StatusBadgeRenderer';
import LogContent from '../../components/Logs/LogContent';
import LogEntryLabel from '../../components/Logs/LogEntryLabel';
import { useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import ActionButtons from '../../components/ActionButtons/ActionButtons';
import EditLogForm from '../../components/Logs/EditLogForm';

const CellModalRenderer = ({ data }) => {
  const [opened, setOpened] = useState(false);
  const isMobile = useMediaQuery('(max-width: 600px)');

  return (
    <Group style={{ flexWrap: 'nowrap', width: '100%' }} position='apart'>
      <Text size='sm'>{data.title}</Text>
      <Modal opened={opened} onClose={() => setOpened(false)} size='xl' fullScreen={isMobile}>
        <Container>
          <FixtureLink
            size='xl'
            data={data.fixture}
            cellData={getFixtureCompositeName(data.fixture)}
            withStatus
          />
        </Container>
        <Divider mt='lg' mb='xs' />

        <Container mt='md' mb='lg'>
          <LogEntryLabel log={data} clickable={false} />
        </Container>
        <Divider mb='lg' />
        <LogContent log={data} />
      </Modal>
      <ActionIcon color='blue.5' variant='light' onClick={() => setOpened(true)}>
        <FontAwesomeIcon icon={faEye} size='sm' color='white' />
      </ActionIcon>
    </Group>
  );
};

const ServiceTagRenderer = ({ data }) => {
  return (
    <Box>
      {data?.tagData?.map((tag) => (
        <Badge key={tag.id} variant='light' style={{ marginRight: 4, marginBottom: 4 }}>
          {tag.shortname}
        </Badge>
      ))}
    </Box>
  );
};

const logsModel = {
  title: 'Logs',
  readFrom: 'swr',
  service: 'logs',
  defaultSort: 'dateCreated',
  injectGetQuery: ['orgId', 'venueId'],
  key: 'id',
  nestedRows: false,
  logging: false,
  colDefs: [
    {
      key: 'logType',
      label: '',
      getter: (data) => data.logType,
      cellRenderer: ({ data, cellData }) => {
        return (
          <Box
            style={{ width: 16, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <FontAwesomeIcon icon={logTypesMap[data.logType].icon} color={'#C1C2C5'} />
          </Box>
        );
      },
      width: '16px',
      hideBreak: 'md',
    },
    {
      key: 'status',
      label: 'Status',
      getter: (data) => data.status,
      cellRenderer: ({ cellData }) => {
        return <StatusBadgeRenderer statusId={cellData} />;
      },
      width: '100px',
      align: 'center',
      hideBreak: 'md',
    },
    {
      key: 'fixtureId',
      label: 'Fixture',
      getter: (data) => getFixtureCompositeName(data.fixture),
      cellRenderer: ({ data, cellData }) => {
        return <FixtureLink data={data.fixture} cellData={cellData} size='sm' withStatus />;
      },
      width: '25%',
      hideBreak: 'md',
    },
    {
      key: 'title',
      label: 'Log',
      getter: (data) => data,
      width: '50%',
      flex: 1,
      cellRenderer: <CellModalRenderer />,
      hideBreak: 'md',
    },
    {
      key: 'tagData',
      label: 'Service Tags',
      getter: (data) => data,
      width: '50%',
      flex: 1,
      cellRenderer: <ServiceTagRenderer />,
      hideBreak: 'md',
    },
    {
      key: 'dateCreated',
      label: 'Date',
      getter: (data) => new Date(data.logDate || data.createdAt).toLocaleDateString(),
      cellRenderer: ({ data, cellData }) => {
        return <Text size='sm'>{cellData}</Text>;
      },
      width: '100px',
      align: 'right',
      hideBreak: 'md',
    },
    {
      key: 'update',
      label: 'Edit',
      getter: (data) => data,
      width: '32px',
      minWidth: '32px',
      hideBreak: 'sm',
      cellRenderer: (props) => <ActionButtons {...props} />,
    },
    {
      key: 'mobile',
      label: 'Logs',
      getter: (data) => data,
      width: '100%',
      minWidth: '100%',
      showBreak: 'md',
      cellRenderer: ({ cellData, data, ...rest }) => {
        return (
          <Stack style={{ width: '100%' }} spacing={0}>
            <Group position='apart' style={{ width: '100%', flexWrap: 'nowrap' }}>
              <StatusBadgeRenderer statusId={data.status} chip />
              <Box
                style={{
                  width: 16,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <FontAwesomeIcon icon={logTypesMap[data.logType].icon} color={'#C1C2C5'} />
              </Box>
              <CellModalRenderer data={data} />
              <ActionButtons data={data} {...rest} />
            </Group>
            <Group position='apart' style={{ flex: 'nowrap', width: '100%' }}>
              <FixtureLink
                data={data.fixture}
                cellData={getFixtureCompositeName(data.fixture)}
                size='sm'
                withStatus
              />

              <Text size='xs' color='dimmed'>
                {new Date(data.logDate || data.createdAt).toLocaleDateString()}
              </Text>
            </Group>
          </Stack>
        );
      },
    },
  ],
  toolbar: ['create'],
  actionButtons: ['patch'],
  leftFilters: [
    {
      key: 'userId',
      label: 'User',
      mode: 'select',
      service: 'venue-access',
      dataQuery: (context) => {
        return {
          ...context,
        };
      },
      itemData: (data) => {
        return data?.map((item) => ({ value: item.userId, label: item.username })) || [];
      },
      filterQuery: (value) => {
        if (!value) return {};
        return {
          userId: value,
        };
      },
    },
    {
      key: 'issueId',
      label: 'Issue Ticket',
      mode: 'select',
      service: 'issues',
      suppress: (context) => {
        // Suppress if no fixtureId
        return !context?.target?.id;
      },
      dataQuery: (context) => {
        if (!context?.target?.id) return false;
        return {
          orgId: context.orgId,
          fixtureId: context?.target?.id,
          $limit: 100,
          $sort: {
            number: 1,
          },
        };
      },
      itemData: (data) => {
        return (
          data?.map((item) => ({
            value: item.id,
            label: `[${item.prefix.toUpperCase()}${item.number}] ${item.name}`,
            group: item.closed ? 'Closed' : 'Open',
          })) || []
        );
      },
      filterQuery: (value) => {
        if (!value) return {};
        return {
          issueId: value,
        };
      },
    },
  ],
  actions: {
    create: {
      type: 'modal',
      icon: <FontAwesomeIcon icon={faPlus} />,
      label: 'New Log',
      title: 'New Log',
      content: <LogForm />,
    },
    patch: {
      type: 'modal',
      icon: <FontAwesomeIcon icon={faEdit} />,
      label: 'Edit Log',
      title: 'Edit Log',
      content: <EditLogForm />,
    },
    remove: {
      type: 'modal',
      icon: <FontAwesomeIcon icon={faTrash} />,
      label: 'Delete Log',
      title: 'Delete Log',
      content: <GenericDeleteConfirmation />,
    },
  },
  fullWidth: true,
  // sidebar: (
  //   <Fragment>
  //     <Title order={5}>Logs</Title>
  //     <Text size='sm' color={'dimmed'}>
  //       Logs belong to fixtures.
  //     </Text>
  //   </Fragment>
  // ),
};

export default logsModel;
