import { Box, Center, Pagination, Text, Table } from '@mantine/core';
import React, { useState } from 'react';
import useSWR from 'swr';
import { fetcher, getter } from '../../app/feathersClient';
import {
  getCompositeAccess,
  getPaginationValues,
  getVenueRoleText,
  VENUE_ADMIN,
} from '../../common/commonResolvers';
import VenueRoleEditor from './VenueRoleEditor';

function VenueUserEditor({ venue, pageSize = 10 }) {
  const [page, setPage] = useState(1);

  const { data: org } = useSWR(`orgs/${venue.orgId}`, getter);

  const { data } = useSWR(
    {
      service: 'venue-access',
      query: {
        orgId: venue.orgId,
        venueId: venue.id,
        $limit: pageSize,
        $skip: (page - 1) * pageSize,
        // Sort filter is ignored in the venue access class for now
        $sort: {
          email: 1,
        },
      },
    },
    fetcher
  );

  const pg = getPaginationValues(data?.total || 0, pageSize, page);
  const venueAccessLevel = getCompositeAccess(venue, org);

  const rows =
    data &&
    data.data.map((user) => (
      <tr key={user.userId}>
        <td>{user.username}</td>
        <td>{user.email}</td>
        <td>
          {venueAccessLevel >= VENUE_ADMIN ? (
            <VenueRoleEditor user={user} />
          ) : (
            <Text size='sm'>{getVenueRoleText(user)}</Text>
          )}
        </td>
      </tr>
    ));

  return (
    <Box>
      <Table>
        <thead>
          <tr>
            <th>User</th>
            <th>Email</th>
            <th>Permission</th>
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </Table>
      <Center>
        <Pagination mt='md' initialPage={1} page={page} total={pg.totalPages} onChange={setPage} />
      </Center>
    </Box>
  );
}

export default VenueUserEditor;
