import React from 'react';
import { Select } from '@mantine/core';
import useSWR from 'swr';
import { fetcher } from '../../app/feathersClient';
import { useParams } from 'react-router-dom';

function OrgSelector() {
  const { data: orgs } = useSWR({ service: 'orgs', query: {} }, fetcher);
  const { orgId: activeOrgId } = useParams();

  const data =
    orgs && orgs.data
      ? orgs.data.filter((org) => !org.isInvite).map((org) => ({ label: org.name, value: org.id }))
      : [];

  return (
    <Select data={data ?? []} disabled={!orgs || orgs.length === 0} defaultValue={activeOrgId} />
  );
}

export default OrgSelector;
