import { ActionIcon, Button, Group, Modal, Select, Text } from '@mantine/core';
import React, { useState } from 'react';
import ObjectActionHandler from '../ObjectAction/ObjectActionHandler';
import { useParams } from 'react-router-dom';
import { fetcher } from '../../app/feathersClient';
import useSWR from 'swr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';

function ToolbarFilter({ filter, filters, setFilters, target }) {
  const { orgId, venueId } = useParams();
  const [value, setValue] = useState(null);
  const { label } = filter;

  // Use an effect to update the value if the filter changes externally
  React.useEffect(() => {
    if (filters[filter.key]) {
      setValue(filters[filter.key][filter.key]);
    }
  }, [filters, filter]);

  const context = { orgId, venueId, target };

  const shouldSuppress = filter.suppress && filter.suppress(context);

  const handleChange = (value) => {
    if (!value) {
      const newFilters = { ...filters };
      delete newFilters[filter.key];
      setFilters(newFilters);
    }
    setValue(value);
    setFilters({ ...filters, [filter.key]: filter.filterQuery(value) });
  };

  const { data: filterData } = useSWR(
    shouldSuppress
      ? false
      : {
          service: filter.service,
          query: filter.dataQuery(context),
        },
    fetcher,
    { revalidateOnFocus: false }
  );

  const data = filter.itemData(filterData?.data || []);

  const handleReset = () => {
    const newFilters = { ...filters };
    delete newFilters[filter.key];
    setFilters(newFilters);
    setValue(null);
  };

  if (shouldSuppress) return null;

  return (
    <Select
      data={data}
      label={label}
      onChange={handleChange}
      value={value}
      rightSection={
        <Group noWrap style={{ width: 70 }} spacing={0} position={'right'} pr='xs'>
          <ActionIcon varient='transparent' onClick={handleReset}>
            <FontAwesomeIcon icon={faClose} />
          </ActionIcon>
        </Group>
      }
    />
  );
}

function Toolbar({ model, secondaryTarget = undefined, filters = {}, setFilters = () => {} }) {
  const [modal, setModal] = useState(null);

  const rightButtons = model?.toolbar.map((item) => {
    const { type, content, icon, label, title } = model?.actions[item];
    if (!type) return null;
    return (
      <Button
        key={item}
        leftIcon={icon}
        size='xs'
        variant='outline'
        onClick={() => {
          setModal({ title, content, action: item });
        }}
      >
        <Text>{label}</Text>
      </Button>
    );
  });

  const leftFilters =
    model?.leftFilters?.map((filter) => {
      return (
        <ToolbarFilter
          filter={filter}
          key={filter.key}
          filters={filters}
          setFilters={setFilters}
          target={secondaryTarget}
        />
      );
    }) || null;

  return (
    <Group mt='md' mb='md' position='apart'>
      <Modal opened={modal} title={modal?.title} onClose={() => setModal(null)}>
        <ObjectActionHandler
          model={model}
          onClose={() => setModal(null)}
          action={modal?.action}
          secondaryTarget={secondaryTarget}
        >
          {/* {React.isValidElement(modal?.content)
            ? React.cloneElement(modal?.content, {
                handleClose: () => setModal(null),
              })
            : null} */}
        </ObjectActionHandler>
      </Modal>
      <Group position='left'>{leftFilters}</Group>
      <Group position='right'>{rightButtons}</Group>
    </Group>
  );
}

export default Toolbar;
