import {
  Accordion,
  Box,
  Center,
  Chip,
  Text,
  Container,
  Group,
  Button,
  Divider,
  useMantineTheme,
  Badge,
  Textarea,
  TextInput,
  LoadingOverlay,
  Loader,
} from '@mantine/core';
import React, { Fragment, useEffect, useRef, useState } from 'react';

import PositionBadgeRenderer from '../positions/PositionBadgeRenderer';
import useSWR from 'swr';
import { fetcher, getter } from '../../app/feathersClient';
import { useParams } from 'react-router-dom';
import { logTypes } from '../Logs/logTypes';
import DataAutocomplete from '../DataAutocomplete/DataAutocomplete';
import { getPositionCompositeName } from '../../common/commonResolvers';
import useMatchMutate from '../../hooks/useMatchMutate';
import StatusBadgeRenderer from '../statusTypes/StatusBadgeRenderer';
import { forwardRef } from 'react';

function LogTypeStep({ logType, setLogType, proceed }) {
  const [forwarded, setForwarded] = useState(false);

  const handleChange = (value) => {
    setLogType(logTypes.find((lt) => parseInt(lt.id) === parseInt(value)));
    if (!forwarded) {
      proceed('status');
      setForwarded(true);
    }
  };

  return (
    <Container style={{ width: '100%' }}>
      <Chip.Group
        onChange={handleChange}
        value={logType?.id || false}
        mt='lg'
        mb='lg'
        position='center'
        size='md'
        multiple={false}
      >
        {logTypes.map((lt) => (
          <Chip value={lt.id} key={lt.id} color={lt.color || 'blue'}>
            {lt.name}
          </Chip>
        ))}
      </Chip.Group>
    </Container>
  );
}

function StatusStep({ statusType, setStatusType, targetId, proceed }) {
  const { data: fixture } = useSWR(targetId ? `fixtures/${targetId}` : false, getter);
  const { data: statusList } = useSWR({ service: 'status', query: {} }, fetcher);
  const [forwarded, setForwarded] = useState(false);

  useEffect(() => {
    if (targetId && fixture) {
      setStatusType(statusList.data.find((st) => st.id === parseInt(fixture.status)));
    }
    //eslint-disable-next-line
  }, [targetId, fixture]);

  if (!statusList) return <Loader />;

  const currentStatus = statusList.data.find((status) => status.id === fixture?.status) || {
    name: 'unknown',
    color: 'gray',
  };

  const handleChange = (value) => {
    setStatusType(statusList.data.find((st) => st.id === parseInt(value)));
    if (!forwarded) {
      proceed('position');
      setForwarded(true);
    }
  };
  return (
    <Fragment>
      <Center style={{ width: '100%' }}>
        <Chip.Group
          onChange={handleChange}
          value={statusType?.hasOwnProperty('id') ? statusType?.id?.toString() : false}
          multiple={false}
        >
          {statusList.data.map((status) => {
            return (
              <Chip value={status.id.toString()} color={status.color || 'blue'} key={status.id}>
                {status.name}
              </Chip>
            );
          })}
        </Chip.Group>
      </Center>
      <Center mt='lg'>
        <Button variant='outline' size='sm' onClick={() => handleChange(fixture.status)}>
          <Text>
            {`Keep current status:`}
            {'\u00A0'}
          </Text>{' '}
          <Text color={currentStatus.color}>{currentStatus.name}</Text>
        </Button>
      </Center>
    </Fragment>
  );
}

function DetailsStep({ details, setDetails, accordionState, targetId, positionId }) {
  const titleInputRef = useRef(null);
  const [pristine, setPristine] = useState(false);
  const { data: position } = useSWR(positionId ? `positions/${positionId}` : false, getter);

  useEffect(() => {
    if (positionId && position) {
      setDetails({ ...details, title: `Move to ${getPositionCompositeName(position)}` });
    }
    //eslint-disable-next-line
  }, [positionId, position]);

  // Trigger focus when accordionState changes to this tab
  // It's not very graceful... update the accordion position if
  // the number of accordion sections changes
  useEffect(() => {
    if (titleInputRef === null) return;
    let cancel = false;
    if (accordionState.find((v) => v === 'details') && !cancel) {
      setTimeout(() => {
        if (!cancel) titleInputRef.current.focus();
      }, 100);
    }
    return () => (cancel = true);
  }, [accordionState]);

  const handleTitleChange = (e) => {
    setDetails({ ...details, title: e.target.value });
    if (!pristine) setPristine(true);
  };

  const handleDescriptionChange = (e) => {
    setDetails({ ...details, description: e.target.value });
  };
  return (
    <Box>
      <TextInput
        ref={titleInputRef}
        label='Log Title'
        required
        value={details.title}
        onChange={handleTitleChange}
      />
      <Textarea
        label={'Log Details'}
        mt='md'
        value={details.description}
        onChange={handleDescriptionChange}
      />
    </Box>
  );
}

function FixtureStep({ setFixtureId, proceed, accordionState }) {
  const [forwarded, setForwarded] = useState(false);
  const autocompleteRef = useRef(null);

  useEffect(() => {
    if (autocompleteRef === null) return;

    let cancel = false;
    if (accordionState.find((v) => v === 'fixture') && !cancel) {
      setTimeout(() => {
        if (!cancel) autocompleteRef.current.focus();
      }, 100);
    }
    return () => (cancel = true);
  }, [accordionState, autocompleteRef]);

  const handleChange = (value) => {
    setFixtureId(value);
    if (!forwarded) {
      proceed('details');
      setForwarded(true);
    }
  };
  return (
    <Fragment>
      <DataAutocomplete
        model={{
          service: 'fixtures',
          injectGetQuery: ['orgId', 'venueId'],
        }}
        // label='Fixture'
        placeholder='Fixture'
        mt='0'
        passRef={autocompleteRef}
        matchField={'identifier'}
        secondaryMatchField={'nickname'}
        onChange={handleChange}
        getValueMap={(item) => ({ id: item.id, value: item.identifier, data: item })}
        customItem={forwardRef(({ data, ...others }, ref) => {
          return (
            <div ref={ref} {...others}>
              <Group nowrap>
                <StatusBadgeRenderer statusId={data.status} chip />
                <Text>
                  {data.nickname ? `${data.identifier} (${data.nickname})` : data.identifier}
                </Text>
              </Group>
            </div>
          );
        })}
      />
    </Fragment>
  );
}

function AssignFixtureForm({ positionId, onSubmit, pending, success, error, onClose }) {
  const [fixtureTargetId, setFixtureTargetId] = useState(null);
  const theme = useMantineTheme();
  const { orgId } = useParams();

  const { data: fixture } = useSWR(fixtureTargetId ? `fixtures/${fixtureTargetId}` : null, getter);
  const [accordionState, setAccordionState] = useState(['fixture']);
  const mutate = useMatchMutate();

  const [logType, setLogType] = useState({
    id: 4,
    name: 'Move',
    color: 'violet',
    icon: 'arrows',
  });
  const [statusType, setStatusType] = useState(false);

  const [details, setDetails] = useState({ title: '', description: '' });

  useEffect(() => {
    if (success) {
      mutate(new RegExp(`fixtures/${fixtureTargetId}`));
      onClose();
    }
    // eslint-disable-next-line
  }, [success]);

  const isValid = () => {
    return (
      logType !== false && statusType !== false && details.title !== '' && fixtureTargetId !== null
    );
  };

  const proceed = (step) => {
    // setAccordionState([step]);
  };

  const LogTypeLabel = ({ logType }) => (
    <Group>
      <Text color={theme.colors.dark[0]}>Log Type{logType && ': '}</Text>
      {logType && <Badge color={logType.color}>{logType?.name}</Badge>}
    </Group>
  );
  const StatusLabel = ({ statusType }) => (
    <Group>
      <Text color={theme.colors.dark[0]}>Fixture Status{statusType && ': '}</Text>
      {statusType && <Badge color={statusType.color}>{statusType?.name}</Badge>}
    </Group>
  );
  const PositionLabel = ({ positionId }) => (
    <Group mb='md'>
      <Text color={theme.colors.dark[0]}>Assign to Position{positionId && ': '}</Text>
      {positionId && <PositionBadgeRenderer positionId={positionId} />}
    </Group>
  );
  const FixtureLabel = ({ fixture }) => (
    <Group mb='md'>
      <Text color={theme.colors.dark[0]}>Fixture{fixture && ': '}</Text>
      {fixture && (
        <Text color='dimmed'>{`${fixture.identifier}${
          fixture.nickname && ` (${fixture.nickname})`
        }`}</Text>
      )}
    </Group>
  );
  const DetailsLabel = ({ details }) => (
    <Group mb='md'>
      <Text color={theme.colors.dark[0]} lineClamp={1}>
        Details:
        {' ' + details.title}
      </Text>
    </Group>
  );

  const handleSubmitButton = () => {
    const data = {
      orgId,
      fixtureId: fixtureTargetId,
      ...details,
      logMeta: {},
      logType: logType.id,
      status: statusType.id,
    };
    if (positionId && positionId !== fixture.positionId) {
      data.newPositionId = positionId;
    }
    const params = {};
    onSubmit(data, params);
  };

  // if (!fixture) return <Loader />;

  return (
    <Box>
      <LoadingOverlay visible={pending || success} />
      {/* <FixtureLabelBlock fixtureId={fixture.id} /> */}
      <PositionLabel positionId={positionId} />
      <Divider mb='lg' />
      <Accordion value={accordionState} onChange={setAccordionState}>
        <Accordion.Item value='type'>
          <Accordion.Control>
            <LogTypeLabel logType={logType} />
          </Accordion.Control>
          <Accordion.Panel>
            <LogTypeStep logType={logType} setLogType={setLogType} proceed={proceed} />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='status'>
          <Accordion.Control>
            <StatusLabel statusType={statusType} />
          </Accordion.Control>
          <Accordion.Panel>
            <StatusStep
              statusType={statusType}
              setStatusType={setStatusType}
              proceed={proceed}
              targetId={fixtureTargetId}
            />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='fixture'>
          <Accordion.Control>
            <FixtureLabel fixture={fixture} />
          </Accordion.Control>
          <Accordion.Panel>
            <FixtureStep
              setFixtureId={setFixtureTargetId}
              proceed={proceed}
              accordionState={accordionState}
            />
          </Accordion.Panel>
        </Accordion.Item>

        <Accordion.Item value='details'>
          <Accordion.Control>
            <DetailsLabel details={details} />
          </Accordion.Control>
          <Accordion.Panel>
            <DetailsStep
              details={details}
              setDetails={setDetails}
              proceed={proceed}
              accordionState={accordionState}
              positionId={positionId}
            />
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
      {error && (
        <Text mt='xl' align='center' color={'red'}>
          {error}
        </Text>
      )}
      <Group position='center'>
        <Button type='button' disabled={!isValid()} mt='xl' onClick={handleSubmitButton}>
          Submit
        </Button>
      </Group>
    </Box>
  );
}

export default AssignFixtureForm;
