import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import {
  ActionIcon,
  Box,
  Container,
  Divider,
  Group,
  Loader,
  Modal,
  Stack,
  Text,
} from '@mantine/core';

import FixtureLink from '../../components/fixtures/FixtureLink';
import { getFixtureCompositeName } from '../../common/commonResolvers';
import LogContent from '../../components/Logs/LogContent';
import LogEntryLabel from '../../components/Logs/LogEntryLabel';
import { useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { getter } from '../../app/feathersClient';
import useSWR from 'swr';

function timeSince(date) {
  let seconds = Math.floor((new Date() - date) / 1000);

  let interval = Math.floor(seconds / 31536000);

  if (interval > 1) {
    return interval + ' years';
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return interval + ' months';
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return interval + ' days';
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 0) {
    return interval + (interval > 1 ? ' hours' : ' hour');
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return interval + ' minutes';
  }
  return Math.floor(seconds) + ' seconds';
}

const CellModalRenderer = ({ data }) => {
  const [opened, setOpened] = useState(false);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const { logId } = data;

  const { data: log } = useSWR(opened ? `logs/${logId}` : false, getter);

  if (!logId) return null;

  return (
    <Group style={{ flexWrap: 'nowrap', width: '100%' }} position='apart'>
      <Modal opened={opened} onClose={() => setOpened(false)} size='xl' fullScreen={isMobile}>
        <Container>
          <FixtureLink size='xl' data={data} cellData={getFixtureCompositeName(data)} withStatus />
        </Container>
        <Divider mt='lg' mb='xs' />

        <Container mt='md' mb='lg'>
          {log && <LogEntryLabel log={log} clickable={false} />}
        </Container>
        <Divider mb='lg' />
        {log && <LogContent log={log} />}
      </Modal>
      <ActionIcon color='blue.5' variant='light' onClick={() => setOpened(true)}>
        <FontAwesomeIcon icon={faEye} size='sm' color='white' />
      </ActionIcon>
    </Group>
  );
};

const serviceHistoryModel = {
  title: 'Service History',
  readFrom: 'swr',
  service: 'service-history',
  defaultSort: 'dateCreated',
  injectGetQuery: ['orgId', 'venueId'],
  key: 'id',
  nestedRows: false,
  logging: false,
  colDefs: [
    {
      key: 'fixtureId',
      label: 'Fixture',
      getter: (data) => getFixtureCompositeName(data),
      cellRenderer: ({ data, cellData }) => {
        return <FixtureLink data={data} cellData={cellData} size='sm' withStatus />;
      },
      width: '45%',
      hideBreak: 'sm',
    },
    {
      key: 'fixtureType',
      label: 'Fixture Type',
      getter: (data) => data.fixtureTypeName || '',
      width: '15%',
      flex: '2 1',
      hideBreak: 'sm',
    },
    {
      key: 'logId',
      label: 'Log',
      getter: (data) => data,
      width: '5%',
      flex: 1,
      cellRenderer: <CellModalRenderer />,
      hideBreak: 'sm',
    },
    {
      key: 'dateCreated',
      label: 'Last Service Date',
      getter: (data) =>
        data.serviceDate ? `${timeSince(new Date(data.serviceDate))} ago` : 'never',
      cellRenderer: ({ data, cellData }) => {
        return (
          <Text size='sm' color={data.serviceDate ? 'white.5' : 'dimmed'}>
            {cellData}
          </Text>
        );
      },
      width: '15%',
      align: 'right',
      hideBreak: 'sm',
    },
    {
      key: 'mobile',
      label: 'Service',
      getter: (data) => data,
      width: '100%',
      minWidth: '100%',
      showBreak: 'sm',
      cellRenderer: ({ cellData, data }) => {
        return (
          <Stack style={{ width: '100%' }} spacing={0}>
            <Group position='apart' style={{ width: '100%', flexWrap: 'nowrap' }}>
              <FixtureLink
                data={data}
                cellData={getFixtureCompositeName(data)}
                size='sm'
                withStatus
              />
              <Group>
                <Text size='sm' color={data.serviceDate ? 'white.5' : 'dimmed'}>
                  {data.serviceDate ? `${timeSince(new Date(data.serviceDate))} ago` : 'never'}
                </Text>
                <Box style={{ width: '28px' }}>
                  <CellModalRenderer data={data} />
                </Box>
              </Group>
            </Group>
            <Group position='apart' style={{ flex: 'nowrap', width: '100%' }}></Group>
          </Stack>
        );
      },
    },
  ],
  toolbar: [],
  actionButtons: [],
  leftFilters: [
    {
      key: 'serviceTagId',
      label: 'Service Tag',
      mode: 'select',
      service: 'service-tags',
      dataQuery: (context) => {
        return {
          orgId: context.orgId,
        };
      },
      itemData: (data) => {
        return data?.map((item) => ({ value: item.id, label: item.name })) || [];
      },
      filterQuery: (value) => {
        if (!value) return {};
        return {
          serviceTagId: value,
        };
      },
    },
    {
      key: 'fixtureTypeId',
      label: 'Fixture Type',
      mode: 'select',
      service: 'fixture-types',
      dataQuery: (context) => {
        return {
          orgId: context.orgId,
        };
      },
      itemData: (data) => {
        return data?.map((item) => ({ value: item.id, label: item.name })) || [];
      },
      filterQuery: (value) => {
        if (!value) return {};
        return {
          typeId: value,
        };
      },
    },
  ],
  actions: {},
  fullWidth: true,
  // sidebar: (
  //   <Fragment>
  //     <Title order={5}>Logs</Title>
  //     <Text size='sm' color={'dimmed'}>
  //       Logs belong to fixtures.
  //     </Text>
  //   </Fragment>
  // ),
};

export default serviceHistoryModel;
