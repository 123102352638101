import React from 'react';
import {
  Text,
  TextInput,
  Space,
  Group,
  Button,
  Box,
  LoadingOverlay,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';

import { useParams } from 'react-router-dom';

import { useEffect } from 'react';

function ServiceTagForm({
  target,
  targetData,
  onClose,
  onSubmit,
  success,
  action,
  pending = false,
  error = '',
}) {
  const { orgId } = useParams();

  const form = useForm({
    initialValues: {
      orgId: target ? targetData.orgId : orgId || '',
      name: target ? targetData.name : '',
      shortname: target ? targetData.shortname : '',
      description: target ? targetData.description : '',
    },
    validationRules: {
      orgId: (value) => value !== '',
      name: (value) => value.trim().length >= 1 && value.trim().length <= 32,
      shortname: (value) => value.trim().length >= 1 && value.trim().length <= 8,
      description: (value) => value.trim().length <= 128,
      colour: (value) => value.trim().length === 0 || value.trim().length === 7,
    },
    errorMessages: {
      venueId: 'Venue must be selected',
      name: 'Venue name should be between 1 and 32 characters',
      shortname: 'Short name should be between 1 and 8 characters',
      description: 'Description should be less than 128 characters',
      colour: 'Colour should be a Hex value',
    },
  });

  useEffect(() => {
    if (success) onClose();
    // eslint-disable-next-line
  }, [success]);

  return (
    <Box>
      <form
        onSubmit={form.onSubmit(() => {
          onSubmit({
            ...form.values,
            id: target ? target : undefined,
          });
        })}
      >
        <LoadingOverlay visible={pending} />

        <TextInput
          placeholder='Tag Name'
          label='Tag Name'
          required
          data-autofocus
          mt='md'
          {...form.getInputProps('name')}
        />

        <TextInput
          placeholder='Tag Short Name (max 8 characters)'
          label='Tag Short Name'
          required
          mt='md'
          {...form.getInputProps('shortname')}
        />

        <Textarea
          placeholder='Description'
          label='Description'
          mt='md'
          {...form.getInputProps('description')}
        />

        {error && (
          <Text color='red' size='sm' mt='sm'>
            {error}
          </Text>
        )}
        <Space h='xl' />
        <Group position='right'>
          <Button type='submit'>{action === 'create' ? 'Create' : 'Update'}</Button>
          <Button variant='outline' type='button' onClick={onClose}>
            Cancel
          </Button>
        </Group>
      </form>
    </Box>
  );
}

export default ServiceTagForm;
