import { Loader } from '@mantine/core';
import React from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { getter } from '../../app/feathersClient';
import { selectAuthState, selectIsAuthenticated } from '../../features/auth/authSlice';

function FixtureShortIdRedirect() {
  const params = useParams();
  const navigate = useNavigate();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const authState = useSelector(selectAuthState);
  console.log('AUTHSTATE', authState);

  const { data } = useSWR(`fixture-short-id/${params.shortId}`, getter);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(`/login/?fshortId=${params.shortId}`);
    }
    if (data) {
      console.log(
        'redirect',
        data,
        `/orgs/${data.orgId}/venues/${data.venueId}/fixtures/${data.id}`
      );
      navigate(`/orgs/${data.orgId}/venues/${data.venueId}/fixtures/${data.id}`);
    }
    //eslint-disable-next-line
  }, [data]);

  return <Loader width={'100%'} mt='xl' />;
}

export default FixtureShortIdRedirect;
