import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useMatch } from 'react-router-dom';
import { Box, Text, UnstyledButton, useMantineTheme } from '@mantine/core';
import React from 'react';

function NavButton({ icon, title, path, alias, menuClose }) {
  const isActive = useMatch(`/${path}`);

  const theme = useMantineTheme();

  const highlight = theme.colorScheme === 'light' ? theme.colors.gray[7] : theme.colors.gray[5];
  const highlightText = theme.colorScheme === 'light' ? theme.colors.gray[8] : theme.colors.gray[5];
  const dimmed = theme.colorScheme === 'light' ? theme.colors.gray[6] : theme.colors.gray[5];
  const dimmedText = theme.colorScheme === 'light' ? theme.colors.gray[7] : theme.colors.gray[5];

  return (
    <UnstyledButton
      component={Link}
      to={path}
      onClick={menuClose}
      name={title}
      sx={(theme) => ({
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '8px 24px',
        height: 40,
        borderRadius: 0,
        borderBottom: `1px solid ${
          theme.colorScheme === 'dark' ? theme.colors.gray[9] : theme.colors.gray[2]
        }`,
        width: '100%',
        textDecoration: 'none',
        backgroundColor: isActive
          ? theme.colorScheme === 'dark'
            ? theme.colors.dark[6]
            : theme.colors.gray[1]
          : 'transparent',

        '&:hover': {
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[3],
        },
        // '&:first-child': {
        //   borderTop: `1px solid ${theme.colors.gray[9]}`,
        // },
        [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
          height: 56,
        },
        // Collapsed menu
        [`@media screen and
        (max-width: ${theme.breakpoints.lg}px) and
        (min-width: ${theme.breakpoints.xs + 1}px)
        `]: {
          width: 90,
          flexDirection: 'column',
          height: 'unset',
          border: 'none',
          '&:last-child': { border: 'none' },
        },
      })}
    >
      <Box
        sx={(theme) => ({
          width: 32,
          height: '100%',
          marginRight: 16,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          // Collapsed menu
          [`@media screen and
        (max-width: ${theme.breakpoints.lg}px) and
        (min-width: ${theme.breakpoints.xs + 1}px)
        `]: {
            margin: '4px 0px 4px 0px',
          },
        })}
      >
        <FontAwesomeIcon size='1x' icon={icon} color={isActive ? highlight : dimmed} />
      </Box>
      <Text
        size='xs'
        color={isActive ? highlightText : dimmedText}
        weight='500'
        sx={(theme) => ({
          [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
            fontSize: 16,
          },
        })}
      >
        {title}
      </Text>
    </UnstyledButton>
  );
}

export default NavButton;
