import { Container, Group, Text, Avatar, Divider, Space } from '@mantine/core';
import React from 'react';
import useSWR from 'swr';
import { getter } from '../../app/feathersClient';
import LogCommentBlock from './LogCommentBlock';

import LogMeta from './LogMeta';
import logsModel from '../../features/logs/logsModel';
import ActionButtons from '../ActionButtons/ActionButtons';

function LogContent({ log }) {
  const { description, userId } = log;
  const logDate = new Date(log.logDate || log.createdAt);
  const { data } = useSWR(`userprofiles/${userId}`, getter, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });

  return (
    <>
      <Container padding='sm'>
        <Group position='right'>
          <ActionButtons model={logsModel} data={log} />
        </Group>
        <LogMeta log={log} />
        <Space h='md' />
        {description && <Divider label='Details' labelPosition='center' />}
        {description && (
          <Text mt='xl' mb='xl'>
            {description}
          </Text>
        )}
        <Group position='right'>
          <Avatar color='blue' radius='xl' size='xs'>
            {data && data.username.slice(0, 1).toUpperCase()}
          </Avatar>

          <Text size='xs' color='dimmed' weight='600' style={{ whiteSpace: 'nowrap' }}>
            {data && data.username} [{logDate.toLocaleDateString()} - {logDate.toLocaleTimeString()}
            ]
          </Text>
        </Group>
        <Divider label='Comments' labelPosition='center' />
        <LogCommentBlock log={log} />
      </Container>
    </>
  );
}

export default LogContent;
