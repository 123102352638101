import { Box, Loader, Title, Grid, Text } from '@mantine/core';
import React from 'react';
import OrgConfigCard from './OrgConfigCard';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { getter } from '../../app/feathersClient';

function OrgConfigPage() {
  const { orgId } = useParams();
  const { data: org } = useSWR(`orgs/${orgId}`, getter);

  if (!org) {
    return <Loader />;
  }

  return (
    <Box>
      <Box
        p='lg'
        sx={(theme) => ({
          borderBottom: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.gray[9] : theme.colors.gray[1]
          }`,
        })}
      >
        <Title order={2}>{org.name}</Title>
      </Box>
      <Grid columns={3} p='lg' m={0}>
        <Grid.Col
          span={3}
          md={1}
          sx={(theme) => ({
            [`@media (min-width: ${theme.breakpoints.md}px)`]: {
              paddingTop: 72,
              paddingLeft: 8,
              paddingRight: 48,
            },
          })}
        >
          <Title order={5}>Organization Settings</Title>
          <Text size='sm' color={'dimmed'}>
            Manage organization users, permissions and organization details.
          </Text>
        </Grid.Col>
        <Grid.Col span={3} md={2}>
          <OrgConfigCard />
        </Grid.Col>
      </Grid>
    </Box>
  );
}

export default OrgConfigPage;
