import { createSlice } from '@reduxjs/toolkit';

// Todo: Move localStorage handling into Saga?

const retrieveColourSchemeFromLocal = () => {
  try {
    return window.localStorage.getItem('color-scheme') || 'dark';
  } catch (error) {
    console.log('UI Slice: No access to LocalStorage');
  }
  return 'dark';
};

const initialState = {
  colourScheme: retrieveColourSchemeFromLocal(),
  contentFrame: 'Venues',
  currentOrgId: null,
  currentVenueId: null,
  initialRedirect: null,
  notificationQueue: [],
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setColourScheme: (state, action) => {
      state.colourScheme = action.payload.colourScheme;
    },
    setContentFrame: (state, action) => {
      state.contentFrame = action.payload.content;
    },
    setCurrentOrg: (state, action) => {
      state.currentOrgId = action.payload;
    },
    setCurrentVenue: (state, action) => {
      state.currentVenueId = action.payload;
    },
    initiateRedirect: (state, action) => {
      state.initialRedirect = action.payload;
    },
    redirectSuccess: (state) => {
      state.initialRedirect = null;
    },
  },
  extraReducers: {
    'ui/notify': (state, action) => {
      state.notificationQueue.push(action.payload);
    },
    'ui/notifyfulfilled': (state) => {
      state.notificationQueue.shift();
    },
  },
});

export const { setColourScheme, setContentFrame, setCurrentOrg, setCurrentVenue } = uiSlice.actions;

export const selectColourScheme = (state) => state.ui.colourScheme;
export const selectContentFrame = (state) => state.ui.contentFrame;
export const selectNotificationQueue = (state) => state.ui.notificationQueue;
export const selectCurrentOrgId = (state) => state.ui.currentOrgId;
export const selectCurrentVenueId = (state) => state.ui.currentVenueId;
export const selectInitialRedirect = (state) => state.ui.initialRedirect;

export default uiSlice.reducer;
