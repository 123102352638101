import { Box, Chip, Container, Group, Switch, TextInput } from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import React from 'react';
import { useState } from 'react';
import { matchPath } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher } from '../../app/feathersClient';
import fixturesModel from '../../features/fixtures/fixturesModel';
import RepeatingGroup from '../RepeatingGroup/RepeatingGroup';

function QuickSearch() {
  const [exact, setExact] = useState(false);
  const [fields, setFields] = useState(['identifier', 'nickname', 'positionName']);
  const match = matchPath(
    { path: `/orgs/:orgId/venues/:venueId/*`, exact: false, strict: false },
    window.location.pathname
  );
  const orgId = match?.params?.orgId || null;
  const venueId = match?.params?.venueId || null;

  const [value, setValue] = useState('');
  const [debounced] = useDebouncedValue(value, 400);

  const key = {
    service: 'quicksearch',
    query: {
      orgId,
      venueId,
      term: encodeURIComponent(debounced),
      fields,
      exact,
    },
  };

  const { data } = useSWR(orgId ? key : false, fetcher);

  const handleFieldChange = (value) => {
    if (value.length > 0) {
      setFields(value);
    }
  };

  return (
    <Box>
      <Group position='apart'>
        <Chip.Group value={fields} onChange={handleFieldChange} multiple>
          <Chip value='identifier'>Identifier</Chip>
          <Chip value='nickname'>Nickname</Chip>
          <Chip value='positionName'>Position</Chip>
        </Chip.Group>
        <Switch
          label='Exact'
          checked={exact}
          onChange={(e) => setExact(e.currentTarget.checked)}
        ></Switch>
      </Group>

      <TextInput
        mt='md'
        size='xl'
        data-autofocus
        onChange={(e) => setValue(e.currentTarget.value)}
        value={value}
      />
      {data?.fixtures && data.fixtures.data.length > 0 && (
        <Container mt='md' pl={0} pr={0}>
          <RepeatingGroup colKey='id' model={fixturesModel} data={data?.fixtures?.data || []} />
        </Container>
      )}
    </Box>
  );
}

export default QuickSearch;
