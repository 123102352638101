import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon, Collapse, Group, Text } from '@mantine/core';
import React, { useState } from 'react';
import {
  themeGroupRow,
  themeGroupRowContainer,
  themeGroupSubRow,
  themeGroupSubRowContainer,
  themeGroupTextColor,
} from '../../common/commonStyles';
import NestedList from '../ObjectList/NestedList';

function GroupCell({ col, rowData, model }) {
  const cellData = typeof col?.getter === 'function' ? col.getter(rowData) : rowData;

  let renderMode = 'default';
  if (typeof col?.cellRenderer === 'function') renderMode = 'func';
  if (typeof col?.cellRenderer === 'object') renderMode = 'component';

  return (
    <Group
      sx={(theme) => ({
        // todo: why is this necessary???
        backgroundColor: 'transparent',
        width: col.width,
        minWidth: col.minWidth || 20,
        maxWidth: col.width?.endsWith('%') ? undefined : col.width,
        flex: col.flex,
        [`@media (max-width: ${theme.breakpoints[col.hideBreak ?? 'xs']}px)`]: col.hideBreak
          ? { display: 'none' }
          : undefined,
        [`@media (min-width: ${theme.breakpoints[col.showBreak ?? 'xs']}px)`]: col.showBreak
          ? { display: 'none' }
          : undefined,
        justifyContent: col.align,
      })}
    >
      {renderMode === 'func' && <>{col.cellRenderer({ data: rowData, cellData, model })}</>}
      {renderMode === 'component' &&
        React.cloneElement(col.cellRenderer, { data: rowData, cellData })}
      {renderMode === 'default' && (
        <Text size='sm' lineClamp={1} sx={(theme) => ({ ...themeGroupTextColor(theme) })}>
          {typeof cellData === 'string' ? cellData : '?'}
        </Text>
      )}
    </Group>
  );
}

function GroupRow({ model, row, nested = false }) {
  const { colDefs } = model;
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Group
        sx={(theme) =>
          nested
            ? { ...themeGroupSubRowContainer(theme) }
            : {
                ...themeGroupRowContainer(theme),
              }
        }
      >
        <Group
          sx={(theme) => ({
            ...themeGroupRow(theme),
          })}
        >
          {model.nestedRows && (
            <Group style={{ width: 16 }}>
              {model.hasChildren(row) && (
                <ActionIcon
                  size='md'
                  m={0}
                  onClick={() => setExpanded(!expanded)}
                  style={{ margin: -16 }}
                >
                  <FontAwesomeIcon icon={expanded ? faChevronDown : faChevronRight} size='sm' />
                </ActionIcon>
              )}
            </Group>
          )}
          {colDefs?.map((col) => (
            <GroupCell col={col} rowData={row} key={col.key} model={model} />
          ))}
        </Group>
        {model.nestedRows && model.hasChildren(row) && (
          <Collapse
            in={expanded}
            sx={(theme) => ({
              width: '100%',
            })}
          >
            <Group
              sx={(theme) => ({
                ...themeGroupSubRow(theme),
              })}
            >
              {expanded && (
                <NestedList nested={true} model={model} query={model.getChildren(row)} />
              )}
            </Group>
          </Collapse>
        )}
      </Group>
    </>
  );
}

export default GroupRow;
