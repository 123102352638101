import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrows, faEye, faPlus } from '@fortawesome/free-solid-svg-icons';
import {
  ActionIcon,
  Container,
  Divider,
  Group,
  Modal,
  Pagination,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import ActionButtons from '../../components/ActionButtons/ActionButtons';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import GenericDeleteConfirmation from '../../components/ObjectAction/GenericDeleteConfirmation';
import PositionsForm from '../../components/positions/PositionForm';
import AssignFixtureForm from '../../components/positions/AssignFixtureForm';
import { useState } from 'react';
import { useMediaQuery } from '@mantine/hooks';
import { getPaginationValues, getPositionCompositeName } from '../../common/commonResolvers';
import { fetcher } from '../../app/feathersClient';
import useSWR from 'swr';
import RepeatingGroup from '../../components/RepeatingGroup/RepeatingGroup';
import fixturesModel from '../fixtures/fixturesModel';
import FixtureLink from '../../components/fixtures/FixtureLink';

const CellModalRenderer = ({ data, fullWidth = true }) => {
  const pageSize = 10;
  const [opened, setOpened] = useState(false);
  const [page, setPage] = useState(1);
  const isMobile = useMediaQuery('(max-width: 600px)');

  const query = {
    service: 'fixtures',
    query: {
      orgId: data?.orgId,
      venueId: data?.venueId,
      positionId: data?.id,
      $sort: { identifier: 1 },
      $limit: pageSize,
      $skip: (page - 1) * pageSize,
    },
  };
  const { data: fixtures } = useSWR(opened ? query : false, fetcher);
  const pg = getPaginationValues(fixtures?.total, pageSize, page);
  const listData = fixtures?.data || [];
  const total = fixtures?.total || 0;

  if (!data) return null;

  return (
    <Group style={{ flexWrap: 'nowrap', width: fullWidth ? '100%' : undefined }} position='center'>
      <Text size='sm'>{String(data.fixtureCount)}</Text>

      <ActionIcon
        color='blue.5'
        variant='light'
        onClick={() => setOpened(true)}
        disabled={data.fixtureCount === 0}
      >
        <FontAwesomeIcon icon={faEye} size='sm' color='white' />
      </ActionIcon>

      <Modal opened={opened} onClose={() => setOpened(false)} size='xl' fullScreen={isMobile}>
        <Container>
          <Title order={2}>{getPositionCompositeName(data)}</Title>
          <Divider />
          <Title order={5} mt='md'>
            Assigned Fixtures
          </Title>
          {fixtures && fixtures.total > 0 && (
            <Container mt='md' pl={0} pr={0}>
              <RepeatingGroup colKey='id' model={fixturesModel} data={listData} />
              {data && total > pageSize && (
                <Pagination
                  mt='xl'
                  mb='xl'
                  initialPage={1}
                  page={page}
                  total={pg.totalPages}
                  onChange={setPage}
                  position='center'
                />
              )}
            </Container>
          )}
        </Container>
        <Divider mt='lg' mb='xs' />
      </Modal>
    </Group>
  );
};

const positionsModel = {
  title: 'Positions',
  readFrom: 'swr',
  service: 'positions',
  injectGetQuery: ['orgId', 'venueId'],
  defaultSort: 'name',
  key: 'id',
  nestedRows: true,
  hasChildren: (data) => data.hasChildren,
  getChildren: (data) => ({
    service: 'positions',
    query: {
      orgId: data.orgId,
      venueId: data.venueId,
      parentId: data.id,
    },
  }),
  colDefs: [
    {
      key: 'name',
      label: 'Name',
      getter: (data) => data,
      cellRenderer: ({ data }) => (
        <Text size='sm'>{data.shortname ? `${data.name} (${data.shortname})` : data.name}</Text>
      ),
      width: '30%',
      flex: '2 1',
      hideBreak: 'sm',
    },
    {
      key: 'limitTypeId',
      label: 'Fixture Type',
      getter: (data) => (data.limitTypeId ? data.fixtureType?.name : ''),
      width: '12%',
      flex: '1 1',
      hideBreak: 'sm',
    },

    {
      key: 'fixtureCount',
      label: 'Assigned',
      getter: (data) => data,
      cellRenderer: (props) =>
        props.data.fixtureCount === 1 ? (
          <Group>
            <FixtureLink data={props.data.fixture} withStatus />
          </Group>
        ) : (
          <CellModalRenderer data={props.data} />
        ),
      width: '20%',
      hideBreak: 'sm',
      flex: '2 1',
      align: 'center',
    },
    {
      key: 'limitQty',
      label: 'Limit',
      getter: (data) => String(data.limitQty),
      width: '8%',
      hideBreak: 'sm',
    },
    {
      key: 'update',
      label: 'Actions',
      getter: (data) => data,
      width: '96px',
      minWidth: '96px',
      hideBreak: 'sm',
      cellRenderer: (props) => <ActionButtons {...props} />,
    },
    {
      key: 'mobile',
      label: 'Positions',
      cellRenderer: (props) => (
        <Stack style={{ width: '100%' }} spacing={0}>
          <Group position='apart' style={{ width: '100%' }}>
            <Text>
              {props.data.shortname
                ? `${props.data.name} (${props.data.shortname})`
                : props.data.name}
            </Text>
            <ActionButtons {...props} />
          </Group>
          <Group position='apart'>
            <Text align='right' size='sm' color='dimmed'>
              {props.data.limitTypeId ? props.data.fixtureType?.name : ''}
              {props.data.limitQty ? ` (${props.data.limitQty})` : ''}
            </Text>
          </Group>
          <Group position='apart' mt='sm'>
            <Text size='xs'>Assigned:</Text>
            {props.data.fixtureCount === 1 ? (
              <FixtureLink data={props.data.fixture} withStatus />
            ) : (
              <CellModalRenderer data={props.data} fullWidth={false} />
            )}
          </Group>
        </Stack>
      ),
      width: '100%',
      minWidth: '100%',
      showBreak: 'sm',
    },
  ],
  toolbar: ['create'],
  actionButtons: ['assign', 'patch', 'remove'],
  actions: {
    assign: {
      service: 'logs',
      method: 'create',
      type: 'modal',
      icon: <FontAwesomeIcon icon={faArrows} />,
      label: 'Assign Fixture',
      title: 'Assign Fixture',
      contextContent: (ctx) => {
        return <AssignFixtureForm positionId={ctx.id} />;
      },
    },
    create: {
      type: 'modal',
      icon: <FontAwesomeIcon icon={faPlus} />,
      label: 'New Position',
      title: 'New Position',
      content: <PositionsForm action={'create'} />,
    },
    patch: {
      type: 'modal',
      icon: <FontAwesomeIcon icon={faEdit} />,
      label: 'Edit Position',
      title: 'Edit Position',
      content: <PositionsForm />,
    },
    remove: {
      type: 'modal',
      icon: <FontAwesomeIcon icon={faTrash} />,
      label: 'Delete Position',
      title: 'Delete Position',
      content: <GenericDeleteConfirmation />,
    },
  },
  fullWidth: true,
  drawer: {
    activationParam: 'positionId',
  },
  // sidebar: (
  //   <Fragment>
  //     <Title order={5}>Positions</Title>
  //     <Text size='sm' color={'dimmed'}>
  //       Positions belong to venues.
  //     </Text>
  //   </Fragment>
  // ),
};

export default positionsModel;
