import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text, Select, ActionIcon, Group, Popover, Button, LoadingOverlay } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import app from '../../app/feathersClient';
import { getVenueRoleText } from '../../common/commonResolvers';
import useMatchMutate from '../../hooks/useMatchMutate';

function VenueRoleEditor({ user }) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(getVenueRoleText(user).toUpperCase());
  const [shouldUpdate, setShouldUpdate] = useState(false);
  const mutate = useMatchMutate();

  useEffect(() => {
    let cancel = false;

    if (user.isOrgOwner || user.isOrgAdmin) {
      setShouldUpdate(false);
    }

    async function updateAccess() {
      if (value !== 'DELETE') {
        // In this case, venueAccessId is the access record
        const newPermission = {
          isKiosk: value === 'KIOSK',
          isCollab: value === 'COLLABORATOR',
          isAdmin: value === 'ADMIN',
          orgId: user.orgId,
        };
        await app.service('venue-access').patch(`${user.userId},${user.venueId}`, newPermission);
        // todo: handle notify toast if call fails
        mutate(/service:"venue-access"/);
        if (!cancel) {
          setShouldUpdate(false);
          setOpen(false);
        }
      } else {
        // In this case, user.id is actually the user access Id
        await app.service('venue-access').remove(`${user.userId},${user.venueId}`);
        // todo: handle notify toast if call fails
        mutate(/service:"venue-access"/);
        if (!cancel) {
          setShouldUpdate(false);
          setOpen(false);
        }
      }
    }

    if (shouldUpdate) {
      updateAccess();
    }
    return () => (cancel = true);
    //eslint-disable-next-line
  }, [shouldUpdate]);

  const handleUpdate = () => {
    setShouldUpdate(true);
  };

  return (
    <Group position='apart'>
      <Text size='sm'>{getVenueRoleText(user)}</Text>
      {!user.isOrgOwner && !user.isOrgAdmin && (
        <Popover opened={open} onClose={() => setOpen(false)} position='bottom' withArrow>
          <Popover.Target>
            <ActionIcon onClick={() => setOpen(true)}>
              <FontAwesomeIcon icon={faEdit} />
            </ActionIcon>
          </Popover.Target>
          <Popover.Dropdown>
            <LoadingOverlay visible={shouldUpdate} />
            <Select
              label='Permission'
              data={[
                { label: 'Admin', value: 'ADMIN', group: 'Permission' },
                { label: 'Kiosk', value: 'KIOSK', group: 'Permission' },
                { label: 'Collaborator', value: 'COLLABORATOR', group: 'Permission' },
                { label: 'Viewer', value: 'VIEWER', group: 'Permission' },
                { label: 'Remove User From Venue', value: 'DELETE', group: 'Restrict' },
              ]}
              value={value}
              onChange={setValue}
            />
            <Group position='right'>
              <Button
                size='xs'
                mt='sm'
                color={value === 'DELETE' ? 'red' : 'blue'}
                onClick={handleUpdate}
              >
                {value === 'DELETE' ? 'Remove' : 'Update'}
              </Button>
              <Button variant='outline' size='xs' mt='sm' onClick={() => setOpen(false)}>
                Cancel
              </Button>
            </Group>
          </Popover.Dropdown>
        </Popover>
      )}
    </Group>
  );
}

export default VenueRoleEditor;
