import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon, Box, Group, LoadingOverlay, Title } from '@mantine/core';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { getter } from '../../app/feathersClient';

function VenuePage() {
  const { orgId, venueId } = useParams();
  const { data: venue } = useSWR(`venues/${venueId}`, getter);

  if (!venue) return <LoadingOverlay />;

  return (
    <Box>
      <Group
        p='lg'
        sx={(theme) => ({
          borderBottom: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.gray[9] : theme.colors.gray[1]
          }`,
        })}
        position={'apart'}
      >
        <Title order={2}>{venue.name}</Title>
        <ActionIcon
          color='gray'
          size='md'
          component={Link}
          to={`/orgs/${orgId}/venues/${venueId}/config`}
        >
          <FontAwesomeIcon icon={faCog}></FontAwesomeIcon>
        </ActionIcon>
      </Group>
    </Box>
  );
}

export default VenuePage;
