import React from 'react';
import { Text, Center } from '@mantine/core';

function HeaderLogo(props) {
  return (
    <Center style={{ maxWidth: 90, height: '100%' }} {...props}>
      <Text
        size='xl'
        weight='500'
        variant='gradient'
        gradient={{ from: 'indigo', to: 'cyan', deg: 45 }}
        sx={(theme) => ({
          fontSize: 24,
        })}
      >
        fixtr
      </Text>
    </Center>
  );
}

export default HeaderLogo;
