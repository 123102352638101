import { AppShell, Header, Box, LoadingOverlay, Navbar } from '@mantine/core';

import React, { useEffect, useState } from 'react';

import HeaderContent from './components/HeaderContent/HeaderContent';
import { flexCenterCenter, flexColCenter } from './common/commonStyles';

import { Routes, Route, useLocation, useParams, Outlet, useNavigate } from 'react-router-dom';
import RequireAuth from './components/RequireAuth/RequireAuth';
import LoginScreen from './components/LoginScreen/LoginScreen';
import { useSelector } from 'react-redux';
import { selectAppShouldRender } from './features/auth/authSlice';
import RequireUnauth from './components/RequireUnauth/RequireUnauth';

import orgsModel from './features/orgs/orgsModel';
import OrgConfigPage from './components/orgs/OrgConfigPage';
import NavTrail from './components/NavTrail/NavTrail';
import NavbarContent from './components/NavbarElements/NavbarContent';
import { useMediaQuery } from '@mantine/hooks';
import ListPage from './components/ListPage/ListPage';
import Dashboard from './components/Dashboard/Dashboard';
import venuesModel from './features/venues/venuesModel';
import VenuePage from './components/venues/VenuePage';
import VenueConfigPage from './components/venues/VenueConfigPage';
import fixtureTypesModel from './features/fixtureTypes/fixtureTypesModel';
import positionsModel from './features/positions/positionsModel';
import fixturesModel from './features/fixtures/fixturesModel';
import FixtureInfoDrawer from './components/fixtures/FixtureInfo';
import AccordionTest from './components/AccordionTest/AccordionTest';
import AuthMonitor from './components/AuthMonitor/AuthMonitor';
import FixtureShortIdRedirect from './components/FixtureShortId/FixtureShortIdRedirect';
import logsModel from './features/logs/logsModel';
import ViewsPage from './components/Views/ViewsPage';
import issuesModel from './features/issues/issuesModel';
import issueTypesModel from './features/issueTypes/issuesTypesModel';
import serviceTagsModel from './features/serviceTags/serviceTagsModel';
import serviceHistoryModel from './features/serviceHistory/serviceHistoryModel';
import useSWR from 'swr';
import { getter } from './app/feathersClient';
import { showNotification } from '@mantine/notifications';

function ScrollToTop(props) {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return <>{props.children}</>;
}

// Venue outlet monitors for misdirected venueIds
function VenueOutlet() {
  const { venueId } = useParams();
  const navigate = useNavigate();

  const { data: venue, error } = useSWR(`venues/${venueId}`, getter);

  useEffect(() => {
    if (error && error.code === 404) {
      console.log('VenueOutlet - Venue not found:', venueId);
      // Notify the user that this venue is not accessible to them
      showNotification({
        color: 'red',
        disallowClose: true,
        autoClose: 5000,
        title: 'Invalid Venue Warning',
        message: 'Attempted to access an invalid venue. Redirecting to orgs page.',
      });
      // Use react router to direct the user to the orgs page
      navigate('/orgs');
    }
    if (!venue) return;
    // eslint-ignore-next-line
  }, [venue, error]);

  return <Outlet />;
}

function AppFrame() {
  const [menuOpened, setMenuOpened] = useState(false);
  const showHeader = useMediaQuery(`(max-width: 576px)`);
  const closeMenu = () => setMenuOpened(false);

  return (
    <AppShell
      fixed
      padding={0}
      header={
        showHeader && (
          <Header
            height={60}
            sx={(theme) => ({
              ...flexColCenter,
              backgroundColor:
                theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
            })}
          >
            <HeaderContent opened={menuOpened} setOpened={setMenuOpened} />
          </Header>
        )
      }
      navbar={
        <Navbar
          width={{ lg: 250, xs: 90, base: '100%' }}
          padding={0}
          hiddenBreakpoint='xs'
          hidden={!menuOpened}
          sx={(theme) => ({
            backgroundColor:
              theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
          })}
        >
          <NavbarContent menuClose={closeMenu} />
        </Navbar>
      }
    >
      <Box>
        <NavTrail />
        <AuthMonitor />
        <ScrollToTop>
          <Routes>
            <Route index element={<ListPage model={orgsModel} />} />
            <Route path='orgs' element={<ListPage model={orgsModel} />} />
            <Route path='orgs/:orgId' element={<Dashboard />} />
            <Route path='orgs/:orgId/config' element={<OrgConfigPage />} />
            <Route path='orgs/:orgId/types' element={<ListPage model={fixtureTypesModel} />} />
            <Route path='orgs/:orgId/venues' element={<ListPage model={venuesModel} />} />
            <Route
              path='orgs/:orgId/service-tags'
              element={<ListPage model={serviceTagsModel} />}
            />
            {/* Wrap further routes inside venue monitor */}
            <Route path='orgs/:orgId/venues/:venueId' element={<VenueOutlet />}>
              <Route path='*' element={<VenuePage />} />
              <Route path='config' element={<VenueConfigPage />} />
              <Route
                path='positions'
                element={<ListPage model={positionsModel} query={{ parentId: 'null' }} />}
              />
              <Route path='fixtures' element={<ListPage model={fixturesModel} query={{}} />} />
              <Route path='fixtures/:fixtureId' element={<FixtureInfoDrawer />} />
              <Route path='logs' element={<ListPage model={logsModel} />} />

              <Route path='views' element={<ViewsPage model={fixturesModel} />} />

              <Route path='test' element={<AccordionTest />} />

              <Route path='tickets' element={<ListPage model={issuesModel} />} />
              <Route path='issues' element={<ListPage model={issueTypesModel} />} />
              <Route path='service-history' element={<ListPage model={serviceHistoryModel} />} />
            </Route>
          </Routes>
        </ScrollToTop>
      </Box>
    </AppShell>
  );
}

const AppLoader = () => (
  <Box
    sx={(theme) => ({
      backgroundColor: theme.colors.dark[6],
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      ...flexCenterCenter,
    })}
  >
    <LoadingOverlay visible />
  </Box>
);

function App() {
  const isReadyToRender = useSelector(selectAppShouldRender);

  if (!isReadyToRender) {
    return <AppLoader />;
  }

  return (
    <Routes>
      <Route
        path='/*'
        element={
          <RequireAuth>
            <AppFrame />
          </RequireAuth>
        }
      ></Route>
      <Route path='f/:shortId' element={<FixtureShortIdRedirect />} />
      <Route
        path='/login'
        element={
          <RequireUnauth>
            <LoginScreen mode='login' />
          </RequireUnauth>
        }
      />
      <Route
        path='/register'
        element={
          <RequireUnauth>
            <LoginScreen mode='register' />
          </RequireUnauth>
        }
      />
    </Routes>
  );
}

export default App;
