import { Box, Text } from '@mantine/core';
import React from 'react';
import useSWR from 'swr';
import { fetcher } from '../../app/feathersClient';
import Comment from '../Comments/Comment';
import CommentCreator from '../Comments/CommentCreator';

const EditNote = ({ edit }) => {
  return (
    <Box>
      <Text size='sm' mt='sm' color='dimmed'>
        Edited by {edit.username} at {new Date(edit.createdAt).toLocaleString()}:
      </Text>
      <Box pl='xl' mb='sm'>
        {edit.changes.map((change, index) => {
          const { field, oldValue } = change;
          let fieldName;
          let fromVal;

          switch (field) {
            case 'logType':
              fieldName = 'Log Type';
              fromVal = oldValue;
              break;
            case 'title':
              fieldName = 'Title';
              fromVal = oldValue;
              break;
            case 'description':
              fieldName = 'Description';
              fromVal = oldValue;
              break;
            case 'serviceTags':
              fieldName = 'Service Tags';
              fromVal = `[${oldValue.map((tag) => tag).join(', ')}]`;
              break;

            default:
              fieldName = field;
              fromVal = oldValue;
              break;
          }

          return (
            <Text size='xs' color='dimmed' key={`change-${index}`}>
              Changed {fieldName} from {fromVal}
            </Text>
          );
        })}
      </Box>
    </Box>
  );
};

function LogCommentBlock({ log }) {
  const { data } = useSWR(
    {
      service: 'comments',
      query: {
        logId: log.id,
        $sort: { createdAt: 1 },
      },
    },
    fetcher,
    {
      revalidateOnFocus: false,
    }
  );

  const { edits = [] } = log.logMeta;
  const comments = data ? data.data : [];

  // Combine comments if any and edits sorted by date into an array of blocks
  const blocks = [
    ...edits?.map((e) => ({ context: 'edit', data: e })),
    ...comments?.map((c) => ({ context: 'comment', data: c })),
  ] // and sort by date
    .sort((a, b) => new Date(b.data.createdAt) - new Date(a.data.createdAt));

  return (
    <Box style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
      {blocks.length > 0 &&
        blocks.map((block, index) => {
          if (block.context === 'comment') {
            return (
              <Comment key={`comment-${block.data.id}-${index}`} comment={block.data} log={log} />
            );
          }
          return <EditNote key={`edit-${index}`} edit={block.data} />;
        })}
      <CommentCreator targetId={log.id} />
    </Box>
  );
}

export default LogCommentBlock;
