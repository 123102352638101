import React from 'react';
import { Link } from 'react-router-dom';

import { Anchor, Group, Loader, Text } from '@mantine/core';
import { closeAllModals } from '@mantine/modals';
import StatusBadgeRenderer from '../statusTypes/StatusBadgeRenderer';
import useSWR from 'swr';
import { getter } from '../../app/feathersClient';
import { getFixtureCompositeName } from '../../common/commonResolvers';

function FixtureLink({
  data,
  cellData,
  targetId,
  withStatus = false,
  size = 'md',
  suppressHover = false,
}) {
  const { data: fixtureData } = useSWR(targetId ? `fixtures/${targetId}` : false, getter);
  let targetData = data || fixtureData;

  if (!targetData) {
    return <Loader size='xs' />;
  }

  return (
    <Group style={{ flexWrap: 'nowrap' }}>
      {withStatus && <StatusBadgeRenderer chip statusId={targetData.status} />}
      <Anchor
        component={Link}
        to={`/orgs/${targetData.orgId}/venues/${targetData.venueId}/fixtures/${targetData.id}`}
        onClick={closeAllModals}
        sx={(theme) => ({
          ':hover': suppressHover
            ? {
                background: 'transparent',
              }
            : undefined,
        })}
      >
        <Text lineClamp={1} size={size}>
          {cellData || getFixtureCompositeName(targetData)}
        </Text>
      </Anchor>
    </Group>
  );
}

export default FixtureLink;
