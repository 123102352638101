import feathers from '@feathersjs/client';
// import socketio from '@feathersjs/socketio-client';
const rest = require('@feathersjs/rest-client');
// import io from 'socket.io-client';

const host =
  window.location.hostname === 'fixtr.io' ? 'https://api.fixtr.io' : window.location.hostname;
const port = host === 'localhost' || host.startsWith('10.0.0') ? ':3030' : '';
const protocol = host.includes('https://') ? '' : 'http://';
console.log('Hostname:', host);
console.log('Port:', port);
console.log(`Opening connection at ${host}${port}`);
export const restClientPath = `${protocol}${host}${port}`;
const restClient = rest(restClientPath);

// const socket = io(`${host}${port}`);
const app = feathers();
// app.configure(socketio(socket));
app.configure(restClient.fetch(window.fetch.bind(window)));

app.configure(
  feathers.authentication({
    storage: window.localStorage,
    storageKey: 'token',
  })
);

app.hooks({
  error(context) {
    console.error(
      `FeathersClient: Error in '${context.path}' service method '${context.method}'`,
      context.error.stack
    );
  },
});

async function fetcher(params) {
  const { service, query } = params;
  const res = await app.service(service).find({ query });
  if (params.logging) console.log(res);
  return res;
}
async function getter(key) {
  const [service, id] = key.split('/');
  return await app.service(service).get(id);
}

export default app;
// export { socket, fetcher, getter };
export { fetcher, getter };
