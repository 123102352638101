import { ActionIcon, Autocomplete, Group, Loader, Text } from '@mantine/core';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { fetcher, getter } from '../../app/feathersClient';
import { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useDebouncedValue } from '@mantine/hooks';

const AutoCompleteItem = forwardRef(({ id, value, ...others }, ref) => {
  return (
    <div ref={ref} {...others}>
      <Group noWrap>
        <Text>{value}</Text>
      </Group>
    </div>
  );
});

function DataAutocomplete({
  model,
  matchField,
  secondaryMatchField,
  onChange,
  onChangeFullData = (data) => true,
  value = '',
  placeholder = '',
  additionalQuery = {},
  getValueMap = (item) => ({ value: item.name, id: item.id, key: item.id, ...item }),
  customItem = null,
  passRef,
  disabled = false,
  reset = false,
  setReset = () => {},
  exact,
  pinnedOption = null,
  ...rest
}) {
  const ref = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const [shouldFetch, setShouldFetch] = useState(value ? true : false);
  const { data: itemData } = useSWR(shouldFetch ? `${model.service}/${value}` : null, getter);
  const [debouncedValue] = useDebouncedValue(inputValue, 300);
  const [shouldRefocus, setShouldRefocus] = useState(false);

  // If a value is provided, fetch the value and insert it
  useEffect(() => {
    if (shouldFetch && itemData) {
      setShouldFetch(false);
      setInputValue(itemData.name);
    }
    //eslint-disable-next-line
  }, [itemData]);

  useEffect(() => {
    if (shouldRefocus) {
      ref?.current?.focus();
      passRef?.current?.focus();
      setShouldRefocus(false);
    }
    // eslint-disable-next-line
  }, [shouldRefocus]);

  useEffect(() => {
    if (reset) {
      setInputValue('');
      setReset(false);
      onChange(null); // Send null to parent form
      onChangeFullData(null);
    }
    // eslint-disable-next-line
  }, [reset]);

  const urlParams = useParams();

  let injectQuery = {};
  if (model?.injectGetQuery) {
    model.injectGetQuery.forEach((item) => {
      if (urlParams[item]) {
        injectQuery[item] = urlParams[item];
      }
    });
  }
  const match = exact ? inputValue : { $ilike: `%${debouncedValue}%` };

  const matchPattern = secondaryMatchField
    ? { $or: [{ [matchField]: match }, { [secondaryMatchField]: match }] }
    : { [matchField]: match };

  const searchParams = inputValue
    ? {
        ...matchPattern,
        ...additionalQuery,
      }
    : { ...additionalQuery };

  const handleReset = () => {
    setInputValue(''); // Reset input
    onChange(null); // Send null to parent form
    onChangeFullData(null);
    setShouldRefocus(true);
  };

  const { data: response } = useSWR(
    model
      ? { service: model.service, query: { ...searchParams, ...injectQuery, $limit: 10 } }
      : false,
    fetcher
  );

  const data = response?.data?.map((item) => getValueMap(item)) || [];

  if (pinnedOption) {
    data.unshift(pinnedOption);
  }

  return (
    <Autocomplete
      limit={pinnedOption ? 10 : 11}
      disabled={disabled}
      ref={passRef || ref}
      placeholder={placeholder}
      data={data || []}
      filter={(value, item) => {
        if (secondaryMatchField) {
          return (
            item?.data?.[matchField]?.toLowerCase().includes(value?.toLowerCase().trim()) ||
            item?.data?.[secondaryMatchField]?.toLowerCase().includes(value?.toLowerCase().trim())
          );
        }
        return item?.value?.toLowerCase().includes(value?.toLowerCase().trim());
      }}
      itemComponent={customItem || AutoCompleteItem}
      onItemSubmit={(value) => {
        onChange(value?.id);
        onChangeFullData(value);
      }}
      value={inputValue}
      onChange={setInputValue}
      rightSectionWidth={70}
      rightSection={
        <Group noWrap style={{ width: 70 }} spacing={0} position={'right'} pr='xs'>
          {shouldFetch && !data && <Loader size='sm' />}
          <ActionIcon varient='transparent' onClick={handleReset} disabled={disabled}>
            <FontAwesomeIcon icon={faClose} />
          </ActionIcon>
        </Group>
      }
      {...rest}
    />
  );
}

export default DataAutocomplete;
