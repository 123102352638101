import React from 'react';
import {
  Text,
  TextInput,
  Space,
  Group,
  Button,
  Box,
  LoadingOverlay,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect } from 'react';

function FixtureTypeForm({
  target,
  targetData,
  onClose,
  onSubmit,
  success,
  action,
  pending = false,
  finished,
  error = '',
}) {
  const form = useForm({
    initialValues: {
      name: target ? targetData.name : '',
      shortname: target ? targetData.shortname : '',
      manufacturer: target ? targetData.manufacturer : '',
      description: target ? targetData.description : '',
    },
    validationRules: {
      name: (value) => value.trim().length >= 1 && value.trim().length <= 64,
      shortname: (value) => value.trim().length >= 1 && value.trim().length <= 8,
      manufacturer: (value) => value.trim().length <= 64,
      description: (value) => value.trim().length <= 1024,
    },
    errorMessages: {
      name: 'Venue name should be between 1 and 64 characters',
      shortname: 'Shortname should be between 1 and 8 characters',
      manufacturer: 'Manufacturer name should be less than 64 characters',
      description: 'Description should be less than 1024 characters',
    },
  });

  useEffect(() => {
    if (success) onClose();
    // eslint-disable-next-line
  }, [success]);

  return (
    <Box>
      <form
        onSubmit={form.onSubmit(() => {
          onSubmit({ ...form.values, id: target ? target : undefined });
        })}
      >
        <LoadingOverlay visible={pending || finished} />
        <TextInput
          placeholder='Fixture Type Name'
          label='Fixture Type Name'
          required
          data-autofocus
          mt='md'
          {...form.getInputProps('name')}
        />
        <TextInput
          placeholder='Short name'
          description='An optional 1-8 character abbreviation for this fixture type.'
          label='Short Name'
          mt='md'
          {...form.getInputProps('shortname')}
        />
        <TextInput
          placeholder='Manufacturer'
          label='Manufacturer'
          mt='md'
          {...form.getInputProps('manufacturer')}
        />
        <Textarea
          placeholder='Description'
          label='Description'
          mt='md'
          {...form.getInputProps('description')}
        />
        {error && (
          <Text color='red' size='sm' mt='sm'>
            {error}
          </Text>
        )}
        <Space h='xl' />
        <Group position='right'>
          <Button type='submit'>{action === 'create' ? 'Create' : 'Update'}</Button>
          <Button variant='outline' type='button' onClick={onClose}>
            Cancel
          </Button>
        </Group>
      </form>
    </Box>
  );
}

export default FixtureTypeForm;
