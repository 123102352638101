import React, { useEffect, useState } from 'react';
import { Text, TextInput, Group, Button, Box, LoadingOverlay } from '@mantine/core';
import { useForm } from '@mantine/form';
import app from '../../app/feathersClient';
import { useNavigate } from 'react-router-dom';

function DeleteOrgForm({ org }) {
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);
  const [remove, setRemove] = useState(false);
  const navigate = useNavigate();

  const form = useForm({
    initialValues: {
      name: '',
    },
    validationRules: {
      name: (value) => value.trim().length >= 2 && value.trim().length <= 64,
    },
    errorMessages: {
      name: 'Organization name should match (case sensitive)',
    },
  });

  useEffect(() => {
    if (!remove) return;
    let cancel = false;

    async function removeOrg() {
      const params = { query: { name: form.values.name, id: org.id } };
      try {
        await app.service('orgs').remove(org.id, params);
        if (!cancel) {
          navigate('/orgs');
          setPending(false);
          setRemove(false);
        }
      } catch (error) {
        setError(error.message);
        setPending(false);
        setRemove(false);
      }
    }

    removeOrg();

    return () => (cancel = false);
    //eslint-disable-next-line
  }, [remove]);

  const handleSubmit = () => {
    setPending(true);
    setRemove(true);
  };

  return (
    <Box>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <LoadingOverlay visible={pending} />
        <Text weight={600} color='red'>
          Delete Organization
        </Text>
        <Text size='sm' mt='xs'>
          Deleting an organization will permenantly delete all venues, fixtures, positions, logs and
          issues associated with the organization. This can not be undone.
        </Text>
        <Text size='sm' mt='xs'>
          To permenantly delete this organization and all associated data, type the organization
          name (case sensitive) in the box below.
        </Text>

        <TextInput
          placeholder='Organization name'
          label='Confirm Organization To Delete'
          data-autocomplete='off'
          required
          data-autofocus
          mt='md'
          {...form.getInputProps('name')}
        />
        {error && (
          <Text color='red' size='sm' mt='sm'>
            {error}
          </Text>
        )}
        <Group position='right' mt='sm'>
          <Button type='submit' color='red'>
            Permenantly Delete
          </Button>
          <Button variant='outline' type='button' onClick={() => form.reset()}>
            Cancel
          </Button>
        </Group>
      </form>
    </Box>
  );
}

export default DeleteOrgForm;
