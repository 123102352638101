import { Box, LoadingOverlay, Tabs } from '@mantine/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { getter } from '../../app/feathersClient';
import { getCompositeAccess, ORG_ADMIN, ORG_OWNER } from '../../common/commonResolvers';
import DataImportForm from '../DataImportForm/DataImportForm';
import DeleteVenueForm from './DeleteVenueForm';
import EditVenueForm from './EditVenueForm';
import VenueAddUserForm from './VenueAddUserForm';
import VenueUserEditor from './VenueUserEditor';

function VenueConfigCard({ handleClose }) {
  const { venueId } = useParams();

  const { data: venue } = useSWR(`venues/${venueId}`, getter);
  const { data: org } = useSWR(`orgs/${venue.orgId}`, getter);

  if (!venue || !org) return <LoadingOverlay />;

  const venueAccessLevel = getCompositeAccess(venue, org);

  return (
    <Box>
      <Tabs defaultValue={'members'}>
        <Tabs.List>
          <Tabs.Tab value='members'>Members</Tabs.Tab>
          {venueAccessLevel >= ORG_ADMIN && <Tabs.Tab value='invite'>Invite User</Tabs.Tab>}
          {venueAccessLevel >= ORG_ADMIN && <Tabs.Tab value='details'>Details</Tabs.Tab>}
          {venueAccessLevel >= ORG_ADMIN && <Tabs.Tab value='import'>Data Import</Tabs.Tab>}
          {venueAccessLevel >= ORG_OWNER && <Tabs.Tab value='danger'>Danger Zone</Tabs.Tab>}
        </Tabs.List>

        <Tabs.Panel value='members'>
          <VenueUserEditor venue={venue} handleClose={handleClose} />
        </Tabs.Panel>

        {venueAccessLevel >= ORG_ADMIN && (
          <Tabs.Panel value='invite'>
            <VenueAddUserForm venue={venue} handleClose={handleClose} />
          </Tabs.Panel>
        )}
        {venueAccessLevel >= ORG_ADMIN && (
          <Tabs.Panel value='details'>
            {<EditVenueForm venue={venue} handleClose={handleClose} />}
          </Tabs.Panel>
        )}
        {venueAccessLevel >= ORG_ADMIN && (
          <Tabs.Panel value='import'>
            {<DataImportForm venueId={venueId} orgId={org.id} handleClose={handleClose} />}
          </Tabs.Panel>
        )}
        {venueAccessLevel >= ORG_OWNER && (
          <Tabs.Panel value='danger'>
            <DeleteVenueForm venue={venue} handleClose={handleClose} />
          </Tabs.Panel>
        )}
      </Tabs>
    </Box>
  );
}

export default VenueConfigCard;
