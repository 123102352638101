import React, { useEffect, useState } from 'react';
import { Select, Group, Button, LoadingOverlay } from '@mantine/core';
import app from '../../app/feathersClient';
import useMatchMutate from '../../hooks/useMatchMutate';

function VenueRoleCreator({ user, venue }) {
  const [value, setValue] = useState('NONE');
  const mutate = useMatchMutate();
  const [shouldUpdate, setShouldUpdate] = useState(false);

  useEffect(() => {
    let cancel = false;

    async function createAccess() {
      // In this case, userId is the user record
      const newPermission = {
        isKiosk: value === 'KIOSK',
        isCollab: value === 'COLLABORATOR',
        isAdmin: value === 'ADMIN',
        orgId: venue.orgId,
        venueId: venue.id,
        userId: user.userId,
      };
      await app.service('venue-access').create(newPermission);

      mutate(/service:"venue-access"/);

      // todo: handle notify toast if call fails
      if (!cancel) {
        setShouldUpdate(false);
      }
    }

    if (shouldUpdate) {
      createAccess();
    }
    return () => (cancel = true);
    //eslint-disable-next-line
  }, [shouldUpdate]);

  return (
    <Group>
      <LoadingOverlay visible={shouldUpdate} />
      <Select
        size='xs'
        data={[
          { label: 'Admin', value: 'ADMIN', group: 'Permission' },
          { label: 'Kiosk', value: 'KIOSK', group: 'Permission' },
          { label: 'Collaborator', value: 'COLLABORATOR', group: 'Permission' },
          { label: 'Viewer', value: 'VIEWER', group: 'Permission' },
          { label: 'None', value: 'NONE', group: 'Restrict' },
        ]}
        value={value}
        onChange={setValue}
      />
      <Button size='xs' disabled={value === 'NONE'} onClick={() => setShouldUpdate(true)}>
        Add
      </Button>
    </Group>
  );
}

export default VenueRoleCreator;
