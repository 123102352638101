import { Box, Loader, Title, Grid, Text } from '@mantine/core';
import React from 'react';
import VenueConfigCard from './VenueConfigCard';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { getter } from '../../app/feathersClient';

function VenueConfigPage() {
  const { venueId } = useParams();
  const { data: venue } = useSWR(`venues/${venueId}`, getter);

  if (!venue) {
    return <Loader />;
  }

  return (
    <Box>
      <Box
        p='lg'
        sx={(theme) => ({
          borderBottom: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.gray[9] : theme.colors.gray[1]
          }`,
        })}
      >
        <Title order={2}>{venue.name}</Title>
      </Box>
      <Grid columns={3} p='lg' m={0}>
        <Grid.Col
          span={3}
          md={1}
          sx={(theme) => ({
            [`@media (min-width: ${theme.breakpoints.md}px)`]: {
              paddingTop: 72,
              paddingLeft: 8,
              paddingRight: 48,
            },
          })}
        >
          <Title order={5}>Organization Settings</Title>
          <Text size='sm' color={'dimmed'}>
            Manage venue users, permissions and details.
          </Text>
        </Grid.Col>
        <Grid.Col span={3} md={2}>
          <VenueConfigCard />
        </Grid.Col>
      </Grid>
    </Box>
  );
}

export default VenueConfigPage;
