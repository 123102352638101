import { Button, LoadingOverlay, Text, Box, Center, Checkbox } from '@mantine/core';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectAuthUserId } from '../../features/auth/authSlice';
import app from '../../app/feathersClient';
import useMatchMutate from '../../hooks/useMatchMutate';
import { useNavigate } from 'react-router-dom';

function LeaveOrgForm({ org }) {
  const matchMutate = useMatchMutate();
  const navigate = useNavigate();
  const [consent, setConsent] = useState(false);
  const [remove, setRemove] = useState(false);
  const userId = useSelector(selectAuthUserId);

  const handleLeaveOrg = () => {
    setRemove(true);
  };

  useEffect(() => {
    if (!remove) return;
    let cancel = false;
    const id = `${userId},${org.id}`;

    async function rejectInvite() {
      await app.service('org-access').remove(id);
      matchMutate(/service:"orgs"/);
      navigate('/orgs');
      if (!cancel) setRemove(false);
    }

    rejectInvite();

    return () => (cancel = true);
    //eslint-disable-next-line
  }, [remove]);

  return (
    <Box>
      <LoadingOverlay visible={remove} />
      <Text weight={600} color='red'>
        Leave Organization
      </Text>
      <Text size='sm' mt='xs'>
        By departing the organization you will no longer have access to it's associated venues and
        data. Only an organization admin can invite you to rejoin.
      </Text>
      <Checkbox
        label='I understand'
        required
        mt='sm'
        checked={consent}
        onChange={(e) => setConsent(e.currentTarget.checked)}
      />
      <Center>
        <Button type='submit' color='red' mt='md' disabled={!consent} onClick={handleLeaveOrg}>
          Leave Organization
        </Button>
      </Center>
    </Box>
  );
}

export default LeaveOrgForm;
