import { Button, Group, Text } from '@mantine/core';
import React, { Fragment, useEffect, useState } from 'react';
import { useSWRConfig } from 'swr';
import app from '../../app/feathersClient';

function CommentDeleter({ targetId, onClose }) {
  const { mutate } = useSWRConfig();
  const [remove, setRemove] = useState(false);

  useEffect(() => {
    if (!remove) return;
    let cancel = false;

    async function removeComment() {
      try {
        const res = await app.service('comments').remove(targetId, {});
        if (res.id === targetId) {
          onClose();
          mutate({
            service: 'comments',
            query: {
              logId: res.logId,
              $sort: { createdAt: 1 },
            },
          });
        }
        cancel = true;
        onClose();
      } catch (error) {
        console.log(error);
        if (!cancel) setRemove(false);
      }
    }
    removeComment();

    return () => (cancel = true);
    //eslint-disable-next-line
  }, [remove]);

  const handleRemove = () => setRemove(true);

  return (
    <Fragment>
      <Text>Delete this comment?</Text>
      <Group position='center' mt='xl'>
        <Button type='submit' onClick={handleRemove} disabled={remove}>
          Delete
        </Button>
        <Button variant='outline' type='button' onClick={onClose}>
          Cancel
        </Button>
      </Group>
    </Fragment>
  );
}

export default CommentDeleter;
