import React, { useEffect } from 'react';
import { useNotifications } from '@mantine/notifications';
import { useDispatch, useSelector } from 'react-redux';
import { selectNotificationQueue } from '../../features/ui/uiSlice';

function Message({ notify, messageId }) {
  const message = notify.message;

  return <>{message.replace('{{RELOAD_ORG}}', '')}</>;
}

function NotificationListener() {
  const notificationQueue = useSelector(selectNotificationQueue);
  const notification = useNotifications();
  const dispatch = useDispatch();

  useEffect(() => {
    if (notificationQueue.length > 0) {
      const timestamp = new Date().getTime();
      const notify = notificationQueue[0];
      notification.showNotification({
        ...notify,
        id: timestamp,
        message: <Message notify={notify} messageId={timestamp} />,
      });
      dispatch({ type: 'ui/notify/fulfilled' });
    }
    //eslint-disable-next-line
  }, [notificationQueue]);

  return null;
}

export default NotificationListener;
