import { takeEvery, put } from 'redux-saga/effects';
import app from '../app/feathersClient';

function* logout() {
  app.logout();
  window.localStorage.removeItem('activeOrgId');
  window.localStorage.removeItem('activeVenues');
  yield put({ type: 'auth/logout/fulfilled' });
}

function* handleReauthentication() {
  yield put({ type: 'auth/reauth/pending' });
  try {
    const res = yield app.reAuthenticate();
    if (res.accessToken) {
      yield put({ type: 'auth/reauth/fulfilled', payload: res });
      const orgId = window.localStorage.getItem('lastOrg');
      const venueId = window.localStorage.getItem('lastVenue');
      // todo: check the auth before initiating this redirect, in case the logged in account is different for some reason
      if (orgId && venueId) {
        yield put({
          type: 'ui/initiateRedirect',
          payload: `/orgs/${orgId}/venues/${venueId}/fixtures`,
        });
      }
      if (orgId && !venueId) {
        yield put({ type: 'ui/initiateRedirect', payload: `/orgs/${orgId}/` });
      }
      return;
    }
  } catch (error) {
    console.log(`AuthSaga Reauth Handler:`, error);
    console.warn('Reauth failed, redirecting to login');
    yield put({ type: 'ui/initiateRedirect', payload: `/login` });
  }
  yield put({ type: 'auth/reauth/rejected' });
}
function* handleLiveReauthentication() {
  yield put({ type: 'auth/reauth/pending' });
  let res;
  try {
    res = yield app.reAuthenticate();
    if (res.accessToken) {
      yield put({ type: 'auth/reauth/fulfilled', payload: res });
      return;
    }
  } catch (error) {
    console.log(`AuthSaga Reauth Handler:`, error);
  }
  yield put({ type: 'auth/reauth/rejected' });
}

function* handleOrgChange(action) {
  if (action.payload) {
    window.localStorage.setItem('lastOrg', action.payload);
  } else {
    window.localStorage.removeItem('lastOrg');
  }
  yield put({ type: 'ui/setCurrentOrg', payload: action.payload });
}

function* handleVenueChange(action) {
  if (action.payload) {
    window.localStorage.setItem('lastVenue', action.payload);
  } else {
    window.localStorage.removeItem('lastVenue');
  }
  yield put({ type: 'ui/setCurrentVenue', payload: action.payload });
}

function* handleAuth() {
  yield takeEvery('auth/logout', logout);
  yield takeEvery('ui/changeOrg', handleOrgChange);
  yield takeEvery('ui/changeVenue', handleVenueChange);
  yield handleReauthentication();
  yield takeEvery('auth/liveReauth', handleLiveReauthentication);
}

export default handleAuth;
