import { Accordion, Button, Group, Menu, UnstyledButton } from '@mantine/core';

import React from 'react';
import UserButton from '../UserButton/UserButton';

function AccordionTest() {
  return (
    <div>
      <Accordion title='Title'>
        <Accordion.Item title='test'>test2</Accordion.Item>
        <Accordion.Item title='test3'>test4</Accordion.Item>
      </Accordion>
      <Menu>
        <Menu.Target>
          <UserButton />
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Item>Test</Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
}

export default AccordionTest;
