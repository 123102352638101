import React, { useState } from 'react';
import { Text, TextInput, Group, Button, Box, LoadingOverlay } from '@mantine/core';
import { useForm } from '@mantine/form';
import app from '../../app/feathersClient';
import useMatchMutate from '../../hooks/useMatchMutate';

function EditVenueForm({ handleClose, venue }) {
  const matchMutate = useMatchMutate();
  const [pending, setPending] = useState(false);
  const [error, setError] = useState(null);
  const form = useForm({
    initialValues: {
      name: venue.name,
      shortname: venue.shortname,
      venueGroup: venue.venueGroup,
    },
    validationRules: {
      name: (value) => value.trim().length >= 2 && value.trim().length <= 64,
      shortname: (value) => value.trim().length >= 1 && value.trim().length <= 6,
      venueGroup: (value) => value.trim().length <= 64,
    },
    errorMessages: {
      name: 'Venue name should be between 2 and 64 characters',
      shortname: 'Shortname should be between 1 and 6 characters',
      venueGroup: 'Venue group should be less than 64 characters',
    },
  });

  const handleSubmit = async () => {
    setPending(true);
    try {
      await app.service('venues').patch(venue.id, form.values, {});
      matchMutate(/service:"venues"/);
      matchMutate(/^venues\//);
      setPending(false);
    } catch (error) {
      setError(error.message);
      setPending(false);
    }
  };

  return (
    <Box>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <LoadingOverlay visible={pending} />
        <TextInput
          placeholder='Venue name'
          label='Venue Name'
          required
          data-autofocus
          mt='md'
          {...form.getInputProps('name')}
        />
        <TextInput
          placeholder='Short name'
          description='A 1-4 character abbreviation for this venue, used to prefix position names when viewing multiple venues.'
          label='Short Name'
          mt='md'
          required
          {...form.getInputProps('shortname')}
        />
        <TextInput
          description='An optional short tag to logically group venues'
          placeholder='Venue Group'
          label='Venue Group'
          mt='md'
          {...form.getInputProps('venueGroup')}
        />
        {error && (
          <Text color='red' size='sm' mt='sm'>
            {error}
          </Text>
        )}
        <Group position='right' mt='xl'>
          <Button type='submit'>Update</Button>
          <Button variant='outline' type='button' onClick={handleClose}>
            Cancel
          </Button>
        </Group>
      </form>
    </Box>
  );
}

export default EditVenueForm;
