import { Badge, Indicator } from '@mantine/core';
import React from 'react';
import useSWR from 'swr';
import { getter } from '../../app/feathersClient';

function StatusBadgeRenderer({ statusId, chip = false }) {
  const { data: status } = useSWR(statusId ? `status/${statusId}` : false, getter);

  let baseColor = 'gray';

  if (statusId >= 10) {
    baseColor = 'red';
  }
  if (statusId >= 20) {
    baseColor = 'orange';
  }
  if (statusId >= 30) {
    baseColor = 'green';
  }

  if (chip) return <Indicator color={baseColor} style={{ zIndex: 99 }} />;

  return (
    <Badge
      variant='gradient'
      gradient={{
        from: baseColor,
        to: baseColor,
        deg: 60,
      }}
      size={'sm'}
    >
      {status?.name || 'Unknown'}
    </Badge>
  );
}

export default StatusBadgeRenderer;
