import { faCog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  Box,
  Card,
  Container,
  Grid,
  Group,
  Loader,
  LoadingOverlay,
  Paper,
  RingProgress,
  Stack,
  Text,
  Title,
} from '@mantine/core';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { getter } from '../../app/feathersClient';
import venuesModel from '../../features/venues/venuesModel';
import ObjectList from '../ObjectList/ObjectList';

function Dashboard() {
  const orgId = useParams().orgId;
  const { data: org } = useSWR(`orgs/${orgId}`, getter);
  const { data: orgStats } = useSWR(`org-stats/${orgId}`, getter);

  if (!org) return <LoadingOverlay />;

  return (
    <Box>
      <Group
        p='lg'
        sx={(theme) => ({
          borderBottom: `1px solid ${
            theme.colorScheme === 'dark' ? theme.colors.gray[9] : theme.colors.gray[1]
          }`,
        })}
        position={'apart'}
      >
        <Title order={2}>{org.name}</Title>
        <ActionIcon color='gray' size='md' component={Link} to={`/orgs/${org.id}/config`}>
          <FontAwesomeIcon icon={faCog}></FontAwesomeIcon>
        </ActionIcon>
      </Group>

      <Paper withBorder p='xl' m='lg'>
        <Grid columns={5}>
          <Grid.Col span={5} xs={2.5} md={1}>
            <Link to={`venues`} style={{ textDecoration: 'none' }}>
              <Card
                sx={(theme) => ({
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                })}
              >
                <Group style={{ height: '100%' }}>
                  {orgStats ? (
                    <Title size='xl' sx={(theme) => ({ fontSize: 72, fontWeight: 200 })}>
                      {orgStats?.venueCount}
                    </Title>
                  ) : (
                    <Loader />
                  )}
                </Group>
                <Text>Venues</Text>
              </Card>
            </Link>
          </Grid.Col>

          <Grid.Col span={5} xs={2.5} md={1}>
            {/* <Link to={`fixtures`} style={{ textDecoration: 'none' }}> */}
            <Card
              sx={(theme) => ({
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                alignItems: 'center',
              })}
            >
              <Group style={{ height: '100%' }}>
                {orgStats ? (
                  <Title size='xl' sx={(theme) => ({ fontSize: 72, fontWeight: 200 })}>
                    {orgStats?.fixtureCount}
                  </Title>
                ) : (
                  <Loader />
                )}
              </Group>
              <Text>Fixtures</Text>
            </Card>
            {/* </Link> */}
          </Grid.Col>

          <Grid.Col span={5} xs={1.6667} md={1}>
            <Card
              sx={(theme) => ({
                height: '100%',
              })}
            >
              <Group position='center'>
                <RingProgress
                  thickness={8}
                  sections={[
                    { value: orgStats?.fixtureStatusCount?.unknown || 0, color: 'gray' },
                    { value: orgStats?.fixtureStatusCount?.unusable || 0, color: 'red' },
                    { value: orgStats?.fixtureStatusCount?.faulty || 0, color: 'orange' },
                    { value: orgStats?.fixtureStatusCount?.good || 0, color: 'green' },
                  ]}
                />
                <Stack>Fixture Status</Stack>
              </Group>
            </Card>
          </Grid.Col>

          {/* <Grid.Col span={5} xs={1.6667} md={1}>
            <Card
              sx={(theme) => ({
                height: '100%',
              })}
            >
              <Group position='center'>
                <RingProgress
                  thickness={8}
                  sections={[
                    { value: 8, color: 'black' },
                    { value: 12, color: 'red' },
                    { value: 30, color: 'orange' },
                    { value: 30, color: 'yellow' },
                    { value: 20, color: 'green' },
                  ]}
                />
                <Stack>Fixture Condition</Stack>
              </Group>
            </Card>
          </Grid.Col> */}

          <Grid.Col span={5} xs={1.6667} md={1}>
            <Link to={`types`} style={{ textDecoration: 'none' }}>
              <Card
                sx={(theme) => ({
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                })}
              >
                <Group style={{ height: '100%' }}>
                  {orgStats ? (
                    <Title size='xl' sx={(theme) => ({ fontSize: 72, fontWeight: 200 })}>
                      {orgStats?.fixtureTypesCount}
                    </Title>
                  ) : (
                    <Loader />
                  )}
                </Group>
                <Text>Fixture Types</Text>
              </Card>
            </Link>
          </Grid.Col>
        </Grid>
      </Paper>

      <Container p='xl' size={'xl'}>
        <ObjectList model={venuesModel} />
      </Container>
    </Box>
  );
}

export default Dashboard;
